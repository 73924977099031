const Joi = require('joi');
const fields = require('./fields');

const getUsersSchema = Joi.object({
	nickname: fields.nicknameField,
	email: fields.emailField,
	role: fields.roleField,
	primaryWallet: fields.walletAddressField.label('Primary Wallet'),
	isActive: fields.boolField.label('Active'),
	isWalletOnly: fields.boolField.label('Wallet Only'),
	isEmailVerified: fields.boolField.label('Email Verified'),
	isAnonymous: fields.boolField.label('Anonymous'),
	createdAfter: Joi.date().optional().label('Created After'),
	createdBefore: Joi.date().optional().label('Created Before'),
	paging: fields.paginationSchema,
});

module.exports = getUsersSchema;
