import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function DefconMsg() {
	const [isOpen, setIsOpen] = useState(false)
	const { defconLvl, defconMsg } = useSelector(state => state.audit)

	useEffect(() => {
		if (defconLvl > 0) {
			setIsOpen(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const RenderModal = ({ setIsOpen }) => {
		return (
			<>
				<div className='darkBG' />
				<div className='centered'>
					<div className='popup_mobile'>
						<h3 className='h3'>Defcon Level {defconLvl}</h3>
						<p className='notice2'>We are in devcon level {defconLvl}</p>
						<p className='notice2'>{defconMsg}</p>
						<div className='tabs2'>
							<button className='signin' id='accept' onClick={() => setIsOpen(false)}>
								Acknowledged
							</button>
						</div>
					</div>
				</div>
			</>
		)
	}

	return <>{isOpen && RenderModal({ setIsOpen })}</>
}

export default DefconMsg
