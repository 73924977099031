const register = require('./register.validator')
const registerWallet = require('./registerWallet.validator')
const login = require('./login.validator')
const deposit = require('./deposit.validator')
const p2pTransfer = require('./p2pTransfer.validator')
const mint = require('./mint.validator')
const walletAddress = require('./walletAddress.validator')
const walletAuth = require('./walletAuth.validator')
const forgotPassword = require('./forgotPassword.validator')
const passwordReset = require('./passwordReset.validator')
const verifyEmail = require('./verifyEmail.validator')
const updateProfile = require('./updateProfile.validator')
const administrateUser = require('./administrateUser.validator')
const createCurrency = require('./createCurrency.validator')
const modifyCurrency = require('./modifyCurrency.validator')
const getTransactions = require('./getTransactions.validator')
const leaderboard = require('./leaderboard.validator')
const getCurrencyStats = require('./getCurrencyStats.validator')
const getUsers = require('./getUsers.validator')
const getUserStats = require('./getUserStats.validator')
const getAccessLogs = require('./getAccessLogs.validator')
const getAdminLogs = require('./getAdminLogs.validator')
const setDefconLevel = require('./setDefconLevel.validator')
const checkoutPackage = require('./checkout/checkoutPackage.validator')
const buyPackage = require('./checkout/buyPackage.validator')
const objectId = require('./objectId.validator')
const setObjectFlag = require('./setObjectFlag.validator')
const idQuantity = require('./idQuantity.validator')
const getCheckoutTransactions = require('./checkout/getCheckoutTransactions.validator')
const getAllCheckoutTransactions = require('./checkout/getAllCheckoutTransactions.validator')
const distributeCurrency = require('./bank/distributeCurrency.validator')
const forgeRecipe = require('./forge/forgeRecipe.validator')
const modifyForgeRecipe = require('./forge/modifyForgeRecipe.validator')
const getForgeProcesses = require('./forge/getForgeProcesses.validator')
const getForgeProcessesAll = require('./forge/getForgeProcessesAll.validator')
const endForgeProcess = require('./forge/endProcess.validator')
const cexPair = require('./cex/cexPair.validator')
const modifyCexPair = require('./cex/modifyCexPair.validator')
const getCexOrders = require('./cex/getCexOrders.validator')
const getUserCexOrders = require('./cex/getUserCexOrders.validator')
const placeCexOrder = require('./cex/placeCexOrder.validator')
const cancelCexOrder = require('./cex/cancelCexOrder.validator')
const createItemCollection = require('./inventory/createItemCollection.validator')
const createItem = require('./inventory/createItem.validator')
const getItems = require('./inventory/getItems.validator')
const getItemsGrouped = require('./inventory/getItemsGrouped.validator')
const openSynthChips = require('./inventory/openSynthChips.validator')
const nickname = require('./general/nickname.validator')
const createCappAuthRequest = require('./capp/createAuthRequest.validator')
const fulfillCappAuthRequest = require('./capp/fulfillAuthRequest.validator')
const getCappAuthRequest = require('./capp/getAuthRequest.validator')
const cAppGetUserItems = require('./capp/getUserItems.validator')

module.exports = {
    register,
    registerWallet,
    login,
    deposit,
    p2pTransfer,
    mint,
    walletAddress,
    walletAuth,
    forgotPassword,
    passwordReset,
    verifyEmail,
    updateProfile,
    administrateUser,
    createCurrency,
    modifyCurrency,
    getTransactions,
    leaderboard,
    getCurrencyStats,
    getUsers,
    getUserStats,
    getAccessLogs,
    getAdminLogs,
    setDefconLevel,
    checkoutPackage,
    buyPackage,
    objectId,
    setObjectFlag,
    idQuantity,
    getCheckoutTransactions,
    getAllCheckoutTransactions,
    distributeCurrency,
    forgeRecipe,
    modifyForgeRecipe,
    getForgeProcesses,
    getForgeProcessesAll,
    endForgeProcess,
    cexPair,
    modifyCexPair,
    getCexOrders,
    getUserCexOrders,
    placeCexOrder,
    cancelCexOrder,
    createItemCollection,
    createItem,
    getItems,
    getItemsGrouped,
    openSynthChips,
    nickname,
    createCappAuthRequest,
    fulfillCappAuthRequest,
    getCappAuthRequest,
    cAppGetUserItems,
}