import React, { useState } from 'react'

function Reports() {
	const [IsOpen, setIsOpen] = useState(false)

	// use the click to open this accordion and close the other one or close it if it is already open

	function onClick(e) {
		if (e.target.name === IsOpen) {
			setIsOpen(false)
		} else {
			setIsOpen(e.target.name)
		}
	}

	return (
		<div>
			<header>Reports</header>
			<div className='report'>
				<button
					className={IsOpen === '2022' ? 'accordion is-open' : 'accordion'}
					name='2022'
					onClick={onClick}
				>
					Reports 2022
				</button>
				{IsOpen === '2022' && (
					<div className='accordion-content'>
						<div className='report_link'>
							<a
								href='https://drive.google.com/file/d/1Vq54nueIHIqqVOjZCZublhe11OTOg1a9ZldVB7wXDGU/view'
								className=''
								target='_blank'
								rel='noreferrer'
							>
								Quarter 1 of 2022 Report
							</a>
						</div>
						<div className='report_link'>
							<a
								href='https://docs.google.com/document/d/1hcZzj27dZqcllLD1E70kNtT_vJZ_AZfgzKivD7zeTxY/edit?usp=sharing'
								className=''
								target='_blank'
								rel='noreferrer'
							>
								Quarter 2 of 2022 Report
							</a>
						</div>
						<div className='report_link'>
							<a
								href='https://docs.google.com/document/d/13UZb8SaeQxNFwFGorJY6DnICgui9rkXDdCp04KQsOWs/edit?usp=sharing'
								className=''
								target='_blank'
								rel='noreferrer'
							>
								Quarter 3 of 2022 Report
							</a>
						</div>
						<div className='report_link'>
							<a
								href='https://docs.google.com/document/d/14OpkpvYWJkHaHYUMagM7q83gb5rV8Z9c8wAr2sdixiI/edit?usp=sharing'
								className=''
								target='_blank'
								rel='noreferrer'
							>
								Quarter 4 of 2022 Report
							</a>
						</div>
					</div>
				)}
			</div>

			<div className='report'>
				<button
					className={IsOpen === '2023' ? 'accordion is-open' : 'accordion'}
					name='2023'
					onClick={onClick}
				>
					Reports 2023
				</button>
				{IsOpen === '2023' && (
					<div className='accordion-content'>
						<div className='report_link'>No reports for 2023 yet</div>
					</div>
				)}
			</div>
		</div>
	)
}
export default Reports
