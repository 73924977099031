import { useSelector } from 'react-redux'
import Categories from '../../../util/accessLogTypes'

function UserAccessLogs() {
	const { logs } = useSelector(state => state.audit)

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}
	const time = {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timezone: 'UTC',
	}

	return (
		<>
			<div className='transactions_grid'>
				<div className='h4_2'>Access Log List</div>
				<div className='tg_access_header'>
					<div>Date (UTC) :</div>
					<div>User :</div>
					<div>Address :</div>
					<div>Login Type :</div>
				</div>

				{logs && logs.length > 0 ? (
					logs.map(logs => (
						<div className='tg_access_listing' key={logs.date}>
							<div>
								{new Date(logs.date).toLocaleDateString('en-GB', date)}
								&nbsp;
								{new Date(logs.date).toLocaleTimeString('en-GB', time)}
							</div>
							<div>{logs.user}</div>
							<div>{logs.address}</div>
							<div>
								{logs.loginType ? Categories.type.find(category => category.id === logs.loginType).name : ''}
							</div>
						</div>
					))
				) : (
					<div>No Access Logs</div>
				)}
			</div>
		</>
	)
}

export default UserAccessLogs
