import { useSelector } from 'react-redux'
import States from '../../../util/checkoutTransactionStates.json'
import Providers from '../../../util/checkoutProviders.json'

function CheckoutListUser() {
	const { logs } = useSelector(state => state.checkout)

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}
	const time = {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timezone: 'UTC',
	}

	return (
		<>
			<div className='transactions_grid'>
				<div className='h4_2'>Checkout Log List</div>
				<div className='tg_admin_header'>
					<div>Date (UTC) :</div>
					<div>Package Name :</div>
					<div>Cost :</div>
					<div>Provider :</div>
					<div>State :</div>
				</div>

				{logs && logs.length > 0 ? (
					logs.map(logs => (
						<div className='tg_admin_listing' key={logs.createdAt}>
							<div>
								{new Date(logs.createdAt).toLocaleDateString('en-GB', date)}
								&nbsp;
								{new Date(logs.createdAt).toLocaleTimeString('en-GB', time)}
							</div>
							<div>{logs.package.name}</div>
							<div>{logs.package.cost}</div>
							<div>
								{logs.checkoutProvider
									? Providers.type.find(type => type.id === logs.checkoutProvider).name
									: ''}
							</div>
							<div>{logs.state ? States.type.find(type => type.id === logs.state).name : ''}</div>
						</div>
					))
				) : (
					<div>No Transactions</div>
				)}
			</div>
		</>
	)
}

export default CheckoutListUser
