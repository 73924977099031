const Joi = require('joi')
const fields = require('../fields')

const createAuthRequestSchema = Joi.object({
    itemCollectionId: fields.objectIdField.required().label('Item Collection ID'),
    redirectUrl: fields.urlField.required().label('Redirect URL'),
    cancelUrl: fields.urlField.required().label('Cancel URL'),
    customMessage: Joi.string().max(128).optional(),
});

module.exports = createAuthRequestSchema;