const Joi = require('joi')
const fields = require('../fields')

const forgeRecipeSchema = Joi.object({
    name: fields.nameField.required(),
    description: fields.descriptionField,
    imgUrl: fields.urlField,
    timeRequired: fields.integerAboveZeroField.required(),
    input: fields.balanceSchema.required(),
    output: fields.balanceSchema.required(),
});

module.exports = forgeRecipeSchema;