const Joi = require('joi');
const fields = require('./fields');

const getAdminLogsSchema = Joi.object({
	adminUser: fields.nicknameField.optional(),
	address: fields.ipAddress,
	affectedUser: fields.nicknameField.optional(),
	affectedCurrency: fields.currencyIdField.optional(),
	category: Joi.number().integer().min(1),

	createdAfter: Joi.date(),
	createdBefore: Joi.date(),

	paging: fields.paginationSchema,
});

module.exports = getAdminLogsSchema;
