import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdOutlineManageAccounts } from 'react-icons/md'
import UserConfigMobile from '../../../pages/UserConfigMobile'
import UserCurrencyMobile from '../bank/UserCurrencyMobile'
import UserProfileMobile from '../UserProfileMobile'
import UserLogs from '../bank/TransactionSortUserMobile'
import { userTransactions, clearBankLogs, resetBank, getCurrencies } from '../../../features/bank/bankSlice'
import { toast } from 'react-toastify'
import P2PTransferMobile from '../../builders/P2PTransferMobile'
import './UserConsoleMobile.css'
import Forge from '../forge/Forge'
import { getForgeProcesses, getForgeRecipes, resetForge } from '../../../features/forge/forgeSlice'
import { BsWallet2 } from 'react-icons/bs'
import { BiTransfer } from 'react-icons/bi'
import DistributeCurrency from '../bank/DistributeCurrency'
import CurrencyTransferMobile from '../bank/CurrencyTransferMobile'
import ViewUserItemsMobile from '../inventory/ViewUserItemsMobile'
import ProcessSortUserMobile from '../forge/ProcessSortUserMobile'

function UserConsoleMobile() {
	const [activeTab, setActiveTab] = useState('UserCurrencyMobile')
	const [logId, setLogId] = useState('')
	const [collectionName, setCollectionName] = useState('')
	const { isErrorBank, isSuccessBank, messageBank, currencies } = useSelector(state => state.bank)
	const { isErrorForge, isSuccessForge, messageForge } = useSelector(state => state.forge)

	const dispatch = useDispatch()

	const [pageNum, setPageNum] = useState(1)

	// when active changes set isopen to false
	useEffect(() => {
		setIsOpen(false)
	}, [activeTab])

	useEffect(() => {
		if (!currencies) {
			dispatch(getCurrencies())
		}
	}, [dispatch, currencies])

	useEffect(() => {
		const initData = {
			paging: {
				limit: 10,
				page: 1,
			},
			currencyId: logId,
		}

		if (activeTab === 'UserLogs') {
			dispatch(userTransactions(initData))
			return () => {
				dispatch(clearBankLogs())
				setPageNum(1)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab, dispatch])

	useEffect(() => {
		if (isErrorBank) {
			toast.error(messageBank)
		}
		if (isSuccessBank) {
			toast.success(messageBank)
		}

		dispatch(resetBank())
	}, [isErrorBank, isSuccessBank, messageBank, dispatch])

	useEffect(() => {
		if (isErrorBank) {
			setPageNum(pageNum - 1)
		}

		if (!pageNum || pageNum === 0) {
			setPageNum(1)
		}
	}, [isErrorBank, isSuccessBank, messageBank, dispatch, pageNum])

	useEffect(() => {
		if (activeTab === 'Forge') {
			const init_data = {
				paging: {
					limit: 10,
					page: 1,
				},
			}
			dispatch(getForgeRecipes())
			dispatch(getForgeProcesses(init_data))
		}
	}, [activeTab, dispatch])

	useEffect(() => {
		if (isErrorForge) {
			toast.error(messageForge)
		}
		if (isSuccessForge) {
			toast.success(messageForge)
		}

		dispatch(resetForge())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorForge, isSuccessForge])

	const [IsOpen, setIsOpen] = useState(false)
	const [Class, setClass] = useState('accordion')

	function handleClick() {
		setIsOpen(!IsOpen)
		if (Class === 'accordion') {
			setClass('accordion is-open')
		} else {
			setClass('accordion')
		}
	}

	return (
		<>
			<div className='profilebox profileboxmobile'>
				<div className='admin_content'>
					{activeTab === 'CurrencyTransferMobile' && (
						<CurrencyTransferMobile
							activeTab={activeTab}
							setActiveTab={activeTab => setActiveTab(activeTab)}
						/>
					)}
					{activeTab === 'P2PTransferMobile' && <P2PTransferMobile />}
					{activeTab === 'DistributeCurrency' && <DistributeCurrency />}
					{activeTab === 'UserConfigMobile' && <UserConfigMobile />}
					{activeTab === 'UserProfileMobile' && <UserProfileMobile />}
					{activeTab === 'ViewUserItemsMobile' && <ViewUserItemsMobile collectionName={collectionName} />}
					{activeTab === 'UserCurrencyMobile' && (
						<UserCurrencyMobile
							logId={logId}
							setLogId={logId => setLogId(logId)}
							activeTab={activeTab}
							setActiveTab={activeTab => setActiveTab(activeTab)}
							collectionName={collectionName}
							setCollectionName={collectionName => setCollectionName(collectionName)}
						/>
					)}
					{activeTab === 'UserLogs' && (
						<UserLogs pageNum={pageNum} changePageNum={pageNum => setPageNum(pageNum)} logId={logId} />
					)}
					{activeTab === 'Forge' && (
						<div>
							<Forge />
							<header className='centraliser'>Forge Logs</header>
							<ProcessSortUserMobile
								pageNum={pageNum}
								changePageNum={pageNum => setPageNum(pageNum)}
								logId={logId}
							/>
						</div>
					)}
				</div>
			</div>
			{IsOpen && (
				<div className='mobileaccounttabs' onClick={() => setIsOpen(false)}>
					<nav>
						<button className='closeBtn' onClick={handleClick}>
							x
						</button>
						<button className='menuopen' onClick={() => setActiveTab('UserProfileMobile')}>
							<p className=''>Profile</p>
						</button>
						<button className='menuopen' onClick={() => setActiveTab('UserConfigMobile')}>
							<p className=''>Settings</p>
						</button>
						<button className='menuopen' onClick={() => setActiveTab('Forge')}>
							<p className=''>Forge</p>
						</button>
					</nav>
				</div>
			)}
			<div className='MobileBoSmenu'>
				<div className='menu_button' onClick={() => setActiveTab('UserCurrencyMobile')}>
					<div>Assets</div>
					<div className='svg_size'>
						<BsWallet2 />
					</div>
				</div>
				<div className='menu_button' onClick={() => setActiveTab('CurrencyTransferMobile')}>
					<div className=''>Transfers</div>
					<div className='svg_size'>
						<BiTransfer />
					</div>
				</div>
				<div className='menu_button' onClick={handleClick}>
					<div className=''>Account</div>
					<div className='svg_size'>
						<MdOutlineManageAccounts />
					</div>
				</div>
			</div>
		</>
	)
}

export default UserConsoleMobile
