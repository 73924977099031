import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUser } from '../features/auth/authSlice'

function Provider() {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getUser())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div>
			<div className='DevNotice'>
				<p className='notice2'>
					Thank you for your purchase. <br></br>
				</p>
				<div className='sales_image2 centraliser'>
					<img alt='' src='https://www.dropbox.com/s/i3q1za5nq8446xj/credits.png?dl=1' />
				</div>
				<p className='notice2'>
					Once your payment has been verified and processed,<br></br>
					your credits appear in your BoS account.
				</p>
				<div className='centraliser'>
					<button className='signin'>
						<Link to='/checkout'>Back</Link>
					</button>
					<button className='signin'>
						<Link to='/dashboard'> To BoS </Link>
					</button>
				</div>
			</div>
		</div>
	)
}

export default Provider
