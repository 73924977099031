import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import MenuBarLeft from './components/MenuBarLeft';
import MenuBarRight from './components/MenuBarRight';
import IconBarLeft from './components/IconBarLeft';
import IconBarRight from './components/IconBarRight';
import MobileMenu from './components/mobilemenu/MobileMenu';

import Dashboard from './pages/Dashboard';
import DashboardMobile from './pages/DashboardMobile';
import Login from './pages/Login';
import LoginCApp from './pages/LoginCApp/LoginCApp';
import LoginMobile from './components/design pieces/LoginMobile';
import Register from './pages/Register';
import Leaderboard from './pages/Leaderboard';
import Main from './pages/Main';
import Home from './pages/home/Home';
import Rewards from './pages/Rewards';
import Reports from './pages/Reports';
import LightPaper from './pages/LightPaper';
import QuantumHub from './pages/quantumhub/QuantumHub';
import Userbank from './components/userservices/UserBank';
import UnderDev from './pages/UnderDev';
import VerifyEmail from './pages/VerifyEmail';
import ResetPassword from './pages/ResetPassword';
import UserConfig from './pages/UserConfig';
import Designer from './pages/Designer';
import Checkout from './pages/Checkout';
import Faq from './pages/Faq';
import Provider from './pages/Provider';
import Media from './pages/Media';
import SynthChips from './pages/synthchips/SynthChips';
import OpenASC from './pages/OpenASC';
import { useDispatch } from 'react-redux';
import { logout, resetAuth } from './features/auth/authSlice';
import Analytics from './pages/analytics/Analytics';
import Games from './pages/games/Games';

function App() {
	const [IsShownMenuBarLeft, setIsShownMenuBarLeft] = useState(false);
	const [IsShownMenuBarRight, setIsShownMenuBarRight] = useState(false);
	const dispatch = useDispatch();
	const tokenexpires = localStorage.getItem('tokenexpires');
	const navigate = useNavigate();

	function closeMenuBars() {
		setIsShownMenuBarLeft(false);
		setIsShownMenuBarRight(false);
	}

	function showMenuBarLeft() {
		setIsShownMenuBarLeft(true);
		setIsShownMenuBarRight(false);
	}

	function showMenuBarRight() {
		setIsShownMenuBarLeft(false);
		setIsShownMenuBarRight(true);
	}

	const location = useLocation();

	useEffect(() => {
		// if location does not include loginCApp, then check for token expiration
		if (!location.pathname.includes('loginCApp')) {
			if (tokenexpires && tokenexpires < new Date()) {
				dispatch(logout());
				toast.error('Your credentials have expired, please login again');
				dispatch(resetAuth());
				navigate('/login');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, tokenexpires, dispatch]);

	function desktopDisplay() {
		return (
			<>
				<div onMouseEnter={() => showMenuBarLeft()}>
					<IconBarLeft />
				</div>
				<div onClick={() => closeMenuBars()}>{IsShownMenuBarLeft && <MenuBarLeft />}</div>
				<div className='content' onMouseEnter={closeMenuBars}>
					<Routes>
						<Route path='/userconfig' element={<UserConfig />} />
						<Route path='/dashboard' element={<Dashboard />} />
						<Route path='/login' element={<Login />} />
						<Route path='/loginCApp/:authId' element={<LoginCApp />} />
						<Route path='/register/:referrerId' element={<Register />} />
						<Route path='/register' element={<Register />} />
						<Route path='/leaderboard' element={<Leaderboard />} />
						<Route path='/main' element={<Main />} />
						<Route path='/' element={<Home />} />
						<Route path='/rewards' element={<Rewards />} />
						<Route path='/reports' element={<Reports />} />
						<Route path='/lightpaper' element={<LightPaper />} />
						<Route path='/quantumhub' element={<QuantumHub />} />
						<Route path='/userbank' element={<Userbank />} />
						<Route path='/underdev' element={<UnderDev />} />
						<Route path='/analytics' element={<Analytics />} />
						<Route path='/verifyemail/:token' element={<VerifyEmail />} />
						<Route path='/resetpassword/:token' element={<ResetPassword />} />
						<Route path='/designer' element={<Designer />} />
						<Route path='checkout' element={<Checkout />} />
						<Route path='/Faq' element={<Faq />} />
						<Route path='/provider' element={<Provider />} />
						<Route path='/media' element={<Media />} />
						<Route path='/synthchips' element={<SynthChips />} />
						<Route path='/openasc' element={<OpenASC />} />
						<Route path='/games' element={<Games />} />
					</Routes>
				</div>
				<div onClick={() => closeMenuBars()}>{IsShownMenuBarRight && <MenuBarRight />}</div>
				<div onMouseEnter={() => showMenuBarRight()}>
					<IconBarRight />
				</div>
			</>
		);
	}

	function mobileDisplay() {
		const MainBody = document.getElementById('mainbody');
		MainBody.style.fontSize = '.7rem';

		return (
			<>
				<div>
					<MobileMenu />
				</div>
				<div className='content'>
					<Routes>
						<Route path='/userconfig' element={<UserConfig />} />
						<Route path='/dashboard' element={<DashboardMobile />} />
						<Route path='/login' element={<LoginMobile />} />
						<Route path='/loginCApp/:authId' element={<LoginCApp />} />
						<Route path='/register/:referrerId' element={<Register />} />
						<Route path='/register' element={<Register />} />
						<Route path='/leaderboard' element={<Leaderboard />} />
						<Route path='/main' element={<Main />} />
						<Route path='/' element={<Home />} />
						<Route path='/rewards' element={<Rewards />} />
						<Route path='/reports' element={<Reports />} />
						<Route path='/lightpaper' element={<LightPaper />} />
						<Route path='/quantumhub' element={<QuantumHub />} />
						<Route path='/userbank' element={<Userbank />} />
						<Route path='/underdev' element={<UnderDev />} />
						<Route path='/analytics' element={<Analytics />} />
						<Route path='/verifyemail/:token' element={<VerifyEmail />} />
						<Route path='/resetpassword/:token' element={<ResetPassword />} />
						<Route path='/designer' element={<Designer />} />
						<Route path='checkout' element={<Checkout />} />
						<Route path='/Faq' element={<Faq />} />
						<Route path='/provider' element={<Provider />} />
						<Route path='/media' element={<Media />} />
						<Route path='/synthchips' element={<SynthChips />} />
						<Route path='/openasc' element={<OpenASC />} />
						<Route path='/games' element={<Games />} />
					</Routes>
				</div>
			</>
		);
	}

	function Display() {
		if (isMobile) {
			return mobileDisplay();
		} else {
			return desktopDisplay();
		}
	}
	return (
		<>
			<section>{Display()}</section>

			<ToastContainer pauseOnFocusLoss={false} />
		</>
	);
}

export default App;
