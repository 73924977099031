import Modal from '../../builders/modal'
import AdministrateUser from './AdministrateUser'
import UserStatsSort from './UserStatsSort'

function UserControl() {
	return (
		<>
			<div className='centraliser'>
				<div>User control</div>
				<Modal
					header={`Administrate User`}
					content={<AdministrateUser />}
					btnTextorIcon={`Administrate User`}
					btnclass='signin signinwide'
				/>
				<UserStatsSort />
			</div>
		</>
	)
}

export default UserControl
