const Joi = require('joi')
const fields = require('../fields')

const cexPairSchema = Joi.object({
    name: fields.nameField.required().label('Pair Name'),
    buyCurrency: fields.currencyIdField.required(),
    sellCurrency: fields.currencyIdField.required(),
    canBuy: fields.boolField.required(),
    canSell: fields.boolField.required(),
    visible: fields.boolField.optional(),
    frozen: fields.boolField.optional(),
    frozenNote: fields.noteField.optional(),
    fee: fields.percentField.optional(),
    feeCollector: fields.nicknameField.optional().label('Fee Collector'),
});

module.exports = cexPairSchema;