import axios from 'axios'

const API_URL_INVENTORY = '/api/inventory/'

// Create Item Collection

const createItemCollection = async (itemCollectionData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_INVENTORY + 'createCollection', itemCollectionData, config)

	return response.data
}

// Create Item

const createItem = async (itemData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_INVENTORY + 'createItem', itemData, config)

	return response.data
}

// Open Alpha Synth Chip

const openSynthChips = async (itemData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_INVENTORY + 'openSynthChips', itemData, config)

	return response.data
}

// Get Item Collections

const getCollections = async token => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_INVENTORY + 'getCollections', config)

	return response.data
}

// Get Items

const getItems = async (data, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_INVENTORY + 'getItems', data, config)

	return response.data
}

// Get User Items Grouped

const getUserItemsGrouped = async (data, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_INVENTORY + 'getUserItemsGrouped', data, config)

	return response.data
}

// Get User Items

const getUserItems = async (data, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_INVENTORY + 'getUserItems', data, config)

	return response.data
}

// Clear Logs
const clearInventoryLogs = () => {}

const inventoryService = {
	createItemCollection,
	createItem,
	openSynthChips,
	getCollections,
	getItems,
	getUserItemsGrouped,
	getUserItems,
	clearInventoryLogs,
}

export default inventoryService
