import React from 'react'
import MintCurrency from './MintCurrency'
import CreateCurrency from './CreateCurrency'
import ModifyCurrency from './ModifyCurrency'
import BurnCurrency from './BurnCurrency'
import Modal from '../../builders/modal'
import CurrencyStatsSort from './CurrencyStatsSort'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getCurrencies } from '../../../features/bank/bankSlice'

function CurrencyControl() {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getCurrencies())
	}, [dispatch])

	return (
		<>
			<div className='divider'>
				<div className='h4_2'>Currency control</div>
				<div className='centraliser'>
					<Modal
						header={`Modify Currency`}
						content={<ModifyCurrency />}
						btnTextorIcon={`Modify Currency`}
						btnclass='signin signinwide'
					/>
					<Modal
						header={`Create Currency`}
						content={<CreateCurrency />}
						btnTextorIcon={`Create Currency`}
						btnclass='signin signinwide'
					/>
					<Modal
						header={`Mint Currency`}
						content={<MintCurrency />}
						btnTextorIcon={`Mint Currency`}
						btnclass='signin signinwide'
					/>
					<Modal
						header={`Burn Currency`}
						content={<BurnCurrency />}
						btnTextorIcon={`Burn Currency`}
						btnclass='signin signinwide'
					/>
				</div>
			</div>
			<div className='divider'>
				<CurrencyStatsSort />
			</div>
		</>
	)
}

export default CurrencyControl
