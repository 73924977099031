import { useSelector } from 'react-redux';

import Categories from '../../../util/transaction';

function CurrencyStats() {
	const currencyStats = useSelector(state => state.bank.currencyStats);
	const currencies = useSelector(state => state.bank.currencies);
	// make a copy of the currencystats array
	const sorted = currencyStats && [...currencyStats].sort((a, b) => a._id - b._id);

	return (
		<div className='cu_card_wrap'>
			{/* sort the currencyStats array by _id */}
			{currencies && currencyStats && currencyStats.length > 0 ? (
				sorted.map(currencyStats => (
					<div className='cu_card' key={currencyStats._id}>
						<div className='h2_2'>
							{currencies.find(currency => currency.currencyId === currencyStats._id).name}
							{'  '}({currencies.find(currency => currency.currencyId === currencyStats._id).ticker})
						</div>
						<div className='cu_card_image'>
							<img
								alt='token'
								src={
									currencies.find(currency => currency.currencyId === currencyStats._id).imgUrl
										? currencies.find(currency => currency.currencyId === currencyStats._id).imgUrl
										: 'https://dl.dropboxusercontent.com/s/s2oyo7dzqi8vsyr/default.gif?raw=1'
								}
							/>
						</div>
						<div className='ccc_border'>
							<div className='cu_card_content'>
								{[...currencyStats.categories]
									.sort((a, b) => a.category - b.category)
									.map(categories => (
										<div key={categories.category}>
											<div>
												<strong>
													{categories.category
														? Categories.type.find(category => category.id === categories.category).name
														: ''}
												</strong>
												<div>
													{categories.total}
													{' in '}
													{categories.count}
													{' txns'}
												</div>
												<br></br>
											</div>
										</div>
									))}
							</div>
							<div className='cu_card_content'>
								<strong>Totals</strong>
								<div>
									{/* look up the precision for the currency to limit decimal place */}
									{currencyStats.categories
										.reduce((acc, curr) => acc + curr.total, 0)
										.toFixed(
											currencies.find(currency => currency.currencyId === currencyStats._id).precision,
										)}
									{' in '}
									{currencyStats.categories.reduce((acc, curr) => acc + curr.count, 0).toFixed(0)}
									{' txns'}
								</div>
							</div>
							<br></br>
							<div className='cu_card_content'>
								<strong>Circulating</strong>
								<div>
									{/* use a reducer to calculate the values of the categories array.  If the categories.category is 1 or 10 add it to the total and if the categories.category is 2, 4 or 11 subtract it from the total and if the categries.category is any other value disregard*/}
									{currencyStats.categories
										.reduce((acc, curr) => {
											if (
												curr.category === 1 ||
												curr.category === 10 ||
												curr.category === 12 ||
												curr.category === 13 ||
												curr.category === 11 ||
												curr.category === 33 ||
												curr.category === 14
											) {
												return acc + curr.total;
											} else if (curr.category === 2 || curr.category === 4 || curr.category === 23) {
												return acc - curr.total;
											} else {
												return acc;
											}
										}, 0)
										.toFixed(0)}
								</div>
							</div>
						</div>
					</div>
				))
			) : (
				<div>No CurrencyStats</div>
			)}
		</div>
	);
}

export default CurrencyStats;
