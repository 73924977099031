import axios from 'axios'
import { toast } from 'react-toastify'

const API_URL_BANK = '/api/bank/'

// p2pTransfer
const initTransfer = async (transactionData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'p2pTransfer', transactionData, config)

	if (response.data) {
		localStorage.setItem('user', JSON.stringify(response.data.senderUser))
		toast.success(response.data.message)
	}

	return response.data
}

// Mint Currency
const initMint = async (transactionData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'mint', transactionData, config)

	if (response.data) {
	}

	return response.data
}

// Burn Currency
const initBurn = async (transactionData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'burn', transactionData, config)

	if (response.data) {
	}

	return response.data
}

// Withdraw from BoS to wallet
const withdraw = async (transactionData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'withdraw', transactionData, config)

	if (response.data) {
		localStorage.setItem('user', JSON.stringify(response.data.senderUser))
	}

	return response.data
}

// Deposit from wallet to BoS
const deposit = async (transactionData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'deposit', transactionData, config)

	if (response.data) {
		localStorage.setItem('user', JSON.stringify(response.data.senderUser))
	}

	return response.data
}

// Get Currencies
const getCurrencies = async () => {
	const response = await axios.get(API_URL_BANK + 'currencies')

	if (response.data) {
		localStorage.setItem('currencies', JSON.stringify(response.data.currencies))
	}

	return response.data
}

// Create Currency
const createCurrency = async (currencyData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'createCurrency', currencyData, config)

	if (response.data) {
	}

	return response.data
}

// Modify Currency
const modifyCurrency = async (currencyData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'modifyCurrency', currencyData, config)

	if (response.data) {
	}

	return response.data
}

// Get Transactions
const getTransactions = async (logData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'alltransactions', logData, config)

	return response.data
}

// Get User Transactions
const getUserTransactions = async (logData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'transactions', logData, config)

	return response.data
}

// Get Currency Stats
const getCurrencyStats = async (logData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'currencyStats', logData, config)

	return response.data
}

// Clear Logs
const clearBankLogs = () => {}

// Distribute Currency
const distributeCurrency = async (currencyData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_BANK + 'distributeCurrency', currencyData, config)

	return response.data
}

const bankService = {
	initTransfer,
	getCurrencies,
	createCurrency,
	modifyCurrency,
	initMint,
	initBurn,
	withdraw,
	deposit,
	getTransactions,
	getUserTransactions,
	clearBankLogs,
	getCurrencyStats,
	distributeCurrency,
}

export default bankService
