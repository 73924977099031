import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { registerWallet, loginWallet, loginWalletCApp } from '../features/auth/authSlice'
import {
	reset,
	setOpState,
	completeOp,
	failOp,
	OpStates,
	getNonce,
	setWalletAction,
	setWalletCAppAuthId,
} from '../features/auth/walletSlice'

function MetamaskButton(props) {
	const dispatch = useDispatch()
	const walletState = useSelector(state => state.wallet)
	const authState = useSelector(state => state.auth)

	useEffect(() => {
		//I wrapped this bit up in a function so that I can bail out on errors using 'return'
		const signAndRegister = async () => {
			let signer
			try {
				signer = window.userAddress
			} catch (err) {
				dispatch(failOp(err.message))
				return
			}
			if (!signer) {
				dispatch(failOp('Metamask not ready'))
				return
			}
			try {
				//Attempt to sign
				const signature = await window.ethereum.request({
					method: 'personal_sign',
					params: [walletState.messageToSign, signer],
				})

				const walletPayload = { walletAddress: signer, signature }
				if (walletState.walletAction === 'register') dispatch(registerWallet(walletPayload))
				else {
					if (!walletState.cAppAuthId) dispatch(loginWallet(walletPayload))
					else {
						walletPayload.cAppAuthRequest = walletState.cAppAuthId
						console.log(walletPayload)
						dispatch(loginWalletCApp(walletPayload))
					}
				}

				dispatch(setOpState(OpStates.WaitForFinish))
				return
			} catch (err) {
				dispatch(failOp('You need to sign the message to continue'))
			}
		}

		if (walletState.isErrorWallet) {
			toast.error(walletState.messageWallet)
		}
		if (walletState.opState === OpStates.WaitForFinish) {
			if (authState.isErrorAuth) dispatch(failOp('Wallet Authentication: Failed'))
			else dispatch(completeOp())
		}
		if (walletState.opState === OpStates.GotNonce && walletState.messageToSign) {
			dispatch(setOpState(OpStates.WaitForSig))
			signAndRegister()
		}

		dispatch(reset())
	}, [walletState, authState, dispatch])

	async function connectWithWallet() {
		dispatch(setOpState(OpStates.WaitForConnectWallet))

		if (window.ethereum) {
			try {
				window.userAddress = await window.ethereum
					.request({ method: 'eth_requestAccounts' })
					.then(accounts => accounts[0])
					.catch(() => {
						throw Error('No account selected!')
					})
				window.localStorage.setItem('walletAddress', window.userAddress)
				dispatch(getNonce({ walletAddress: window.userAddress }))
			} catch (error) {
				dispatch(failOp(error.message))
			}
		} else {
			dispatch(failOp('No Metamask browser extension detected'))
		}
	}

	const preSignInWithWallet = async e => {
		e.preventDefault()

		if (props.action !== 'register' && props.action !== 'login') {
			console.log(
				`ERROR: MetamaskButton must have an "action" property of either "register" or "login"!\nInstead got ${props.action}`,
			)
			return
		}

		if (walletState.opState !== OpStates.Idle) return //Prevent user from clicking again while we wait on stuff to finish

		dispatch(setWalletAction(props.action)) //Store the final step's action in a way that doesn't make this component re-render
		dispatch(setWalletCAppAuthId(props.authId)) //Same but for CAppAuthId
		await connectWithWallet() //Make sure metamask is connected with a valid wallet
	}

	return (
		<>
			<button onClick={preSignInWithWallet} id='step1' className='signinMetaMask'>
				.
			</button>
		</>
	)
}

export default MetamaskButton
