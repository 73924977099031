import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import checkoutService from './checkoutService'
import { getErrMsg } from '../../util/generic'

const initialState = {
	isErrorCheckout: false,
	isSuccessCheckout: false,
	isLoadingCheckout: false,
	messageCheckout: '',
	logs: null,
}

// Get Packages
export const getPackages = createAsyncThunk('checkout/packages/fetch', async (_, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await checkoutService.getPackages(token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Create Package
export const createPackage = createAsyncThunk('checkout/packages/create', async (packageData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await checkoutService.createPackage(packageData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Delete Package
export const deletePackage = createAsyncThunk('checkout/packages/delete', async (packageData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await checkoutService.deletePackage(packageData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Publish Package
export const publishPackage = createAsyncThunk('checkout/packages/publish', async (packageData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await checkoutService.publishPackage(packageData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Set Active state of Package
export const setActivePackage = createAsyncThunk(
	'checkout/packages/setactive',
	async (packageData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token
			return await checkoutService.setActivePackage(packageData, token)
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error))
		}
	},
)

// Purchase Package
export const purchasePackage = createAsyncThunk(
	'checkout/packages/purchase',
	async (purchaseData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token
			return await checkoutService.purchasePackage(purchaseData, token)
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error))
		}
	},
)

// Get User Checkout Transactions
export const userCheckoutTransactions = createAsyncThunk(
	'checkout/transactions',
	async (logData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token
			return await checkoutService.userCheckoutTransactions(logData, token)
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error))
		}
	},
)

// Get All Checkout Transactions
export const allCheckoutTransactions = createAsyncThunk(
	'checkout/alltransactions',
	async (logData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token
			return await checkoutService.allCheckoutTransactions(logData, token)
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error))
		}
	},
)

// Clear Checkout Logs
export const clearCheckoutLogs = createAsyncThunk('checkout/clearLogs', async () => {
	checkoutService.clearCheckoutLogs()
})

export const checkoutSlice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		resetCheckout: state => {
			state.isLoadingCheckout = false
			state.isSuccessCheckout = false
			state.isErrorCheckout = false
			state.messageCheckout = ''
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getPackages.pending, state => {
				state.isLoadingCheckout = true
			})
			.addCase(getPackages.fulfilled, (state, action) => {
				state.isLoadingCheckout = false
				state.isSuccessCheckout = false
				state.packages = action.payload.data
				state.messageCheckout = action.payload.message
			})
			.addCase(getPackages.rejected, (state, action) => {
				state.isLoadingCheckout = false
				state.isErrorCheckout = true
				state.messageCheckout = action.payload
			})
			.addCase(createPackage.pending, state => {
				state.isLoadingCheckout = true
			})
			.addCase(createPackage.fulfilled, (state, action) => {
				state.isLoadingCheckout = false
				state.isSuccessCheckout = true
				state.messageCheckout = action.payload.message
			})
			.addCase(createPackage.rejected, (state, action) => {
				state.isLoadingCheckout = false
				state.isErrorCheckout = true
				state.messageCheckout = action.payload
			})
			.addCase(deletePackage.pending, state => {
				state.isLoadingCheckout = true
			})
			.addCase(deletePackage.fulfilled, (state, action) => {
				state.isLoadingCheckout = false
				state.isSuccessCheckout = true
				state.messageCheckout = action.payload.message
			})
			.addCase(deletePackage.rejected, (state, action) => {
				state.isLoadingCheckout = false
				state.isErrorCheckout = true
				state.messageCheckout = action.payload
			})
			.addCase(publishPackage.pending, state => {
				state.isLoadingCheckout = true
			})
			.addCase(publishPackage.fulfilled, (state, action) => {
				state.isLoadingCheckout = false
				state.isSuccessCheckout = true
				state.messageCheckout = action.payload.message
			})
			.addCase(publishPackage.rejected, (state, action) => {
				state.isLoadingCheckout = false
				state.isErrorCheckout = true
				state.messageCheckout = action.payload
			})
			.addCase(setActivePackage.pending, state => {
				state.isLoadingCheckout = true
			})
			.addCase(setActivePackage.fulfilled, (state, action) => {
				state.isLoadingCheckout = false
				state.isSuccessCheckout = true
				state.messageCheckout = action.payload.message
			})
			.addCase(setActivePackage.rejected, (state, action) => {
				state.isLoadingCheckout = false
				state.isErrorCheckout = true
				state.messageCheckout = action.payload
			})
			.addCase(purchasePackage.pending, state => {
				state.isLoadingCheckout = true
			})
			.addCase(purchasePackage.fulfilled, (state, action) => {
				state.isLoadingCheckout = false
				state.isSuccessCheckout = true
				state.messageCheckout = action.payload.message
				state.redirectUrl = action.payload.link
			})
			.addCase(purchasePackage.rejected, (state, action) => {
				state.isLoadingCheckout = false
				state.isErrorCheckout = true
				state.messageCheckout = action.payload
			})
			.addCase(userCheckoutTransactions.pending, state => {
				state.isLoadingCheckout = true
			})
			.addCase(userCheckoutTransactions.fulfilled, (state, action) => {
				state.isLoadingCheckout = false
				state.isSuccessCheckout = true
				state.messageCheckout = action.payload.message
				state.logs = action.payload.data
			})
			.addCase(userCheckoutTransactions.rejected, (state, action) => {
				state.isLoadingCheckout = false
				state.isErrorCheckout = true
				state.messageCheckout = action.payload
			})
			.addCase(allCheckoutTransactions.pending, state => {
				state.isLoadingCheckout = true
			})
			.addCase(allCheckoutTransactions.fulfilled, (state, action) => {
				state.isLoadingCheckout = false
				state.isSuccessCheckout = true
				state.messageCheckout = action.payload.message
				state.logs = action.payload.data
			})
			.addCase(allCheckoutTransactions.rejected, (state, action) => {
				state.isLoadingCheckout = false
				state.isErrorCheckout = true
				state.messageCheckout = action.payload
			})
			.addCase(clearCheckoutLogs.pending, state => {
				state.logs = null
			})
			.addCase(clearCheckoutLogs.fulfilled, (state, action) => {
				state.logs = null
			})
			.addCase(clearCheckoutLogs.rejected, (state, action) => {
				state.logs = null
			})
	},
})

export const { resetCheckout } = checkoutSlice.actions

export default checkoutSlice.reducer
