import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allTransactions } from '../../../features/bank/bankSlice'

import Categories from '../../../util/transaction'
import TransactionListAdmin from './TransactionListAdmin'

function TransactionSortAdmin(props) {
	const [formData, setFormData] = useState({
		recLimit: '',
		pageNum: '',
		nickname: '',
		startDate: '',
		endDate: '',
		currencyId: '',
		amountAbove: '',
		amountBelow: '',
		category: '',
	})

	const pageNum = props.pageNum

	const { recLimit, nickname, startDate, endDate, currencyId, amountAbove, amountBelow, category } = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const { currencies } = useSelector(state => state.bank)

	const onSubmit = e => {
		e.preventDefault()

		const logData = {
			paging: {
				limit: recLimit ? recLimit * 1 : 10,
				page: pageNum * 1,
			},
			nickname: nickname ? nickname : '',
			startDate: startDate ? startDate : '',
			endDate: endDate ? endDate : '',
			currencyId: currencyId ? currencyId : '',
			amountAbove: amountAbove ? amountAbove : '',
			amountBelow: amountBelow ? amountBelow : '',
			category: category ? category : '',
		}

		Object.keys(logData).forEach(field => {
			if (logData[field] === '') {
				delete logData[field]
			}
		})

		dispatch(allTransactions(logData))
	}

	return (
		<div className='centraliser'>
			<form onSubmit={onSubmit}>
				<div className='tsa_unit'>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Record Limit: </div>
							<select className='form-input3 fi_mini' name='recLimit' value={recLimit} onChange={onChange}>
								<option value='10'>10</option>
								<option value='20'>20</option>
								<option value='50'>50</option>
							</select>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>Nickname: </div>
							<input
								type='text'
								className='form-input3 fi_mini'
								id='nickname'
								name='nickname'
								value={nickname}
								placeholder='nickname'
								onChange={onChange}
							/>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Start Date: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='startDate'
								name='startDate'
								value={startDate}
								placeholder='start date'
								onChange={onChange}
							/>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>End Date: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='endDate'
								name='endDate'
								value={endDate}
								placeholder='end date'
								onChange={onChange}
							/>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Currency: </div>
							<select
								className='form-input3 fi_mini'
								id='currencyId'
								name='currencyId'
								value={currencyId}
								onChange={onChange}
							>
								<option value=''>All</option>
								{/* find the currency id and name from currency array in local storage */}
								{currencies.map(currencies => (
									<option key={currencies.currencyId} value={currencies.currencyId}>
										{currencies.ticker}
									</option>
								))}
							</select>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>Category: </div>
							<select
								className='form-input3 fi_mini'
								id='category'
								name='category'
								value={category}
								onChange={onChange}
							>
								<option value=''>All</option>
								{/* get category list from import transactions JSON file */}
								{Categories.type.map(data => (
									<option key={data.id} value={data.id}>
										{data.name}
									</option>
								))}
							</select>
						</div>
					</div>
					<div>
						<div className='wrap2_2'>
							<div className='f1_2'>Amount Above: </div>
							<input
								type='text'
								className='form-input3 fi_mini'
								id='amountAbove'
								name='amountAbove'
								value={amountAbove}
								placeholder='amount above'
								onChange={onChange}
							/>
						</div>
						<div className='wrap2_2'>
							<div className='f1_2'>Amount Below: </div>
							<input
								type='text'
								className='form-input3 fi_mini'
								id='amountBelow'
								name='amountBelow'
								value={amountBelow}
								placeholder='amount below'
								onChange={onChange}
							/>
						</div>
					</div>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin' onClick={() => props.changePageNum(1)}>
						Search
					</button>
				</div>
				<div className='centraliser c_vmargin'>
					<button
						type='submit'
						className='signin signinmini'
						name='previous'
						onClick={() => {
							if (pageNum === 2) {
								props.changePageNum(1)
							} else if (pageNum > 2) {
								props.changePageNum(pageNum - 1)
							}
						}}
					>
						{pageNum === 1 ? '⭮' : '«'}
					</button>
					<span>Page : {pageNum} </span>
					<button
						type='submit'
						className='signin signinmini'
						name='next'
						onClick={() => props.changePageNum(pageNum + 1)}
					>
						»
					</button>
				</div>
				<TransactionListAdmin />
				<div className='centraliser c_vmargin'>
					<button
						type='submit'
						className='signin signinmini'
						name='previous'
						onClick={() => {
							if (pageNum === 2) {
								props.changePageNum(1)
							} else if (pageNum > 2) {
								props.changePageNum(pageNum - 1)
							}
						}}
					>
						{pageNum === 1 ? '⭮' : '«'}
					</button>
					<span>Page : {pageNum} </span>
					<button
						type='submit'
						className='signin signinmini'
						name='next'
						onClick={() => props.changePageNum(pageNum + 1)}
					>
						»
					</button>
				</div>
			</form>
		</div>
	)
}

export default TransactionSortAdmin
