import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { userCheckoutTransactions } from '../../../features/checkout/checkoutSlice'

import States from '../../../util/checkoutTransactionStates.json'
import CheckoutListUser from './CheckoutListUser'

function CheckoutSortAdmin(props) {
	const [formData, setFormData] = useState({
		recLimit: '',
		pageNum: '',
		startDate: '',
		endDate: '',
		currentstate: '',
	})

	const pageNum = props.pageNum

	const { recLimit, startDate, endDate, currentstate } = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const logData = {
			paging: {
				limit: recLimit ? recLimit * 1 : 10,
				page: pageNum * 1,
			},
			filters: {
				startDate: startDate ? startDate : '',
				endDate: endDate ? endDate : '',
				state: currentstate ? currentstate * 1 : '',
			},
		}

		Object.keys(logData.filters).forEach(key => {
			if (logData.filters[key] === '' || isNaN(logData.filters[key])) {
				delete logData.filters[key]
			}
		})

		dispatch(userCheckoutTransactions(logData))
	}

	return (
		<div className='centraliser'>
			<form onSubmit={onSubmit}>
				<div className='tsa_unit'>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Record Limit: </div>
							<select className='form-input3 fi_mini' name='recLimit' value={recLimit} onChange={onChange}>
								<option value='10'>10</option>
								<option value='20'>20</option>
								<option value='50'>50</option>
							</select>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>State: </div>
							<select
								className='form-input3 fi_mini'
								id='currentstate'
								name='currentstate'
								value={currentstate}
								onChange={onChange}
							>
								<option>All</option>
								{States.type.map(state => (
									<option key={state.id} value={state.id}>
										{state.name}
									</option>
								))}
							</select>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Start Date: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='startDate'
								name='startDate'
								value={startDate}
								placeholder='start date'
								onChange={onChange}
							/>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>End Date: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='endDate'
								name='endDate'
								value={endDate}
								placeholder='end date'
								onChange={onChange}
							/>
						</div>
					</div>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin' onClick={() => props.changePageNum(1)}>
						Search
					</button>
				</div>
				<div className='centraliser c_vmargin'>
					<button
						type='submit'
						className='signin signinmini'
						name='previous'
						onClick={() => {
							if (pageNum === 2) {
								props.changePageNum(1)
							} else if (pageNum > 2) {
								props.changePageNum(pageNum - 1)
							}
						}}
					>
						{pageNum === 1 ? '⭮' : '«'}
					</button>
					<span>Page : {pageNum} </span>
					<button
						type='submit'
						className='signin signinmini'
						name='next'
						onClick={() => props.changePageNum(pageNum + 1)}
					>
						»
					</button>
				</div>
				<CheckoutListUser />
				<div className='centraliser c_vmargin'>
					<button
						type='submit'
						className='signin signinmini'
						name='previous'
						onClick={() => {
							if (pageNum === 2) {
								props.changePageNum(1)
							} else if (pageNum > 2) {
								props.changePageNum(pageNum - 1)
							}
						}}
					>
						{pageNum === 1 ? '⭮' : '«'}
					</button>
					<span>Page : {pageNum} </span>
					<button
						type='submit'
						className='signin signinmini'
						name='next'
						onClick={() => props.changePageNum(pageNum + 1)}
					>
						»
					</button>
				</div>
			</form>
		</div>
	)
}

export default CheckoutSortAdmin
