const Joi = require('joi')
const fields = require('../fields')

const modifyCexPairSchema = Joi.object({
    pairId: fields.objectIdField.required().label('Pair ID'),
    name: fields.nameField.optional().label('Pair Name'),
    visible: fields.boolField.optional(),
    frozen: fields.boolField.optional(),
    canBuy: fields.boolField.optional(),
    canSell: fields.boolField.optional(),
    frozenNote: fields.noteField.optional(),
    fee: fields.percentField.optional(),
    feeCollector: fields.nicknameField.optional().label('Fee Collector'),
});

module.exports = modifyCexPairSchema;