const Joi = require('joi')
const fields = require('../fields')
const checkoutTransactionFiltersSchema = require('./checkoutTransactionFilters.validator')

const checkoutAllTransactionFiltersSchema = checkoutTransactionFiltersSchema.keys({
    user: fields.objectIdField,
    nickname: fields.nicknameField,
    package: fields.objectIdField,
    checkoutProvider: fields.integerAboveZeroField,
    refId: Joi.string(),
    ip: fields.ipAddress,
})

module.exports = checkoutAllTransactionFiltersSchema