const Joi = require('joi')
const { registerPasswordField, nicknameField, emailField } = require('./fields')

const registerSchema = Joi.object({
    nickname: nicknameField.required(),
    email: emailField.required(),
    password: registerPasswordField.required(),
    referrer: nicknameField,
})

module.exports = registerSchema