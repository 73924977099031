import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUsersListAdmin } from '../../../features/auth/authSlice'

import UserListAdmin from './UsersListAdmin'

function UsersSortAdmin(props) {
	const [formData, setFormData] = useState({
		pageNum: '',
		recLimit: '',
		nickname: '',
		email: '',
		role: '',
		isActive: '',
		isWalletOnly: '',
		isEmailVerified: '',
		isAnonymous: '',
		createdAfter: '',
		createdBefore: '',
	})

	const pageNum = props.pageNum

	const {
		recLimit,
		nickname,
		email,
		role,
		isActive,
		isWalletOnly,
		isEmailVerified,
		isAnonymous,
		createdAfter,
		createdBefore,
	} = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const sortData = {
			paging: {
				limit: recLimit ? recLimit * 1 : 10,
				page: pageNum * 1,
			},
			nickname: nickname ? nickname : '',
			email: email ? email : '',
			role: role ? role : '',
			isActive: isActive ? isActive : '',
			isWalletOnly: isWalletOnly ? isWalletOnly : '',
			isEmailVerified: isEmailVerified ? isEmailVerified : '',
			isAnonymous: isAnonymous ? isAnonymous : '',
			createdAfter: createdAfter ? createdAfter : '',
			createdBefore: createdBefore ? createdBefore : '',
		}

		Object.keys(sortData).forEach(key => {
			if (sortData[key] === '') {
				delete sortData[key]
			}
		})

		dispatch(getUsersListAdmin(sortData))
	}

	return (
		<div className='centraliser'>
			<form onSubmit={onSubmit}>
				<div className='tsa_unit2'>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Record Limit: </div>
							<select className='form-input3 fi_mini' name='recLimit' value={recLimit} onChange={onChange}>
								<option value='10'>10</option>
								<option value='20'>20</option>
								<option value='50'>50</option>
							</select>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>Nickname: </div>
							<input
								type='text'
								className='form-input3 fi_mini'
								id='nickname'
								name='nickname'
								value={nickname}
								placeholder='nickname'
								onChange={onChange}
							/>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Email: </div>
							<input
								type='text'
								className='form-input3 fi_mini'
								id='email'
								name='email'
								value={email}
								placeholder='email'
								onChange={onChange}
							/>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>Role: </div>
							<input
								type='text'
								className='form-input3 fi_mini'
								id='role'
								name='role'
								value={role}
								placeholder='role'
								onChange={onChange}
							/>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>isActive: </div>
							<select className='form-input3 fi_mini' name='isActive' value={isActive} onChange={onChange}>
								<option value=''></option>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</select>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>isWalletOnly: </div>
							<select
								className='form-input3 fi_mini'
								name='isWalletOnly'
								value={isWalletOnly}
								onChange={onChange}
							>
								<option value=''></option>
								<option value={false}>No</option>
								<option value={true}>Yes</option>
							</select>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>isEmailVerified: </div>
							<select
								className='form-input3 fi_mini'
								name='isEmailVerified'
								value={isEmailVerified}
								onChange={onChange}
							>
								<option value=''></option>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</select>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>isAnonymous: </div>
							<select
								className='form-input3 fi_mini'
								name='isAnonymous'
								value={isAnonymous}
								onChange={onChange}
							>
								<option value=''></option>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</select>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Created After: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='createdAfter'
								name='createdAfter'
								value={createdAfter}
								placeholder='Created After'
								onChange={onChange}
							/>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>Created Before: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='createdBefore'
								name='createdBefore'
								value={createdBefore}
								placeholder='Created Before'
								onChange={onChange}
							/>
						</div>
					</div>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin' onClick={() => props.changePageNum(1)}>
						Search
					</button>
				</div>
				<div className='centraliser c_vmargin'>
					<button
						type='submit'
						className='signin signinmini'
						name='previous'
						onClick={() => {
							if (pageNum === 2) {
								props.changePageNum(1)
							} else if (pageNum > 2) {
								props.changePageNum(pageNum - 1)
							}
						}}
					>
						{pageNum === 1 ? '⭮' : '«'}
					</button>
					<span>Page : {pageNum} </span>
					<button
						type='submit'
						className='signin signinmini'
						name='next'
						onClick={() => props.changePageNum(pageNum + 1)}
					>
						»
					</button>
				</div>
				<UserListAdmin />
				<div className='centraliser c_vmargin'>
					<button
						type='submit'
						className='signin signinmini'
						name='previous'
						onClick={() => {
							if (pageNum === 2) {
								props.changePageNum(1)
							} else if (pageNum > 2) {
								props.changePageNum(pageNum - 1)
							}
						}}
					>
						{pageNum === 1 ? '⭮' : '«'}
					</button>
					<span>Page : {pageNum} </span>
					<button
						type='submit'
						className='signin signinmini'
						name='next'
						onClick={() => props.changePageNum(pageNum + 1)}
					>
						»
					</button>
				</div>
			</form>
		</div>
	)
}

export default UsersSortAdmin
