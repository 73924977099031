import { getPairs, getOrders, placeOrder, resetCex } from '../../features/cex/cexSlice';
import { getUser, logout } from '../../features/auth/authSlice';
import { getCurrencies } from '../../features/bank/bankSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import './SynthChips.css';

function SynthChipSale() {
	const { user } = useSelector(state => state.auth);
	const { currencies } = useSelector(state => state.bank);
	const { orders, tradingPairs, isLoadingCex, isSuccessCex, isErrorCex, messageCex } = useSelector(
		state => state.cex,
	);
	const navigate = useNavigate();

	const dispatch = useDispatch();
	useEffect(() => {
		if (!currencies) {
			dispatch(getCurrencies());
		}
	}, [dispatch, currencies]);

	const Login = () => {
		navigate('/login');
	};

	const cardPackCurrencyId =
		currencies && currencies.find(currency => currency.name === 'Alpha Synth Chip')?.currencyId;

	const cardPair =
		tradingPairs &&
		tradingPairs &&
		tradingPairs.find(pair => pair.sellCurrency === cardPackCurrencyId)?._id;

	const pair =
		orders &&
		orders.sells &&
		orders.sells.length > 0 &&
		orders.sells.find(order => order.pair === cardPair)?.pair;
	const price =
		orders &&
		orders.sells &&
		orders.sells.length > 0 &&
		orders.sells.find(order => order.pair === cardPair)?.price;
	const initialAmount =
		orders &&
		orders.sells &&
		orders.sells.length > 0 &&
		orders.sells.find(order => order.pair === cardPair)?.initialAmount;
	const filledAmount =
		orders &&
		orders.sells &&
		orders.sells.length > 0 &&
		orders.sells.find(order => order.pair === cardPair)?.filledAmount;
	const remainingAmount = initialAmount ? initialAmount - filledAmount : 0;

	useEffect(() => {
		if (!tradingPairs) {
			dispatch(getPairs());
		} else if (!orders) {
			dispatch(getOrders({ pair: cardPair }));
		}
	}, [dispatch, tradingPairs, orders, cardPair]);

	const [quantity, setQuantity] = useState(0);

	const onChange = e => setQuantity(e.target.value);

	const onSubmit = e => {
		e.preventDefault();

		const orderData = {
			pair: pair,
			buySide: true,
			initialAmount: quantity,
			price: price,
		};

		dispatch(placeOrder(orderData));
	};

	useEffect(() => {
		if (isErrorCex) {
			toast.error(messageCex);
			// if error contains Unauthorized, logout user
			if (messageCex.includes('Unauthorized')) dispatch(logout());
		}
		if (
			isSuccessCex &&
			!messageCex.match(/Got \d+ pair\(s\)/) &&
			!messageCex.match(/Got \d+ Orders/)
		) {
			toast.success(messageCex);
		}

		dispatch(resetCex());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorCex, isSuccessCex, messageCex]);

	useEffect(() => {
		if (messageCex && messageCex.includes('Placed a Buy Order')) {
			const orderData = {
				pair: cardPair,
			};
			// wait 2000ms before getting the orders again
			setTimeout(() => {
				dispatch(getOrders(orderData));
				dispatch(getUser());
			}, 2000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messageCex]);

	if (isLoadingCex) return <Spinner />;

	return (
		<div>
			{/* display the text vertical */}
			<header className='synthchipsale'>Synth Chips</header>
			<div className='synthchipback' />

			<div className='cp_flexcontainer'>
				{/* do not show the next div if isMobile */}
				{!isMobile && (
					<div className='cp_iconsL'>
						<img
							className='cp_unit2'
							alt='cardsale'
							src='https://dl.dropboxusercontent.com/s/0o7asozzjcev7wm/assaulticon.png?raw=1'
						/>
						<img
							className='cp_unit2'
							alt='cardsale'
							src='https://dl.dropboxusercontent.com/s/fyz6pb1u38v79wk/levelicon.png?raw=1'
						/>
						<img
							className='cp_unit2'
							alt='cardsale'
							src='https://dl.dropboxusercontent.com/s/3uj2jo54iwg17os/exploreicon.png?raw=1'
						/>
					</div>
				)}
				<div className='cp_unit'>
					<div className='cp_card corners--round'>
						<div className='cp_title'>Alpha Chip</div>{' '}
						<div className='chipcount'>
							{remainingAmount > 0 ? (
								<>
									Only
									<br />
									{remainingAmount}
									<br />
									Left
								</>
							) : (
								<>
									All
									<br />
									Sold
									<br />
									Out
								</>
							)}
						</div>
						<img
							className=''
							alt='cardsale'
							src='https://dl.dropboxusercontent.com/s/8vg2bgkhiyu2fcu/background.png?raw=1'
						/>
						<div className='chiptextarea'>
							<div className='b_margin'>
								Synth chips are 500 credits each. Each synth chip once activated will provide 5 ship
								cards.
							</div>
							{user ? (
								<>
									<div className='centraliser'>Amount to Buy: </div>
									<form onSubmit={e => onSubmit(e)}>
										<div className='centraliser tb_margin'>
											<input
												type='number'
												min='0'
												name='quantity'
												placeholder='Quantity'
												className='form-input3'
												value={quantity}
												onChange={e => onChange(e)}
											/>
										</div>
										<label>
											{/* multiply the quantity * price */}
											Total Credits: {price * quantity}
										</label>
										<div className='centraliser tb_margin'>
											{remainingAmount > 0 ? (
												<button type='submit' className='signin signinwide2'>
													Buy Synth Chips
												</button>
											) : (
												<button type='submit' className='signin signinwide2' disabled>
													Sold Out
												</button>
											)}
										</div>
									</form>
								</>
							) : (
								<>
									<div className='centraliser loginsynth'>
										<h2>
											<button className='synthbutton' onClick={Login}>
												Login
											</button>{' '}
											to buy or view
										</h2>
									</div>
								</>
							)}
							{user ? (
								<div className='centraliser tb_margin'>
									<button className='signin signinwide2' onClick={() => navigate('/openasc')}>
										Open Synth Chips
									</button>
								</div>
							) : null}
						</div>
					</div>
				</div>
				{/* do not show the next div if isMobile */}
				{!isMobile && (
					<div className='cp_iconsR'>
						<img
							className='cp_unit2'
							alt='cardsale'
							src='https://dl.dropboxusercontent.com/s/yxu42eqtowuxu6d/mineicon.png?raw=1'
						/>
						<img
							className='cp_unit2'
							alt='cardsale'
							src='https://dl.dropboxusercontent.com/s/fyz6pb1u38v79wk/levelicon.png?raw=1'
						/>
						<img
							className='cp_unit2'
							alt='cardsale'
							src='https://dl.dropboxusercontent.com/s/pzr9n6hx54f675c/haulicon.png?raw=1'
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default SynthChipSale;
