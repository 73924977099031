import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFaq, resetPublic } from '../../features/public/publicSlice'

function FaqArray(params) {
	const [open, setOpen] = useState(false)
	const { faq } = useSelector(state => state.public)

	const toggle = id => {
		if (open === id) {
			// if currently open, then close
			setOpen(false)
		} else {
			// if currently closed, then open
			setOpen(id)
		}
	}

	const dispatch = useDispatch()

	useEffect(() => {
		if (!faq) {
			dispatch(getFaq())
		}
		dispatch(resetPublic())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div>
			<header>{params.header}</header>
			<div className='faq_contentcontainer'>
				{faq &&
					faq[params.content] &&
					faq[params.content].map((type, id) => (
						<div key={id} className='faq_item'>
							<button
								className={open === id ? 'faq_question is-open' : 'faq_question'}
								id={id}
								onClick={() => toggle(id)}
							>
								<div className='faq_left'>Q :</div>
								<div className='faq_right'>{type.question}</div>
							</button>
							{open === id && (
								<div className='faq_answer'>
									<div className='faq_left'>A :</div>
									<div className='faq_right'>
										{/* display the answer if there is a '\n' in the answer add a <br /> and strong anything between '\b' and '\b' */}
										{type.answer.split('\n').map((itemn, key) => {
											return (
												<span key={key}>
													{itemn.split('\b').map((itemb, key) => {
														return <span key={key}>{key % 2 === 0 ? itemb : <strong>{itemb}</strong>}</span>
													})}
													<br />
												</span>
											)
										})}
									</div>
								</div>
							)}
						</div>
					))}
			</div>
		</div>
	)
}

export default FaqArray
