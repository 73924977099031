import React, { useState } from 'react'
import Socialmedia from '../components/Socialmedia'
import Pressreleases from '../components/Pressreleases'
import Reports from './Reports'

function Media() {
    const [activeTab, setActiveTab] = useState('Socialmedia')
	return (
      <div>
        <header>Starlight Nation Media</header>

        <div className='centraliser2'>
				<div className='tabs'>
					<nav>
						<button className='tab' onClick={() => setActiveTab('Socialmedia')}>
							<p className='glow'>Social Media</p>
						</button>
						<button className='tab' onClick={() => setActiveTab('Pressreleases')}>
							<p className='glow'>Press Releases</p>
						</button>
                        <button className='tab' onClick={() => setActiveTab('Reports')}>
							<p className='glow'>Reports</p>
						</button>
					</nav>
				</div>
				
			</div>
            <div className='profilebox'>
					{activeTab === 'Socialmedia' && <Socialmedia />}
					{activeTab === 'Pressreleases' && <Pressreleases />}
                    {activeTab === 'Reports' && <Reports />}
				</div>


      </div>
    )
}

export default Media