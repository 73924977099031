import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrencies, modifyCurrency } from '../../../features/bank/bankSlice'

function ModifyCurrency() {
	const [formData, setFormData] = useState({
		currencyId: '',
		ticker: '',
		name: '',
		precision: '',
		canDeposit: '',
		canWithdraw: '',
		canTransfer: '',
		depositTax: '',
		withdrawTax: '',
		transferTax: '',
		isFrozen: '',
		frozenNote: '',
		taxCollector: '',
		imgUrl: '',
	})

	const {
		currencyId,
		ticker,
		name,
		precision,
		canDeposit,
		canWithdraw,
		canTransfer,
		depositTax,
		withdrawTax,
		transferTax,
		isFrozen,
		frozenNote,
		taxCollector,
		imgUrl,
	} = formData

	const dispatch = useDispatch()
	const { currencies, isSuccessBank } = useSelector(state => state.bank)

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	useEffect(() => {
		if (isSuccessBank) {
			dispatch(getCurrencies())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessBank])

	const onSubmit = e => {
		e.preventDefault()

		const currencyData = {
			currencyId: currencyId * 1,
			ticker: ticker ? ticker : '',
			name: name ? name : '',
			precision: precision,
			canDeposit: canDeposit,
			canWithdraw: canWithdraw,
			canTransfer: canTransfer,
			depositTax: depositTax ? depositTax * 0.01 : '',
			withdrawTax: withdrawTax ? withdrawTax * 0.01 : '',
			transferTax: transferTax ? transferTax * 0.01 : '',
			isFrozen: isFrozen,
			frozenNote,
			taxCollector: taxCollector ? taxCollector : '',
			imgUrl: imgUrl ? imgUrl : '',
		}

		Object.keys(currencyData).forEach(key => {
			if (currencyData[key] === '') {
				delete currencyData[key]
			}
		})

		dispatch(modifyCurrency(currencyData))
	}

	return (
		<>
			<div>
				<form onSubmit={onSubmit}>
					<div className='wrap2'>
						<div className='f1_2'>Currency: </div>
						<select
							className='form-input3'
							id='currencyId'
							name='currencyId'
							value={currencyId}
							onChange={onChange}
						>
							<option />
							{currencies.map(currencies => (
								<option key={currencies.currencyId} value={currencies.currencyId}>
									{currencies.name}
								</option>
							))}
						</select>
					</div>
					<div className='wrap2'>
						<div className='f1_2'>Ticker: </div>
						<input
							type='text'
							className='form-input3'
							id='ticker'
							name='ticker'
							value={ticker}
							placeholder={
								currencyId && currencies.find(currency => currency.currencyId === currencyId * 1).ticker
							}
							onChange={onChange}
						/>
					</div>
					<div className='wrap2'>
						<div className='f1_2'>Name: </div>
						<input
							type='text'
							className='form-input3'
							id='name'
							name='name'
							value={name}
							placeholder={
								currencyId && currencies.find(currency => currency.currencyId === currencyId * 1).name
							}
							onChange={onChange}
						/>
					</div>
					<div className='wrap2'>
						<div className='f1_2'>Precision: </div>
						<input
							type='number'
							className='form-input3'
							id='precision'
							name='precision'
							value={precision}
							placeholder={
								currencyId && currencies.find(currency => currency.currencyId === currencyId * 1).precision
							}
							onChange={onChange}
						/>
					</div>
					<div className='wrap3'>
						<div className='f1_2'>Deposit:</div>
						<select className='form-input2' id='canDeposit' name='canDeposit' onChange={onChange}>
							{currencyId &&
							currencies.find(currency => currency.currencyId === currencyId * 1).canDeposit === true ? (
								<>
									<option value={true}>Yes</option>
									<option value={false}>No</option>
								</>
							) : (
								<>
									<option value={false}>No</option>
									<option value={true}>Yes</option>
								</>
							)}
						</select>
						<div className='f1_2'>Tax %: </div>
						<input
							type='number'
							className='form-input3 fi3-small'
							id='depositTax'
							name='depositTax'
							value={depositTax}
							placeholder={
								currencyId &&
								currencies.find(currency => currency.currencyId === currencyId * 1).depositTax * 100
							}
							onChange={onChange}
						/>
					</div>
					<div className='wrap3'>
						<div className='f1_2'>Withdraw: </div>
						<select className='form-input2' id='canWithdraw' name='canWithdraw' onChange={onChange}>
							{currencyId &&
							currencies.find(currency => currency.currencyId === currencyId * 1).canWithdraw === true ? (
								<>
									<option value={true}>Yes</option>
									<option value={false}>No</option>
								</>
							) : (
								<>
									<option value={false}>No</option>
									<option value={true}>Yes</option>
								</>
							)}
						</select>
						<div className='f1_2'>Tax %:</div>
						<input
							type='number'
							className='form-input3 fi3-small'
							id='withdrawTax'
							name='withdrawTax'
							value={withdrawTax}
							placeholder={
								currencyId &&
								currencies.find(currency => currency.currencyId === currencyId * 1).withdrawTax * 100
							}
							onChange={onChange}
						/>
					</div>
					<div className='wrap3'>
						<div className='f1_2'>Transfer: </div>
						<select className='form-input2' id='canTransfer' name='canTransfer' onChange={onChange}>
							{currencyId &&
							currencies.find(currency => currency.currencyId === currencyId * 1).canTransfer === true ? (
								<>
									<option value={true}>Yes</option>
									<option value={false}>No</option>
								</>
							) : (
								<>
									<option value={false}>No</option>
									<option value={true}>Yes</option>
								</>
							)}
						</select>
						<div className='f1_2'>Tax %: </div>
						<input
							type='number'
							className='form-input3 fi3-small'
							id='transferTax'
							name='transferTax'
							value={transferTax}
							placeholder={
								currencyId &&
								currencies.find(currency => currency.currencyId === currencyId * 1).transferTax * 100
							}
							onChange={onChange}
						/>
					</div>
					<div className='wrap3'>
						<div className='f1_2'>Frozen: </div>
						<select className='form-input2' id='isFrozen' name='isFrozen' onChange={onChange}>
							{currencyId &&
							currencies.find(currency => currency.currencyId === currencyId * 1).isFrozen === true ? (
								<>
									<option value={true}>Yes</option>
									<option value={false}>No</option>
								</>
							) : (
								<>
									<option value={false}>No</option>
									<option value={true}>Yes</option>
								</>
							)}
						</select>
					</div>
					<div className='wrap2'>
						<div className='f1_2'>Frozen Note: </div>
						<input
							type='text'
							className='form-input3'
							id='frozenNote'
							name='frozenNote'
							value={frozenNote}
							placeholder={
								currencyId && currencies.find(currency => currency.currencyId === currencyId * 1).frozenNote
							}
							onChange={onChange}
						/>
					</div>
					<div className='wrap2'>
						<div className='f1_2'>Tax Collector: </div>
						<input
							type='text'
							className='form-input3'
							id='taxCollector'
							name='taxCollector'
							value={taxCollector}
							placeholder={
								currencyId && currencies.find(currency => currency.currencyId === currencyId * 1).taxCollector
							}
							onChange={onChange}
						/>
					</div>
					<div className='wrap2'>
						<div className='f1_2'>Image URL: </div>
						<input
							type='text'
							className='form-input3'
							id='imgUrl'
							name='imgUrl'
							value={imgUrl}
							placeholder={
								currencyId && currencies.find(currency => currency.currencyId === currencyId * 1).imgUrl
							}
							onChange={onChange}
						/>
					</div>
					<div className='tabs2'>
						<button type='submit' className='signin'>
							Modify
						</button>
					</div>
				</form>
			</div>
		</>
	)
}

export default ModifyCurrency
