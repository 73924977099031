import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createTradingPair, resetCex } from '../../../features/cex/cexSlice'
import Spinner from '../../Spinner'

function CreateTradingPair() {
	const [formData, setFormData] = useState({
		buyCurrency: '',
		sellCurrency: '',
		canBuy: '',
		canSell: '',
		frozen: '',
		frozenNote: '',
		fee: '',
		feeCollector: '',
	})

	const { buyCurrency, sellCurrency, canBuy, canSell, fee, feeCollector, frozen, frozenNote } = formData
	const { isLoadingCex } = useSelector(state => state.cex)
	const { currencies } = useSelector(state => state.bank)

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const tradingPairData = {
			name: `${currencies.find(currency => currency.currencyId === buyCurrency * 1).name} -> 
      ${currencies.find(currency => currency.currencyId === sellCurrency * 1).name}`,
			buyCurrency: buyCurrency * 1,
			sellCurrency: sellCurrency * 1,
			canBuy: canBuy === 'on' ? true : false,
			canSell: canSell === 'on' ? true : false,
			frozen: frozen === 'on' ? true : false,
			frozenNote,
			fee: fee * 0.01,
			feeCollector,
		}

		dispatch(createTradingPair(tradingPairData))
		dispatch(resetCex())
	}

	if (isLoadingCex) {
		return <Spinner />
	}

	return (
		<div>
			<form onSubmit={onSubmit}>
				<div className='wrap2'>
					<div className='f1_2'>Name: </div>
					<div className='f1_22'>
						{buyCurrency > 0 &&
							currencies.find(currency => currency.currencyId === buyCurrency * 1).name + ' -> '}
						{sellCurrency > 0 && currencies.find(currency => currency.currencyId === sellCurrency * 1).name}
					</div>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Buy Currency: </div>
					<select className='form-input3' name='buyCurrency' value={buyCurrency} onChange={onChange}>
						<option value=''>Select Currency</option>
						{currencies.map(
							currencyBuy =>
								currencyBuy.currencyId > 0 && (
									<option key={currencyBuy.currencyId} value={currencyBuy.currencyId}>
										{currencyBuy.name}
									</option>
								),
						)}
					</select>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Sell Currency: </div>
					<select className='form-input3' name='sellCurrency' value={sellCurrency} onChange={onChange}>
						<option value=''>Select Currency</option>
						{/* map the name of the currencies only if the currencyId is > 0 */}
						{currencies.map(
							currency =>
								currency.currencyId > 0 && (
									<option key={currency.currencyId} value={currency.currencyId}>
										{currency.name}
									</option>
								),
						)}
					</select>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Can Buy: </div>
					<input className='checkbox' type='checkbox' name='canBuy' id='canBuy' onChange={onChange} />
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Can Sell: </div>
					<input className='checkbox' type='checkbox' name='canSell' id='canSell' onChange={onChange} />
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Frozen: </div>
					<input type='checkbox' className='checkbox' id='isFrozen' name='isFrozen' onChange={onChange} />
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Frozen Note: </div>
					<input
						type='text'
						className='form-input3'
						id='frozenNote'
						name='frozenNote'
						value={frozenNote}
						placeholder='note'
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Fee %: </div>
					<input className='form-input3' type='number' name='fee' value={fee} onChange={onChange} />
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Fee Collector: </div>
					<input
						className='form-input3'
						type='text'
						name='feeCollector'
						value={feeCollector}
						onChange={onChange}
					/>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin' value='Create'>
						{' '}
						Create
					</button>
				</div>
			</form>
		</div>
	)
}

export default CreateTradingPair
