import axios from 'axios'

const API_URL_CHECKOUT = '/api/checkout/'

// Get Packages
const getPackages = async token => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.get(API_URL_CHECKOUT + 'packages/fetch', config)

	return response.data
}

// Create Package
const createPackage = async (packageData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CHECKOUT + 'packages/create', packageData, config)

	return response.data
}

// Delete Package
const deletePackage = async (packageData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CHECKOUT + 'packages/delete', packageData, config)

	return response.data
}

// Publish Package
const publishPackage = async (packageData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CHECKOUT + 'packages/publish', packageData, config)

	return response.data
}

// Set Active state of Package
const setActivePackage = async (packageData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CHECKOUT + 'packages/setactive', packageData, config)

	return response.data
}

// Purchase Package
const purchasePackage = async (purchaseData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CHECKOUT + 'buyPackage', purchaseData, config)

	return response.data
}

// Get User Checkout Transactions
const userCheckoutTransactions = async (logData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CHECKOUT + 'getTransactions', logData, config)

	return response.data
}

// Get All Checkout Transactions
const allCheckoutTransactions = async (logData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CHECKOUT + 'getAllTransactions', logData, config)

	return response.data
}

// Clear Logs
const clearCheckoutLogs = () => {}

const checkoutService = {
	getPackages,
	createPackage,
	deletePackage,
	publishPackage,
	setActivePackage,
	purchasePackage,
	userCheckoutTransactions,
	allCheckoutTransactions,
	clearCheckoutLogs,
}

export default checkoutService
