import CreateTradingPair from './CreateTradingPair'
import Modal from '../../builders/modal'
import ViewTradingPairs from './ViewTradingPairs'

function CexControl() {
	return (
		<div className='centraliser'>
			<div className='centraliser'>
				<Modal
					header={`Create Trading Pair`}
					content={<CreateTradingPair />}
					btnTextorIcon={`Create Trading Pair`}
					btnclass='signin signinwide'
				/>
			</div>
			<div className='centraliser'>
				<ViewTradingPairs />
			</div>
		</div>
	)
}

export default CexControl
