const Joi = require('joi')
const fields = require('../fields')
const paginationSchema = require('../queries/pagination.validator');

const getItemsGroupedSchema = Joi.object({
    filters: Joi.object({
        itemCollection: fields.objectIdField.optional().label('Collection'),
    }).optional(),
    paging: paginationSchema.optional(),
})

module.exports = getItemsGroupedSchema