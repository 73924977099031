import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import auditService from './auditService';
import { getErrMsg } from '../../util/generic';
import { login, loginWallet } from '../auth/authSlice';

const initialState = {
	isErrorAudit: false,
	isSuccessAudit: false,
	isLoadingAudit: false,
	messageAudit: '',
	logs: null,
};

// Get User Access Logs
export const getAccessLogs = createAsyncThunk('audit/getAccessLogs', async (logData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await auditService.getAccessLogs(logData, token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Get Admin Action Logs
export const getAdminLogs = createAsyncThunk('audit/getAdminLogs', async (logData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await auditService.getAdminLogs(logData, token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Set Defcon Level
export const setDefconLevel = createAsyncThunk(
	'audit/setDefconLevel',
	async (defconData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			return await auditService.setDefconLevel(defconData, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error));
		}
	}
);

// Clear Logs
export const clearAuditLogs = createAsyncThunk('auth/clearLogs', async () => {
	auditService.clearAuditLogs();
});

export const auditSlice = createSlice({
	name: 'audit',
	initialState,
	reducers: {
		resetAudit: (state) => {
			state.isLoadingAudit = false;
			state.isSuccessAudit = false;
			state.isErrorAudit = false;
			state.messageAudit = '';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAccessLogs.pending, (state) => {
				state.isLoadingAudit = true;
			})
			.addCase(getAccessLogs.fulfilled, (state, action) => {
				state.isLoadingAudit = false;
				state.isSuccessAudit = true;
				state.logs = action.payload.logs;
				state.messageAudit = action.payload.message;
			})
			.addCase(getAccessLogs.rejected, (state, action) => {
				state.isLoadingAudit = false;
				state.isErrorAudit = true;
				state.messageAudit = action.payload;
			})
			.addCase(getAdminLogs.pending, (state) => {
				state.isLoadingAudit = true;
			})
			.addCase(getAdminLogs.fulfilled, (state, action) => {
				state.isLoadingAudit = false;
				state.isSuccessAudit = true;
				state.logs = action.payload.logs;
				state.messageAudit = action.payload.message;
			})
			.addCase(getAdminLogs.rejected, (state, action) => {
				state.isLoadingAudit = false;
				state.isErrorAudit = true;
				state.messageAudit = action.payload;
			})
			.addCase(setDefconLevel.pending, (state) => {
				state.isLoadingAudit = true;
			})
			.addCase(setDefconLevel.fulfilled, (state, action) => {
				state.isLoadingAudit = false;
				state.isSuccessAudit = true;
				state.defconLvl = action.meta.arg.defconLvl;
				state.defconMsg = action.meta.arg.defconMsg;
				state.messageAudit = action.payload.message;
			})
			.addCase(setDefconLevel.rejected, (state, action) => {
				state.isLoadingAudit = false;
				state.isErrorAudit = true;
				state.messageAudit = action.payload;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.defconLvl = action.payload.defconLvl;
				state.defconMsg = action.payload.defconMsg;
			})
			.addCase(loginWallet.fulfilled, (state, action) => {
				state.defconLvl = action.payload.defconLvl;
				state.defconMsg = action.payload.defconMsg;
			})
			.addCase(clearAuditLogs.pending, (state) => {
				state.logs = null;
			})
			.addCase(clearAuditLogs.fulfilled, (state, action) => {
				state.logs = null;
			})
			.addCase(clearAuditLogs.rejected, (state, action) => {
				state.logs = null;
			});
	},
});

export const { resetAudit } = auditSlice.actions;
export default auditSlice.reducer;
