const Joi = require('joi');

//Generic
const boolField = Joi.bool();
const noteField = Joi.string().max(255).allow('').label('Note');
const nameField = Joi.string().min(3).max(127).label('Name');
const descriptionField = Joi.string().min(12).max(511).label('Description');
const percentField = Joi.number().min(0).max(1);
const pageLimitField = Joi.number().integer().min(5).max(50).label('Records per Page');
const integerAboveZeroField = Joi.number().integer().min(1);
const urlField = Joi.string().uri();
const ipAddress = Joi.string().min(7).max(15);
const objectIdField = Joi.string().alphanum().length(24).label('ID');
const genericObjectField = Joi.object().pattern(/^/, [Joi.string(), Joi.number(), Joi.boolean()]);
const nestedObjectField = Joi.object().pattern(
	/^/,
	Joi.alternatives().try(Joi.string(), Joi.number(), Joi.boolean(), genericObjectField)
);
const paginationSchema = Joi.object({
	limit: Joi.number().integer().min(5).max(50).required().label('Records per Page'),
	page: integerAboveZeroField.required(),
});

//User fields
const userIdField = objectIdField.label('User ID');
const nicknameField = Joi.string().alphanum().min(3).max(32).label('Nickname');
const emailOptions = { tlds: { allow: false } };
const emailField = Joi.string().max(255).email(emailOptions).lowercase().label('Email Address');
const passwordField = Joi.string()
	.pattern(/^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,32}$/)
	.label('Password');
const loginPasswordField = passwordField.messages({ 'string.pattern.base': 'Incorrect password' });
const registerPasswordField = passwordField.messages({
	'string.pattern.base': 'Password not strong enough',
});
const roleField = Joi.string().min(1).max(10).label('Role');

//Wallet Fields
const walletAddressField = Joi.string().min(10).max(50).lowercase().label('Wallet Address');
const signatureField = Joi.string().label('Signature');

//Transaction fields
const currencyIdField = Joi.number().integer().label('Currency');
const currencyAmountField = Joi.number().min(0.01).label('Amount');
const currencyPrecisionField = Joi.number().integer().min(0).max(9).label('Precision');
const currencyTickerField = Joi.string().alphanum().min(2).max(7).label('Ticker');
const currencyNameField = Joi.string().min(3).max(31).label('Currency Name');
const canDepositField = boolField.label('Can Desposit');
const canWithdrawField = boolField.label('Can Withdraw');
const canTransferField = boolField.label('Can Transfer');
const canMintField = boolField.label('Can Mint');
const canBurnField = boolField.label('Can Burn');
const depositTaxField = percentField.label('Deposit Tax');
const withdrawTaxField = percentField.label('Withdraw Tax');
const transferTaxField = percentField.label('Transfer Tax');
const taxCollectorField = nicknameField.label('Tax Collector');
const isFrozenField = boolField.label('Is Frozen');
const frozenNoteField = noteField.label('Frozen Note');
const transactionReferenceField = Joi.string().max(255).label('Reference');
const transactionCategoryField = Joi.number().integer().min(1).label('Category');
const balanceSchema = Joi.object({
	currencyId: currencyIdField.required(),
	amount: currencyAmountField.required(),
});

//Other
const jwtToken = Joi.string().min(150).max(255);

module.exports = {
	boolField,
	noteField,
	nameField,
	descriptionField,
	percentField,
	pageLimitField,
	integerAboveZeroField,
	urlField,
	ipAddress,
	objectIdField,
	genericObjectField,
	nestedObjectField,
	paginationSchema,

	userIdField,
	nicknameField,
	emailField,
	passwordField,
	loginPasswordField,
	registerPasswordField,
	roleField,

	walletAddressField,
	signatureField,

	currencyIdField,
	currencyAmountField,
	currencyPrecisionField,
	currencyTickerField,
	currencyNameField,
	canDepositField,
	canMintField,
	canBurnField,
	canWithdrawField,
	canTransferField,
	isFrozenField,
	frozenNoteField,
	depositTaxField,
	withdrawTaxField,
	transferTaxField,
	taxCollectorField,
	transactionReferenceField,
	transactionCategoryField,
	balanceSchema,

	jwtToken,
};
