import { useSelector } from 'react-redux'

function BuyOrders(props) {
	const { orders } = useSelector(state => state.cex)
	const buys = orders && orders.buys

	return (
		<div className='transactions_grid'>
			<div className='h3'>Buy Orders</div>
			<div className='tg_header'>
				<div>Amount</div>
				<div>Price</div>
			</div>
			{/*filter buyOrders with state of 1*/}
			{buys && buys.length > 0 ? (
				buys
					.filter(order => order.state !== 0)
					.map(order => (
						<div className='tg_listing' key={order._id}>
							<div>{order.initialAmount - order.filledAmount}</div>
							<div>{order.price}</div>
						</div>
					))
			) : (
				<div className='tg_listing'>No orders Found</div>
			)}
		</div>
	)
}

export default BuyOrders
