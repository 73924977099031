import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { placeOrder, getOrders, getUserOrders } from '../../../features/cex/cexSlice'
import { Link } from 'react-router-dom'

function CreateOrder(props) {
	const [formData, setFormData] = useState({
		quantity: '',
		price: '',
		buySide: '',
	})

	const { quantity, price, buySide } = formData
	const { messageCex } = useSelector(state => state.cex)
	const { user } = useSelector(state => state.auth)
	const dispatch = useDispatch()
	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	const onSubmit = e => {
		e.preventDefault()

		const orderData = {
			pair: props._id,
			buySide: buySide,
			initialAmount: quantity,
			price: price,
		}

		dispatch(placeOrder(orderData))
	}

	useEffect(() => {
		console.log(messageCex);
		if (messageCex.includes('Placed a buy order of') || messageCex.includes('Placed a Sell Order of')) {
			const orderData = {
				pair: props._id,
			}

			dispatch(getOrders(orderData))
			dispatch(getUserOrders())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messageCex])

	return (
		<>
			{user && user ? (
				<div>
					<div className='h3'>Create Order</div>
					<form onSubmit={onSubmit} className='centraliser'>
						<div className='createorder_gridcontainer'>
							<div>Side:</div>
							<select className='form-input5' name='buySide' value={buySide} onChange={e => onChange(e)}>
								<option value=''>Select Side</option>
								<option value={true}>Buy</option>
								<option value={false}>Sell</option>
							</select>{' '}
							<div>Quantity:</div>
							<div>
								<input
									className='form-input3'
									type='number'
									placeholder='Quantity'
									name='quantity'
									value={quantity}
									onChange={e => onChange(e)}
								/>{' '}
							</div>
							<div>Price:</div>
							<div>
								<input
									type='number'
									className='form-input3'
									placeholder='Price'
									name='price'
									value={price}
									onChange={e => onChange(e)}
								/>{' '}
							</div>
						</div>
						<div className='tabs2'>
							<button
								type='submit'
								value='Create'
								className={`createbutton ${!buySide || !quantity || !price ? 'disabled' : ''}`}
								disabled={!buySide || !quantity || !price}
								title={!buySide || !quantity || !price ? 'Please fill in all the fields' : ''}
							>
								Create
							</button>
						</div>
					</form>
				</div>
			) : (
				<div className='tabs2'>
					<Link to='/login' className='signin'>
						Login to Place and Order
					</Link>
				</div>
			)}
		</>
	)
}

export default CreateOrder
