import { ImSpinner10 } from 'react-icons/im'

function Spinner() {
	return (
		<div className='loadingSpinnerContainer'>
			<div className='loadingSpinner'>
				<ImSpinner10 />
			</div>
		</div>
	)
}

export default Spinner
