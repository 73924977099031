import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BoSConsole from './BoSConsole'
import UserProfile from './UserProfile'
import UserLogs from './LogsConsole'
import { toast } from 'react-toastify'
import { resetForge, getForgeRecipes } from '../../features/forge/forgeSlice'
import Forge from './forge/Forge'
import { resetBank } from '../../features/bank/bankSlice'

function UserConsole() {
	const [activeTab, setActiveTab] = useState('UserProfile')
	const { isErrorBank, isSuccessBank, messageBank } = useSelector(state => state.bank)
	const { isErrorForge, isSuccessForge, messageForge } = useSelector(state => state.forge)

	const dispatch = useDispatch()

	useEffect(() => {
		if (isErrorBank) {
			toast.error(messageBank)
		}
		if (isSuccessBank) {
			toast.success(messageBank)
		}

		dispatch(resetBank())
	}, [isErrorBank, isSuccessBank, messageBank, dispatch])

	useEffect(() => {
		if (activeTab === 'Forge') {
			dispatch(getForgeRecipes())
		}
	}, [activeTab, dispatch])

	useEffect(() => {
		if (activeTab === 'Forge') {
			dispatch(getForgeRecipes())
		}
	}, [activeTab, dispatch])

	useEffect(() => {
		if (isErrorForge) {
			toast.error(messageForge)
		}
		if (isSuccessForge) {
			toast.success(messageForge)
		}

		dispatch(resetForge())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorForge, isSuccessForge])

	return (
		<>
			<div className='profilebox'>
				<div className='admin_tabs'>
					<div className='tabs'>
						<nav>
							<button className='tab' onClick={() => setActiveTab('UserProfile')}>
								<p
									/* if the tab is active set the class of the button to hover */
									className={activeTab === 'UserProfile' ? 'activetab' : 'glow'}
								>
									Profile
								</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('BoSConsole')}>
								<p className={activeTab === 'BoSConsole' ? 'activetab' : 'glow'}>Bank of Starlight</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('UserLogs')}>
								<p className={activeTab === 'UserLogs' ? 'activetab' : 'glow'}>User Logs</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('Forge')}>
								<p className={activeTab === 'Forge' ? 'activetab' : 'glow'}>Forge</p>
							</button>
						</nav>
					</div>
				</div>
				<div className='admin_content'>
					{activeTab === 'UserProfile' && <UserProfile />}
					{activeTab === 'BoSConsole' && <BoSConsole />}
					{activeTab === 'UserLogs' && <UserLogs />}
					{activeTab === 'Forge' && <Forge />}
				</div>
			</div>
		</>
	)
}

export default UserConsole
