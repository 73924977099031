const Joi = require('joi')
const { nicknameField, currencyIdField, currencyAmountField, transactionReferenceField } = require('./fields')

const mintSchema = Joi.object({
	recipientNickName: nicknameField,
	currencyId: currencyIdField,
	amount: currencyAmountField,
	reference: transactionReferenceField,
	note: Joi.string().min(4).max(255).label('Note').required(),
})

module.exports = mintSchema
