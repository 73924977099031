import { useSelector } from 'react-redux'

import States from '../../../util/orderStates.json'
import TradingPairName from '../../builders/TradingPairName'
import CancelOrder from './CancelOrder'

function ViewMyOrders() {
	const { userOrders } = useSelector(state => state.cex)
	const { user } = useSelector(state => state.auth)

	function formatAmount(amount) {
		const absAmount = Math.abs(amount)

		if (absAmount >= 1e15) {
			// Quadrillions
			return (
				(amount / 1e15).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) +
				' Q'
			)
		} else if (absAmount >= 1e12) {
			// Trillions
			return (
				(amount / 1e12).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) +
				' T'
			)
		} else if (absAmount >= 1e9) {
			// Billions
			return (
				(amount / 1e9).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) +
				' B'
			)
		} else if (absAmount >= 1e6) {
			// Millions
			return (
				(amount / 1e6).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 }) +
				' M'
			)
		} else {
			// Regular formatting for smaller numbers
			return amount
		}
	}

	// sort userOrders by state and date created starting with most recent
	const sortedUserOrders =
		userOrders &&
		[...userOrders].sort((a, b) => a.state - b.state || new Date(a.createdAt) - new Date(b.createdAt))

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}

	return (
		<div className='transactions_grid'>
			{user && user ? (
				<>
					<div className='header cex_myordersgrid'>
						<div>Created At</div>
						<div>Trading Pair</div>
						<div>Side</div>
						<div>Initial</div>
						<div>Filled</div>
						<div>Price</div>
						<div>State</div>
					</div>
					{/* if orders is not and empty array display map if empty display 'No orders Found' display orders starting with most recent*/}
					{sortedUserOrders && sortedUserOrders.length > 0 ? (
						sortedUserOrders.map(order => (
							<div className='cex_myordersgrid' key={order._id}>
								<div>{new Date(order.createdAt).toLocaleDateString('en-GB', date)}</div>
								<div>
									<TradingPairName pair={order.pair} />
								</div>
								<div>{order.buySide ? 'Buying' : 'Selling'}</div>
								{/* if the number is very large then display in millions, billions, trillions, etc. */}
								<div>{formatAmount(order.initialAmount)}</div>

								<div>{formatAmount(order.filledAmount)}</div>
								<div>{formatAmount(order.price)}</div>
								<div>
									{order.state ? States.type.find(type => type.id === order.state).name : ''}
									{order.state ? (
										States.type.find(type => type.id === order.state).name === 'Placed' ? (
											<CancelOrder orderId={order._id} />
										) : (
											''
										)
									) : (
										''
									)}
								</div>
							</div>
						))
					) : (
						<div className='tg_listing'>No orders Found</div>
					)}
				</>
			) : (
				<div className='tg_listing'>Please login to view your orders</div>
			)}
		</div>
	)
}

export default ViewMyOrders
