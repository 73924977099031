import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetAuth, administrateUser } from '../../../features/auth/authSlice'

import Spinner from '../../Spinner'

function AdministrateUser() {
	const [formData, setFormData] = useState({
		role: '',
		nickname: '',
		active: '',
	})

	const { role, nickname, active } = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const { user, isLoadingAuth, isErrorAuth, isSuccessAuth, messageAuth } = useSelector(state => state.auth)

	useEffect(() => {
		dispatch(resetAuth())
	}, [user, isErrorAuth, isSuccessAuth, messageAuth, dispatch])

	const onSubmit = e => {
		e.preventDefault()

		const administrateData = {
			role: role,
			nickname: nickname,
			active: active,
		}

		Object.keys(administrateData).forEach(field => {
			if (administrateData[field] === '') {
				delete administrateData[field]
			}
		})

		dispatch(administrateUser(administrateData))
	}

	if (isLoadingAuth) {
		return <Spinner />
	}

	return (
		<>
			<div>
				<form onSubmit={onSubmit}>
					<div className='wrap'>
						<div className='f1'>Role to assign User: </div>
						<select
							type='text'
							className='form-input2'
							id='role'
							name='role'
							value={role}
							placeholder='Role to Assign'
							onChange={onChange}
						>
							<option value=''>Change Role</option>
							<option value='admin'>Admin</option>
							<option value='user'>User</option>
							<option value='creator'>Creator</option>
						</select>
					</div>
					<div className='wrap'>
						<div className='f1'>Activate/ Deactivate User: </div>
						<select
							type='boolean'
							className='form-input2'
							id='active'
							name='active'
							value={active}
							placeholder='Activate/Deactivate User'
							onChange={onChange}
						>
							<option value=''>Change Active</option>
							<option value='true'>True</option>
							<option value='false'>False</option>
						</select>
					</div>
					<div className='wrap'>
						<div className='f1'>User Id: </div>
						<input
							type='text'
							className='form-input2'
							id='nickname'
							name='nickname'
							value={nickname}
							placeholder='Nickname'
							onChange={onChange}
						/>
					</div>
					<div className='tabs2'>
						<button type='submit' className='signin'>
							Submit
						</button>
					</div>
				</form>
			</div>
		</>
	)
}

export default AdministrateUser
