import Modal from '../builders/modal'
import CreateItemCollection from './CreateItemCollection'

function CollectionControl() {
	return (
		<div className='centraliser'>
			<div className='centraliser'>
				<Modal
					header={`Create Recipe`}
					content={<CreateItemCollection />}
					btnTextorIcon={`Create Collection`}
					btnclass='signin signinwide'
				/>
			</div>
		</div>
	)
}

export default CollectionControl
