import React, { useState } from 'react'

import Profile from './UserProfile'
import UserCurrency from './bank/UserCurrency'

const UserBank = () => {
	const [activeTab, setActiveTab] = useState('Profile')

	return (
		<div className='profilebox'>
			<div className='tabs settingsgrid'>
				<button className='tab' onClick={() => setActiveTab('Profile')}>
					<p className='glow'>Profile</p>
				</button>
				{'     '}
				<button className='tab' onClick={() => setActiveTab('UserCurrency')}>
					<p className='glow'>Bank of Starlight</p>
				</button>
			</div>
			<div>
				{activeTab === 'Profile' && <Profile />}
				{activeTab === 'UserCurrency' && <UserCurrency />}
			</div>
		</div>
	)
}

export default UserBank
