import { useEffect, useState } from 'react'

import UserLogs from './bank/TransactionSortUser'
import CheckoutLogs from './checkout/CheckoutSortUser'
import ForgeLogs from './forge/ProcessSortUser'
import { useDispatch, useSelector } from 'react-redux'
import { clearForgeProcessLogs, getForgeProcesses, getForgeRecipes } from '../../features/forge/forgeSlice'
import { clearBankLogs, resetBank, userTransactions } from '../../features/bank/bankSlice'
import {
	clearCheckoutLogs,
	resetCheckout,
	userCheckoutTransactions,
} from '../../features/checkout/checkoutSlice'
import { toast } from 'react-toastify'

function UserConsole() {
	const [activeTab, setActiveTab] = useState('UserLogs')
	const { isErrorForge } = useSelector(state => state.forge)
	const { isErrorBank } = useSelector(state => state.bank)
	const { isErrorCheckout, isSuccessCheckout, messageCheckout } = useSelector(state => state.checkout)

	const [pageNum, setPageNum] = useState(1)
	const dispatch = useDispatch()

	useEffect(() => {
		const initData = {
			paging: {
				limit: 10,
				page: 1,
			},
	}

		if (activeTab === 'ForgeLogs') {
			const init_data = {
				paging: {
					limit: 10,
					page: 1,
				},
			}
			dispatch(getForgeRecipes())
			dispatch(getForgeProcesses(init_data))
			return () => {
				dispatch(clearForgeProcessLogs())
				setPageNum(1)
			}
		}

		if (activeTab === 'Forge') {
			dispatch(getForgeRecipes())
		}

		if (activeTab === 'UserLogs') {
			dispatch(userTransactions(initData))
			return () => {
				dispatch(resetBank())
				dispatch(clearBankLogs())
				setPageNum(1)
			}
		}

		if (activeTab === 'CheckoutLogs') {
			const init_data = {
				paging: {
					limit: 10,
					page: 1,
				},
			}
			dispatch(userCheckoutTransactions(init_data))
			return () => {
				dispatch(resetCheckout())
				dispatch(clearCheckoutLogs())
				setPageNum(1)
			}
		}
	}, [activeTab, dispatch])

	useEffect(() => {
		if (isErrorBank) {
			setPageNum(pageNum - 1)
		}

		if (!pageNum || pageNum === 0) {
			setPageNum(1)
		}
	}, [isErrorBank, pageNum])

	useEffect(() => {
		if (isErrorCheckout) {
			toast.error(messageCheckout)
		}
		if (isSuccessCheckout) {
			toast.success(messageCheckout)
		}

		dispatch(resetCheckout())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorCheckout, isSuccessCheckout])

	useEffect(() => {
		if (isErrorCheckout) {
			setPageNum(pageNum - 1)
		}

		if (!pageNum || pageNum === 0) {
			setPageNum(1)
		}
	}, [isErrorCheckout, pageNum])

	useEffect(() => {
		if (isErrorForge) {
			setPageNum(pageNum - 1)
		}

		if (!pageNum || pageNum === 0) {
			setPageNum(1)
		}
	}, [isErrorForge, pageNum])

	return (
		<div className='profilebox'>
			<div className='tabs settingsgrid'>
				<button
					className={activeTab === 'UserLogs' ? 'signin signinwide activetab' : 'signin signinwide'}
					onClick={() => setActiveTab('UserLogs')}
				>
					User Logs
				</button>
				{'     '}
				<button
					className={activeTab === 'CheckoutLogs' ? 'signin signinwide activetab' : 'signin signinwide'}
					onClick={() => setActiveTab('CheckoutLogs')}
				>
					Checkout Logs
				</button>
				{'     '}
				<button
					className={activeTab === 'ForgeLogs' ? 'signin signinwide activetab' : 'signin signinwide'}
					onClick={() => setActiveTab('ForgeLogs')}
				>
					Forge Logs
				</button>
			</div>
			<div>
				{activeTab === 'UserLogs' && (
					<UserLogs pageNum={pageNum} changePageNum={pageNum => setPageNum(pageNum)} />
				)}
				{activeTab === 'CheckoutLogs' && (
					<CheckoutLogs pageNum={pageNum} changePageNum={pageNum => setPageNum(pageNum)} />
				)}
				{activeTab === 'ForgeLogs' && (
					<ForgeLogs pageNum={pageNum} changePageNum={pageNum => setPageNum(pageNum)} />
				)}
			</div>
		</div>
	)
}

export default UserConsole
