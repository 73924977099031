import { useDispatch } from 'react-redux'
import { getUser } from '../../../features/auth/authSlice'
import CurrencyArrayMobile from '../../builders/currencyarraymobile/CurrencyArrayMobile'
import SelectUserItemsMobile from '../inventory/SelectUserItemsMobile'

function UserCurrencyMobile(props) {
	const dispatch = useDispatch()

	const onRefresh = () => {
		dispatch(getUser())
	}
	return (
		<div className='centraliser'>
			<div className='subheaderwrap'>
				<h4 className='h4_2'>My Tokens</h4>
				<div onClick={onRefresh} className='refreshbutton'>
					<button className='signin signinmini'>↻</button>
				</div>
			</div>
			<div>
				<CurrencyArrayMobile
					logId={props.logId}
					setLogId={props.setLogId}
					activeTab={props.activeTab}
					setActiveTab={props.setActiveTab}
				/>
			</div>
			<h4 className='h4_2'>My Assets</h4>
			<div>Click on game to see assets</div>
			<div>
				<SelectUserItemsMobile
					activeTab={props.activeTab}
					setActiveTab={props.setActiveTab}
					setCollectionName={props.setCollectionName}
					collectionName={props.collectionName}
				/>
			</div>
		</div>
	)
}

export default UserCurrencyMobile
