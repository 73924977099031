import { useSelector } from 'react-redux'
import States from '../../../util/forgeProcessStates.json'

function ProcessListAdmin() {
	const { logs } = useSelector(state => state.forge)
	const { currencies } = useSelector(state => state.bank)

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}
	const time = {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timezone: 'UTC',
	}

	return (
		<div className='transactions_grid'>
			<div className='h4_2'>Forge Process List</div>
			<div className='tg_header'>
				<div>Start Date (UTC) :</div>
				<div>Finish Date (UTC) :</div>
				<div>User :</div>
				<div>Process Name :</div>
				<div>Input :</div>
				<div>Output :</div>
				<div>State :</div>
			</div>
			{logs && logs.length > 0 ? (
				logs.map((logs, createdAt) => (
					<div className='tg_listing' key={createdAt}>
						<div>
							{new Date(logs.createdAt).toLocaleDateString('en-GB', date)}
							&nbsp;
							{new Date(logs.createdAt).toLocaleTimeString('en-GB', time)}
						</div>
						<div>
							{new Date(logs.finishDate).toLocaleDateString('en-GB', date)}
							&nbsp;
							{new Date(logs.finishDate).toLocaleTimeString('en-GB', time)}
						</div>
						<div>{logs.user.nickname}</div>
						<div>{logs.recipe.name}</div>
						<div>
							{currencies.find(currency => currency.currencyId === logs.input.currencyId).ticker}
							{' x '}
							{logs.input.amount * logs.quantity}
						</div>
						<div>
							{currencies.find(currency => currency.currencyId === logs.output.currencyId).ticker}
							{' x '}
							{logs.output.amount * logs.quantity}
						</div>
						<div>{logs.state ? States.type.find(type => type.id === logs.state).name : ''}</div>
					</div>
				))
			) : (
				<div className='tg_listing'>
					<div>No logs found</div>
				</div>
			)}
		</div>
	)
}

export default ProcessListAdmin
