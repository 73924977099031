import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLeaderBoard } from '../features/auth/authSlice'

function Leaderboard() {
	const { leaderslist, user } = useSelector(state => state.auth)
	const dispatch = useDispatch()
	const [currencyId, setCurrencyId] = useState(1)

	function Amount(currencyId) {
		return user && user.balances.find(x => x.currencyId === currencyId)
			? user.balances.find(x => x.currencyId === currencyId).amount
			: 0
	}

	function Ticker(currencyId) {
		if (currencyId === 1) {
			return 'SD'
		}
		if (currencyId === 2) {
			return 'SN'
		}
		if (currencyId === 3) {
			return 'Credits'
		}
	}

	useEffect(() => {
		const initData = {
			currencyId: 1,
		}

		dispatch(getLeaderBoard(initData))
	}, [dispatch])

	const onSubmit = e => {
		e.preventDefault()
		const data = {
			currencyId: currencyId,
		}
		dispatch(getLeaderBoard(data))
	}

	return (
		<div>
			<div className='leaderback' />
			<header>Leaderboards</header>
			<div className='leaderboard'>
				<form onSubmit={onSubmit}>
					<div className='tabs'>
						<button className='subtab' type='submit' onClick={() => setCurrencyId(1)}>
							<p className='glow'>SD</p>
						</button>
						<button className='subtab' type='submit' onClick={() => setCurrencyId(2)}>
							<p className='glow'>SN</p>
						</button>
						<button className='subtab' type='submit' onClick={() => setCurrencyId(3)}>
							<p className='glow'>Credits</p>
						</button>
					</div>
					<div className='h4_2 centraliser'>{Ticker(currencyId)} LeaderBoards</div>
					<div className=''>
						<div className='lb_header'>
							<div>Rank :</div>
							<div>Nickname :</div>
							<div>Amount :</div>
						</div>
					</div>
				</form>

				{leaderslist && leaderslist.leaders.length > 0 ? (
					leaderslist.leaders.map((leader, index) => {
						return (
							<div className='' key={index}>
								<div className='lb_listing board'>
									<div>{index + 1}</div>
									<div>{leader.nickname}</div>
									<div>{leader.amount}</div>
								</div>
							</div>
						)
					})
				) : (
					<div>No Data</div>
				)}
				<div className='lb_personalrank'>
					<div className='lb_header'>
						<div>Your Rank :</div>
						<div></div>
						<div>Amount :</div>
					</div>
				</div>
				{leaderslist ? (
					<div className='lb_listing board'>
						<div>{leaderslist && leaderslist.position}</div>
						<div>{''}</div>
						<div>{Amount(currencyId)}</div>
					</div>
				) : (
					<div>Log in to see where you rank!</div>
				)}
			</div>
		</div>
	)
}

export default Leaderboard
