const CitizenStatus = () => {
	return (
		<>
			<div className='notice2'>
				Citizen status is a ranking based on StarNation Tokens (SN) owned. In combination with Fame it will
				unlock certain powers or perks.
			</div>
			<div className='cs_grid'>
				<div className='cs_header'>Citizen Rank</div>
				<div className='cs_header'>SN Required</div>
				<div className='cs_rank'>Citizen</div>
				<div className='cs_sn'>0</div>
				<div className='cs_rank'>Ethereal</div>
				<div className='cs_sn'>1000</div>
				<div className='cs_rank'>Extraterrestrial</div>
				<div className='cs_sn'>10000</div>
				<div className='cs_rank'>Stellar</div>
				<div className='cs_sn'>50000</div>
				<div className='cs_rank'>Celestial</div>
				<div className='cs_sn'>100000</div>
			</div>
		</>
	)
}

export default CitizenStatus
