const Joi = require('joi')
const fields = require('../fields')

const placeCexOrderSchema = Joi.object({
    pair: fields.objectIdField.required().label('Pair'),
    buySide: fields.boolField.required(),
    initialAmount: fields.currencyAmountField.required().label('Initial Amount'),
    price: fields.currencyAmountField.required().label('Price'),
});

module.exports = placeCexOrderSchema;