import { useSelector } from 'react-redux'
import Categories from '../../util/adminLogTypes'

function UserAccessLogs() {
	const { logs } = useSelector(state => state.audit)

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}
	const time = {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timezone: 'UTC',
	}

	return (
		<>
			<div className='transactions_grid'>
				<div className='h4_2'>Admin Action Log</div>
				<div className='tg_admin_header'>
					<div>Date (UTC) :</div>
					<div>Admin :</div>
					<div>Affected User :</div>
					<div>Address :</div>
					<div>Category :</div>
				</div>

				{logs && logs.length > 0 ? (
					logs.map(logs => (
						<div className='tg_admin_listing' key={logs.date}>
							<div>
								{new Date(logs.date).toLocaleDateString('en-GB', date)}
								&nbsp;
								{new Date(logs.date).toLocaleTimeString('en-GB', time)}
							</div>
							<div>{logs.adminUser}</div>
							<div>{logs.affectedUser}</div>
							<div>{logs.address}</div>
							<div>
								{logs.category ? Categories.type.find(category => category.id === logs.category).name : ''}
							</div>
							<div className='tg_admin_note'>Note : {logs.action}</div>
						</div>
					))
				) : (
					<div>No Admin Action Logs</div>
				)}
			</div>
		</>
	)
}

export default UserAccessLogs
