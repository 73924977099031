function VInput(props) {
	function validate(validationSchema, value) {
		//Bail if there's no validation schema to use
		if (!validationSchema) {
			console.log('No validation schema provided')
			return
		}

		//Bail if blank
		if (!value) return

		const validationResult = validationSchema.validate(value)
		if (validationResult.error) return validationResult.error.message
		else return
	}

	const errorMsg = validate(props.validationSchema, props.value)

	return (
		<>
			<input
				type={props.type}
				className={props.className}
				id={props.id}
				name={props.name}
				value={props.value}
				placeholder={props.placeholder}
				onChange={props.onChange}
			/>
			<div className='pw_text'>{errorMsg ? <>{errorMsg}</> : <> {' ⁤ '} </>}</div>
		</>
	)
}

export default VInput
