import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import bankService from './bankService'
import { getErrMsg, tryParseJson } from '../../util/generic'

const currencies = tryParseJson(localStorage.getItem('currencies'))

const initialState = {
	isErrorBank: false,
	isSuccessBank: false,
	isLoadingBank: false,
	messageBank: '',
	logs: null,
	currencies: currencies ? currencies : null,
}

// Get Currencies
export const getCurrencies = createAsyncThunk('bank/currencies', async (_, thunkAPI) => {
	try {
		return await bankService.getCurrencies()
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Create Currency
export const createCurrency = createAsyncThunk('bank/createCurrency', async (currencyData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await bankService.createCurrency(currencyData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Modify Currency
export const modifyCurrency = createAsyncThunk('bank/modifyCurrency', async (currencyData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await bankService.modifyCurrency(currencyData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// P2P transfer
export const initTransfer = createAsyncThunk('bank/p2pTransfer', async (transactionData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await bankService.initTransfer(transactionData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Mint Currency
export const initMint = createAsyncThunk('bank/mint', async (transactionData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await bankService.initMint(transactionData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Burn Currency
export const initBurn = createAsyncThunk('bank/burn', async (transactionData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await bankService.initBurn(transactionData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Withdraw from BoS to wallet
export const withdraw = createAsyncThunk('bank/withdraw', async (transactionData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await bankService.initTransfer(transactionData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Deposit to BoS from wallet
export const deposit = createAsyncThunk('bank/deposit', async (transactionData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await bankService.initTransfer(transactionData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Get Transactions
export const allTransactions = createAsyncThunk('bank/alltransactions', async (logData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await bankService.getTransactions(logData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Get User Transactions
export const userTransactions = createAsyncThunk('bank/transactions', async (logData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await bankService.getUserTransactions(logData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Clear Transactions
export const clearBankLogs = createAsyncThunk('bank/clearLogs', async () => {
	bankService.clearBankLogs()
})

// Get Currency Stats
export const getCurrencyStats = createAsyncThunk('bank/getCurrencyStats', async (logData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await bankService.getCurrencyStats(logData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Distribute Currency
export const distributeCurrency = createAsyncThunk(
	'bank/distributeCurrency',
	async (currencyData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token
			return await bankService.distributeCurrency(currencyData, token)
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error))
		}
	},
)

export const bankSlice = createSlice({
	name: 'bank',
	initialState,
	reducers: {
		resetBank: state => {
			state.isLoadingBank = false
			state.isSuccessBank = false
			state.isErrorBank = false
			state.messageBank = ''
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getCurrencies.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(getCurrencies.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.currencies = action.payload.currencies
				state.messageBank = action.payload.message
			})
			.addCase(getCurrencies.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(createCurrency.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(createCurrency.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isSuccessBank = true
				state.messageBank = action.payload.message
			})
			.addCase(createCurrency.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(modifyCurrency.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(modifyCurrency.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isSuccessBank = true
				state.messageBank = action.payload.message
			})
			.addCase(modifyCurrency.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(initTransfer.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(initTransfer.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(initTransfer.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(initMint.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(initMint.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isSuccessBank = true
				state.messageBank = action.payload.message
			})
			.addCase(initMint.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(initBurn.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(initBurn.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isSuccessBank = true
				state.messageBank = action.payload.message
			})
			.addCase(initBurn.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(withdraw.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(withdraw.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isSuccessBank = true
				state.messageBank = action.payload.message
			})
			.addCase(withdraw.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(deposit.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(deposit.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isSuccessBank = true
				state.messageBank = action.payload.message
			})
			.addCase(deposit.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(allTransactions.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(allTransactions.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isSuccessBank = true
				state.logs = action.payload.txs
				state.messageBank = action.payload.message
			})
			.addCase(allTransactions.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(userTransactions.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(userTransactions.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isSuccessBank = true
				state.logs = action.payload.txs
				state.messageBank = action.payload.message
			})
			.addCase(userTransactions.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(getCurrencyStats.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(getCurrencyStats.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isSuccessBank = true
				state.currencyStats = action.payload.currencyStats
				state.messageBank = action.payload.message
			})
			.addCase(getCurrencyStats.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
			.addCase(clearBankLogs.pending, state => {
				state.logs = null
			})
			.addCase(clearBankLogs.fulfilled, (state, action) => {
				state.logs = null
			})
			.addCase(clearBankLogs.rejected, (state, action) => {
				state.logs = null
			})
			.addCase(distributeCurrency.pending, state => {
				state.isLoadingBank = true
			})
			.addCase(distributeCurrency.fulfilled, (state, action) => {
				state.isLoadingBank = false
				state.isSuccessBank = true
				state.messageBank = action.payload.message
			})
			.addCase(distributeCurrency.rejected, (state, action) => {
				state.isLoadingBank = false
				state.isErrorBank = true
				state.messageBank = action.payload
			})
	},
})

export const { resetBank } = bankSlice.actions
export default bankSlice.reducer
