import { useSelector } from 'react-redux'
import Categories from '../../../util/transaction'

function TransactionListAdmin() {
	const { logs, currencies } = useSelector(state => state.bank)

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}
	const time = {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timezone: 'UTC',
	}

	return (
		<div className='transactions_grid'>
			<div className='h4_2'>Transaction Log List</div>
			<div className='tg_header'>
				<div>Date (UTC) :</div>
				<div>Executor :</div>
				<div>Sender :</div>
				<div>Recipient :</div>
				<div>Amount :</div>
				<div>Currency :</div>
				<div>Category :</div>
			</div>

			{logs && logs.length > 0 ? (
				logs.map((logs, index) => (
					<div className='tg_listing' key={index}>
						<div>
							{new Date(logs.createdAt).toLocaleDateString('en-GB', date)}
							&nbsp;
							{new Date(logs.createdAt).toLocaleTimeString('en-GB', time)}
						</div>
						<div>{logs.executor ? logs.executor.nickname : ''}</div>
						<div>{logs.sender ? logs.sender.nickname : ''}</div>
						<div>{logs.recipient ? logs.recipient.nickname : ''}</div>
						<div>{logs.balance.amount}</div>
						<div>
							{currencies.find(currency => currency.currencyId === logs.balance.currencyId).name}(
							{currencies.find(currency => currency.currencyId === logs.balance.currencyId).ticker})
						</div>
						<div>
							{logs.category ? Categories.type.find(category => category.id === logs.category).name : ''}
						</div>
						<div className='tg_note'>Note : {logs.note}</div>
					</div>
				))
			) : (
				<div>No Transactions</div>
			)}
		</div>
	)
}

export default TransactionListAdmin
