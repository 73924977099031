import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import cexService from './cexService';
import { getErrMsg, removeEmptyKeys } from '../../util/generic';

const initialState = {
	isErrorCex: false,
	isLoadingCex: false,
	isSuccessCex: false,
	messageCex: '',
	logs: null,
	tradingPairs: null,
	orders: null,
	userOrders: null,
};

// Create Trading Pair
export const createTradingPair = createAsyncThunk(
	'cex/createTradingPair',
	async (tradingPairData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			const cleanedData = removeEmptyKeys(tradingPairData);

			return await cexService.createTradingPair(cleanedData, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error));
		}
	}
);

// Modify Trading Pair
export const modifyTradingPair = createAsyncThunk(
	'cex/modifyTradingPair',
	async (tradingPairData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			const cleanedData = removeEmptyKeys(tradingPairData);

			return await cexService.modifyTradingPair(cleanedData, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error));
		}
	}
);

// Delete Trading Pair
export const deleteTradingPair = createAsyncThunk(
	'cex/deleteTradingPair',
	async (tradingPairData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			return await cexService.deleteTradingPair(tradingPairData, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error));
		}
	}
);

// Place Order
export const placeOrder = createAsyncThunk('cex/placeOrder', async (orderData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		const cleanedOrderData = removeEmptyKeys(orderData);

		return await cexService.placeOrder(cleanedOrderData, token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Cancel Order
export const cancelOrder = createAsyncThunk('cex/cancelOrder', async (orderData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await cexService.cancelOrder(orderData, token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Get Pairs
export const getPairs = createAsyncThunk('cex/getPairs', async (_, thunkAPI) => {
	try {
		return await cexService.getPairs();
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Get Orders
export const getOrders = createAsyncThunk('cex/getOrders', async (orderData, thunkAPI) => {
	try {
		return await cexService.getOrders(orderData);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Get User Orders
export const getUserOrders = createAsyncThunk('cex/getUserOrders', async (_, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await cexService.getUserOrders(token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Payout Order
export const payoutOrder = createAsyncThunk('cex/payoutOrder', async (orderData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await cexService.payoutOrder(orderData, token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Clear CEX Logs
export const clearCexLogs = createAsyncThunk('cex/clearLogs', async () => {
	cexService.clearCexLogs();
});

export const cexSlice = createSlice({
	name: 'cex',
	initialState,
	reducers: {
		resetCex: (state) => {
			state.isErrorCex = false;
			state.isLoadingCex = false;
			state.isSuccessCex = false;
			state.messageCex = '';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createTradingPair.pending, (state) => {
				state.isErrorCex = false;
				state.isLoadingCex = true;
				state.isSuccessCex = false;
			})
			.addCase(createTradingPair.fulfilled, (state, action) => {
				state.isErrorCex = false;
				state.isLoadingCex = false;
				state.isSuccessCex = true;
				state.messageCex = action.payload.message;
			})
			.addCase(createTradingPair.rejected, (state, action) => {
				state.isErrorCex = true;
				state.isLoadingCex = false;
				state.isSuccessCex = false;
				state.messageCex = action.payload;
			})
			.addCase(modifyTradingPair.pending, (state) => {
				state.isErrorCex = false;
				state.isLoadingCex = true;
				state.isSuccessCex = false;
			})
			.addCase(modifyTradingPair.fulfilled, (state, action) => {
				state.isErrorCex = false;
				state.isLoadingCex = false;
				state.isSuccessCex = true;
				state.messageCex = action.payload.message;
			})
			.addCase(modifyTradingPair.rejected, (state, action) => {
				state.isErrorCex = true;
				state.isLoadingCex = false;
				state.isSuccessCex = false;
				state.messageCex = action.payload;
			})
			.addCase(deleteTradingPair.pending, (state) => {
				state.isErrorCex = false;
				state.isLoadingCex = true;
				state.isSuccessCex = false;
			})
			.addCase(deleteTradingPair.fulfilled, (state, action) => {
				state.isErrorCex = false;
				state.isLoadingCex = false;
				state.isSuccessCex = true;
				state.messageCex = action.payload.message;
			})
			.addCase(deleteTradingPair.rejected, (state, action) => {
				state.isErrorCex = true;
				state.isLoadingCex = false;
				state.isSuccessCex = false;
				state.messageCex = action.payload;
			})
			.addCase(placeOrder.pending, (state) => {
				state.isErrorCex = false;
				state.isLoadingCex = true;
				state.isSuccessCex = false;
			})
			.addCase(placeOrder.fulfilled, (state, action) => {
				state.isErrorCex = false;
				state.isLoadingCex = false;
				state.isSuccessCex = true;
				state.messageCex = action.payload.tx.note;
			})
			.addCase(placeOrder.rejected, (state, action) => {
				state.isErrorCex = true;
				state.isLoadingCex = false;
				state.isSuccessCex = false;
				state.messageCex = action.payload;
			})
			.addCase(cancelOrder.pending, (state) => {
				state.isErrorCex = false;
				state.isLoadingCex = true;
				state.isSuccessCex = false;
			})
			.addCase(cancelOrder.fulfilled, (state, action) => {
				state.isErrorCex = false;
				state.isLoadingCex = false;
				state.isSuccessCex = true;
				state.messageCex = action.payload.message;
			})
			.addCase(cancelOrder.rejected, (state, action) => {
				state.isErrorCex = true;
				state.isLoadingCex = false;
				state.isSuccessCex = false;
				state.messageCex = action.payload;
			})
			.addCase(getPairs.pending, (state) => {
				state.isErrorCex = false;
				state.isLoadingCex = true;
				state.isSuccessCex = false;
			})
			.addCase(getPairs.fulfilled, (state, action) => {
				state.isErrorCex = false;
				state.isLoadingCex = false;
				state.isSuccessCex = true;
				state.messageCex = action.payload.message;
				state.tradingPairs = action.payload.pairs;
			})
			.addCase(getPairs.rejected, (state, action) => {
				state.isErrorCex = true;
				state.isLoadingCex = false;
				state.isSuccessCex = false;
				state.messageCex = action.payload;
			})
			.addCase(getOrders.pending, (state) => {
				state.isErrorCex = false;
				state.isLoadingCex = true;
				state.isSuccessCex = false;
			})
			.addCase(getOrders.fulfilled, (state, action) => {
				state.isErrorCex = false;
				state.isLoadingCex = false;
				state.isSuccessCex = true;
				state.messageCex = action.payload.message;
				state.orders = action.payload.orders;
			})
			.addCase(getOrders.rejected, (state, action) => {
				state.isErrorCex = true;
				state.isLoadingCex = false;
				state.isSuccessCex = false;
				state.messageCex = action.payload;
				// if messageCex is No orders found the remove orders from state
				if (action.payload === 'No orders found') {
					state.orders = [];
				}
			})
			.addCase(getUserOrders.pending, (state) => {
				state.isErrorCex = false;
				state.isLoadingCex = true;
				state.isSuccessCex = false;
			})
			.addCase(getUserOrders.fulfilled, (state, action) => {
				state.isErrorCex = false;
				state.isLoadingCex = false;
				state.isSuccessCex = true;
				state.messageCex = action.payload.message;
				state.userOrders = action.payload.orders;
			})
			.addCase(getUserOrders.rejected, (state, action) => {
				state.isErrorCex = true;
				state.isLoadingCex = false;
				state.isSuccessCex = false;
				state.messageCex = action.payload;
				// if messageCex is No orders found the remove orders from state
				if (action.payload === 'No orders found') {
					state.userOrders = [];
				}
			})
			.addCase(payoutOrder.pending, (state) => {
				state.isErrorCex = false;
				state.isLoadingCex = true;
				state.isSuccessCex = false;
			})
			.addCase(payoutOrder.fulfilled, (state, action) => {
				state.isErrorCex = false;
				state.isLoadingCex = false;
				state.isSuccessCex = true;
				state.messageCex = action.payload.message;
			})
			.addCase(payoutOrder.rejected, (state, action) => {
				state.isErrorCex = true;
				state.isLoadingCex = false;
				state.isSuccessCex = false;
				state.messageCex = action.payload;
			})
			.addCase(clearCexLogs.pending, (state) => {
				state.logs = null;
				state.tradingPairs = null;
				state.orders = null;
				state.userOrders = null;
			})
			.addCase(clearCexLogs.fulfilled, (state) => {
				state.logs = null;
				state.tradingPairs = null;
				state.orders = null;
				state.userOrders = null;
			})
			.addCase(clearCexLogs.rejected, (state) => {
				state.logs = null;
				state.tradingPairs = null;
				state.orders = null;
				state.userOrders = null;
			});
	},
});

export const { resetCex } = cexSlice.actions;

export default cexSlice.reducer;
