function Discord() {
	return (
		<div>
			<div className='centraliser2'>
				<div>
					<iframe
						title='discord'
						src='https://discord.com/widget?id=807258037102313522&theme=dark'
						width='350'
						height='500'
						allowtransparency='true'
						sandbox='allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts'
					></iframe>
				</div>
			</div>
		</div>
	)
}

export default Discord
