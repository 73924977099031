import { useDispatch, useSelector } from 'react-redux'
import {
	publishPackage,
	deletePackage,
	setActivePackage,
	resetCheckout,
	getPackages,
} from '../../../features/checkout/checkoutSlice'
import CreatePackage from './CreatePackage'
import Modal from '../../builders/modal'

function CheckoutControl() {
	const { packages } = useSelector(state => state.checkout)
	const sortedPackages = packages && [...packages].sort((a, b) => a.createdAt - b.createdAt)

	const dispatch = useDispatch()

	function DeletePackage(packageId) {
		const packageData = {
			objectId: packageId,
		}

		dispatch(deletePackage(packageData))
		dispatch(resetCheckout())
		dispatch(getPackages())
	}

	function PublishPackage(packageId) {
		const packageData = {
			objectId: packageId,
		}

		dispatch(publishPackage(packageData))
		dispatch(resetCheckout())
		dispatch(getPackages())
	}

	function SetActivePackage(packageId) {
		const packageData = {
			objectId: packageId,
			flag: packages.find(p => p._id === packageId).active ? false : true,
		}

		dispatch(setActivePackage(packageData))
		dispatch(resetCheckout())
		dispatch(getPackages())
	}

	return (
		<div className='centraliser'>
			<div className='centraliser'>
				<Modal
					header={`Create Package`}
					content={<CreatePackage />}
					btnTextorIcon={`Create Package`}
					btnclass='signin signinwide'
				/>
			</div>
			<div className='cu_card_wrap'>
				{/* sort the packages by _id */}
				{packages && packages.length > 0 ? (
					sortedPackages.map(packages => (
						<div className='cu_card' key={packages._id}>
							<div className='h2_2'>{packages.name}</div>
							<div className='cu_card_image'>
								<img src={packages.imgUrl} alt={packages.name} />
							</div>
							<div className='centraliser tb_margin'>
								<div className='cu_card_content'>{packages.description}</div>
								<div className='cu_card_content'>Cost: {packages.cost}</div>
								<div className='cu_card_content'>Credits: {packages.currency.amount}</div>
								<div className='cu_card_content'>Active: {packages.active ? 'Yes' : 'No'}</div>
								<div className='cu_card_content'>Published: {packages.published ? 'Yes' : 'No'}</div>
							</div>
							<div className='ccc_border'>
								<div className='centraliser'>
									<button className='signin tb_margin' onClick={() => DeletePackage(packages._id)}>
										Delete
									</button>
								</div>
								<div className='centraliser'>
									{/* display publish package button if package is not published else display 'Published' */}
									{!packages.published ? (
										<button className='signin b_margin' onClick={() => PublishPackage(packages._id)}>
											Publish
										</button>
									) : (
										<button className='signin b_margin' disabled>
											Published
										</button>
									)}
								</div>
								<div className='centraliser'>
									<button className='signin b_margin' onClick={() => SetActivePackage(packages._id)}>
										Set Active
									</button>
								</div>
							</div>
						</div>
					))
				) : (
					<div className='cu_card'>
						<div className='h2_2'>No Packages</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default CheckoutControl
