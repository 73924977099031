import { useEffect } from 'react'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { reset, sendVerificationEmail } from '../../features/auth/modalSlice'
import Spinner from '../Spinner'

function RequestVerifyEmail() {
	const { user, token } = useSelector(state => state.auth)
	const { isLoadingModal, isErrorModal, isSuccessModal, messageModal } = useSelector(state => state.modal)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		const nextVerifyEmailModal = localStorage.getItem('nextVerifyEmail')

		if (nextVerifyEmailModal > new Date()) {
			return
		}
		localStorage.removeItem('nextVerifyEmail')
		setIsOpen(true)
		var expires = new Date()
		expires = expires.setHours(expires.getHours() + 24)

		localStorage.setItem('nextVerifyEmail', expires)
	}, [])

	const onSendClick = e => {
		e.preventDefault()
		dispatch(sendVerificationEmail({ token, email: user.email }))
	}

	//Response handler for API call
	useEffect(() => {
		if (!isOpen) return

		let closeMe = false
		let errMsg
		let successMsg

		if (isErrorModal) {
			errMsg = messageModal
			closeMe = true
		}
		if (isSuccessModal) {
			successMsg = messageModal
			closeMe = true
		}

		dispatch(reset())
		if (errMsg) toast.error(errMsg)
		if (successMsg) toast.success(successMsg)
		if (closeMe) setIsOpen(false)
	}, [isOpen, isErrorModal, isSuccessModal, messageModal, dispatch, navigate])

	if (isLoadingModal) {
		return <Spinner />
	}

	const RenderModal = ({ setIsOpen }) => {
		return (
			<>
				{/* -----This is the modal container----- */}
				<div className='darkBG' onClick={() => setIsOpen(false)} />
				<div className='centered'>
					<div className='popup'>
						<h3 className='h3'>Email Verification</h3>
						<button className='closeBtn' onClick={() => setIsOpen(false)}>
							x
						</button>
						<p className='notice2'>
							Your email address registered with
							<br />
							this account needs to be verified.
						</p>
						<div className='form-group'>
							<button className='signin' onClick={onSendClick}>
								Send
							</button>
						</div>
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			{/* -----This is the button----- */}
			<button className='question question2' title='Verify email' onClick={() => setIsOpen(true)}>
				Verify Email !
			</button>
			{isOpen && RenderModal({ setIsOpen })}
		</>
	)
}

export default RequestVerifyEmail
