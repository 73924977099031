import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

import Modal from '../components/builders/modal'
import truncator from '../components/builders/truncator'

import UpdateNickname from '../components/userservices/UpdateNickname'
import UpdatePassword from '../components/userservices/UpdatePassword'
import UpdateEmail from '../components/userservices/UpdateEmail'
import Anonymous from '../components/userservices/Anonymous'

import { resetAuth } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import ApiKey from '../components/userservices/ApiKey'
// import UpdatewalletAddress from '../components/UpdateWallet'

function UserConfig() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { user, isLoadingAuth, isErrorAuth, isSuccessAuth, messageAuth } = useSelector(state => state.auth)

	useEffect(() => {
		if (isErrorAuth) {
			toast.error(messageAuth)
		}

		if (isSuccessAuth) {
			toast.success(messageAuth)
		}

		dispatch(resetAuth())
	}, [user, isErrorAuth, isSuccessAuth, messageAuth, navigate, dispatch])

	if (isLoadingAuth) {
		return <Spinner />
	}

	// function truncatedWallet() {
	// 	if (!user || !user.primaryWallet) return

	// 	return <>{truncator(user && user.primaryWallet)}</>
	// }

	function truncatedUser() {
		if (!user || !user.nickname) return

		return <>{truncator(user && user.nickname)}</>
	}

	function allowNicknameOrWalletChange() {
		if (user.isWalletOnly === true) {
			return (
				<>
					<div className='ps1'>Nickname :</div>
					<div className='p2 ps2'>{truncatedUser()}</div>
					<div className='ps3'>
						<Modal
							header='Update Nickname'
							content={<UpdateNickname />}
							btnTextorIcon={'Add Nickname'}
							btnclass='signin question3'
						/>
					</div>
				</>
			)
		} /*else {
			return (
				<>
					<div className='p1'>Add a wallet :</div>
					<div className='p2'> {truncatedWallet()}</div>
					<div className='p3'>
						<Modal
						header='Update Wallet'
						content={<UpdateWalletAddress />}
						btnTextorIcon={'Add Wallet'}
						btnclass='question'
					/>
					</div>
				</>
			)
		}*/
	}

	function allowPasswordChange() {
		if (!user.isWalletOnly) {
			return (
				<div className='pg-settings fg-left'>
					<div className='pg1'>Password :</div>
					<div className='pg2 ps2'>••••••••••••••</div>
					<div className='pg3'>
						<Modal
							header='Update Password'
							content={<UpdatePassword />}
							btnTextorIcon={'Change Password'}
							btnclass='signin signin3'
						/>
					</div>
				</div>
			)
		}
	}

	function allowEmailChange() {
		if (user.isWalletOnly === true) {
			return (
				<>
					<div className='ps1'>Email :</div>
					<div className='p2 ps2'>{user && user.email}</div>
					<div className='ps3'>
						<Modal
							header='Update Email'
							content={<UpdateEmail />}
							btnTextorIcon={'Change Email'}
							btnclass='signin question3'
						/>
					</div>
				</>
			)
		}
	}

	return (
		<div>
			<h1 className='h1'>User Settings</h1>
			<div className='logoutbox'>
				<Link to='../dashboard'>
					<button className='signin'>Back</button>
				</Link>
			</div>
			<div className='profilebox'>
				<h4 className='h4_2'>Profile settings</h4>
				<div className='profile'>
					{allowNicknameOrWalletChange()}
					{allowEmailChange()}
					{allowPasswordChange()}
				</div>
				<div className='profile'>
					<Anonymous />
				</div>
				<div className='profile'>
					<ApiKey />
				</div>
			</div>
		</div>
	)
}

export default UserConfig
