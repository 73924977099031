const Joi = require('joi');
const fields = require('./fields');

const getAccessLogsSchema = Joi.object({
	user: fields.nicknameField,
	loginType: Joi.number().integer().min(1).max(2),
	address: fields.ipAddress,

	createdAfter: Joi.date(),
	createdBefore: Joi.date(),

	paging: fields.paginationSchema,
});

module.exports = getAccessLogsSchema;
