import { useDispatch, useSelector } from 'react-redux'
import { startForgeProcess } from '../../../features/forge/forgeSlice'

function Forge() {
	const { recipes } = useSelector(state => state.forge)
	const { currencies } = useSelector(state => state.bank)
	const sortedRecipes = recipes && [...recipes].sort((a, b) => a.createdAt - b.createdAt)

	const dispatch = useDispatch()
	// convert var recipe.timeRequired from seconds into days, hours, minutes and seconds
	const convertTime = time => {
		const days = Math.floor(time / (3600 * 24))
		const hours = Math.floor((time % (3600 * 24)) / 3600)
		const minutes = Math.floor((time % 3600) / 60)
		const seconds = Math.floor(time % 60)

		// retunr the time if it is greater than 0 in days hours minutes and seconds
		return (
			(days > 0 ? days + 'day ' : '') +
			(hours > 0 ? hours + 'hour ' : '') +
			(minutes > 0 ? minutes + 'min ' : '') +
			(seconds > 0 ? seconds + 'sec ' : '')
		)
	}

	// on form submit dispatch the startForgeProcess action with recipeData for the recipe._id and quantity
	const onSubmit = e => {
		e.preventDefault()

		const recipeData = {
			recipeId: e.target.id.value,
			quantity: e.target.quantity.value,
		}

		dispatch(startForgeProcess(recipeData))
	}

	return (
		<div className='centraliser'>
			<header className='centraliser'>Forge</header>
			<div className='forgeback' />
			<div className='cu_card_wrap'>
				{/* sort the recipes by _id */}
				{recipes && recipes.length > 0 ? (
					sortedRecipes.map(recipe => (
						<div className='cu_card' key={recipe._id}>
							<div className='h2_2'>{recipe.name}</div>
							<div className='cu_card_image tb_margin'>
								<img src={recipe.imgUrl} alt={recipe.name} />
							</div>
							<div className='centraliser tb_margin'>
								<div className='cu_card_content'>{recipe.description}</div>
								<div className='cu_card_content'>
									Input : {currencies.find(currency => currency.currencyId === recipe.input.currencyId).name}
									{' x '}
									{recipe.input.amount}
								</div>
								<div className='cu_card_content'>
									Output :{' '}
									{currencies.find(currency => currency.currencyId === recipe.output.currencyId).name}
									{' x '}
									{recipe.output.amount}
								</div>
								<div className='cu_card_content'>
									Time :{/* use the convert time to display recipe.timeRequired */}
									{convertTime(recipe.timeRequired)}
								</div>
								<form onSubmit={e => onSubmit(e, recipe._id)} name={recipe._id}>
									<div className='centraliser tb_margin'>
										<input type='number' name='quantity' placeholder='Quantity' className='form-input3' />
									</div>
									<div className='centraliser'>
										<button type='submit' name='id' className='signin' value={recipe._id}>
											Start Forge
										</button>
									</div>
								</form>
							</div>
						</div>
					))
				) : (
					<div className='cu_card'>
						<div className='h2_2'>No Recipes</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Forge
