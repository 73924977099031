import { useSelector } from 'react-redux'
// import ships from './data.json'

function CreatedShips() {
	const { data } = useSelector(state => state.inventory)

	function capitalizeFirstLetter(str) {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	// only show return if data is not null
	if (data) {
		return (
			<div className='ship-box'>
				<h1 className='centraliser'>Created Ships</h1>
				<div className='ship-container centralizer'>
					{data &&
						data.map((ship, index) => (
							<div className='ships' key={index}>
								<h3 className='centraliser'>{ship.name}</h3>
								<p className='centraliser'>x {ship.count}</p>
								<img src={ship.imgUrl} alt={ship.name} />
								{ship.metadata &&
									Object.entries(ship.metadata).map(([key, value]) => (
										<p className='centraliser' key={key}>
											{capitalizeFirstLetter(key)}: {value}
										</p>
									))}
								{ship.variabledata &&
									Object.entries(ship.variabledata && ship.variabledata).map(([key, value]) => (
										<p className='centraliser' key={key}>
											{capitalizeFirstLetter(key)}: {value}
										</p>
									))}
							</div>
						))}
				</div>
			</div>
		)
	} else {
		return null
	}
}

export default CreatedShips
