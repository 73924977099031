import { Link } from 'react-router-dom';
import { GoCommentDiscussion } from 'react-icons/go';
import { SiAtom } from 'react-icons/si';
import { FaRegPlayCircle } from 'react-icons/fa';

function IconBarLeft() {
	return (
		<div className='iconsbar'>
			<h1 className='spinnercw'>SN</h1>
			<Link to='/'>
				<div className='iconbox'>
					{' '}
					<p className='glow'>H</p>{' '}
				</div>
			</Link>
			<Link to='/underdev'>
				<div className='iconbox'>
					{' '}
					<p className='glow'>$</p>{' '}
				</div>
			</Link>
			<Link to='/analytics'>
				<div className='iconbox'>
					{' '}
					<p className='glow'>F</p>{' '}
				</div>
			</Link>
			<Link to='/media'>
				<div className='iconbox'>
					{' '}
					<p className='glow'>
						<GoCommentDiscussion />
					</p>{' '}
				</div>
			</Link>
			<Link to='/quantumhub'>
				<div className='iconbox'>
					{' '}
					<p className='glow'>
						<SiAtom />
					</p>{' '}
				</div>
			</Link>
			<Link to='/games'>
				<div className='iconbox'>
					{' '}
					<p className='glow'>
						<FaRegPlayCircle />
					</p>{' '}
				</div>
			</Link>
		</div>
	);
}

export default IconBarLeft;
