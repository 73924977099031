import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCollections } from '../../../features/inventory/inventorySlice'
import './ViewUserItemsMobile.css'

function ViewUserItemsMobile(props) {
	const { grouped } = useSelector(state => state.inventory)
	const [userItems, setUserItems] = useState([])

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getCollections())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const userItemsContainerRef = useRef(null)

	// Add an effect to scroll to the userItemsContainer when userItems change
	useEffect(() => {
		if (userItems && userItems.length > 0 && userItemsContainerRef.current) {
			userItemsContainerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [userItems])

	function onGroupedItemClick(e) {
		// setUserItems to the item that was clicked
		setUserItems(grouped.filter(item => item.name === e.target.value))
	}

	function capitalizeFirstLetter(str) {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	return (
		<div className=''>
			<div className='assetselect'>
				<p>{props.collectionName}</p>

				<div className={grouped && grouped.length > 0 ? 'ship-box' : ''}>
					<div className='ship-containermobile'>
						{grouped &&
							grouped.map((item, index) => (
								<div className='shipsmobile' key={index}>
									<img className='image-containermobile' src={item.imgUrl} alt={item.name} />
									<h3 className='centraliser'>{item.name}</h3>
									<p className='centraliser'>x {item.count}</p>

									{item.metadata &&
										Object.entries(item.metadata).map(([key, value]) => (
											<p className='centraliser' key={key}>
												{capitalizeFirstLetter(key)}: {value}
											</p>
										))}
									{item.variabledata &&
										Object.entries(item.variabledata && item.variabledata).map(([key, value]) => (
											<p className='centraliser' key={key}>
												{capitalizeFirstLetter(key)}: {value}
											</p>
										))}
									<div className='centraliser'>
										<button className='signin' value={item.name} onClick={onGroupedItemClick}>
											Details
										</button>
									</div>
								</div>
							))}
					</div>
				</div>
				<div className='useritems_grid' id='userItemsContainer' ref={userItemsContainerRef}>
					{userItems && userItems.length > 0 && (
						<>
							<img className='useritemsimg' src={userItems[0].imgUrl} alt={userItems[0].name} />
							<div className='useritemsdescription'>{userItems[0].description}</div>
							<div className='useritemsstats'>
								<h3 className='centraliser'>{userItems[0].name}</h3>
								{userItems[0].metadata &&
									Object.entries(userItems[0].metadata).map(([key, value]) => (
										<p className='centraliser' key={key}>
											{capitalizeFirstLetter(key)}: {value}
										</p>
									))}
								{userItems[0].variabledata &&
									Object.entries(userItems[0].variabledata).map(([key, value]) => (
										<p className='centraliser' key={key}>
											{capitalizeFirstLetter(key)}: {value}
										</p>
									))}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default ViewUserItemsMobile
