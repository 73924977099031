import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrencyStats } from '../../../features/bank/bankSlice'

import CurrencyStatsList from './CurrencyStatsList'

function CurrencyStatsSort() {
	const [formData, setFormData] = useState({
		startDate: '',
		endDate: '',
		currencyId: '',
	})

	const { startDate, endDate, currencyId } = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const { currencies } = useSelector(state => state.bank)

	function yesterday() {
		const yesterday = new Date()
		yesterday.setDate(yesterday.getDate() - 1)
		const yesterdayString = yesterday.toISOString().split('T')[0]
		const todayString = new Date().toISOString().split('T')[0]
		const logData = {
			startDate: startDate ? startDate : yesterdayString,
			endDate: endDate ? endDate : todayString,
			currencyId: currencyId ? currencyId : '',
		}
		Object.keys(logData).forEach(field => {
			if (logData[field] === '') {
				delete logData[field]
			}
		})

		dispatch(getCurrencyStats(logData))
	}

	function lastWeek() {
		const lastWeek = new Date()
		lastWeek.setDate(lastWeek.getDate() - 7)
		const lastWeekString = lastWeek.toISOString().split('T')[0]
		const todayString = new Date().toISOString().split('T')[0]
		const logData = {
			startDate: startDate ? startDate : lastWeekString,
			endDate: endDate ? endDate : todayString,
			currencyId: currencyId ? currencyId : '',
		}
		Object.keys(logData).forEach(field => {
			if (logData[field] === '') {
				delete logData[field]
			}
		})
		dispatch(getCurrencyStats(logData))
	}

	function lastMonth() {
		const lastMonth = new Date()
		lastMonth.setMonth(lastMonth.getMonth() - 1)
		const lastMonthString = lastMonth.toISOString().split('T')[0]
		const todayString = new Date().toISOString().split('T')[0]
		const logData = {
			startDate: startDate ? startDate : lastMonthString,
			endDate: endDate ? endDate : todayString,
			currencyId: currencyId ? currencyId : '',
		}
		Object.keys(logData).forEach(field => {
			if (logData[field] === '') {
				delete logData[field]
			}
		})
		dispatch(getCurrencyStats(logData))
	}

	const onSubmit = e => {
		e.preventDefault()

		const logData = {
			startDate: startDate ? startDate : '',
			endDate: endDate ? endDate : '',
			currencyId: currencyId ? currencyId : '',
		}

		Object.keys(logData).forEach(field => {
			if (logData[field] === '') {
				delete logData[field]
			}
		})

		dispatch(getCurrencyStats(logData))
	}

	return (
		<div className='centraliser'>
			<h1 className='h4_2'>CurrencyStats</h1>
			<div className='tabs'>
				<button className='signin' onClick={yesterday}>
					Prev day
				</button>
				<button className='signin' onClick={lastWeek}>
					Prev week
				</button>
				<button className='signin' onClick={lastMonth}>
					Prev month
				</button>
			</div>
			<form className='cuss_form' onSubmit={onSubmit}>
				<div className='cussf_selectors'>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Start Date: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='startDate'
								name='startDate'
								value={startDate}
								placeholder='start date'
								onChange={onChange}
							/>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>End Date: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='endDate'
								name='endDate'
								value={endDate}
								placeholder='end date'
								onChange={onChange}
							/>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Currency: </div>
							<select
								className='form-input3 fi_mini'
								id='currencyId'
								name='currencyId'
								value={currencyId}
								onChange={onChange}
							>
								<option value=''>All</option>
								{currencies &&
									currencies.map(currencies => (
										<option key={currencies.currencyId} value={currencies.currencyId}>
											{currencies.ticker}
										</option>
									))}
							</select>
						</div>
					</div>
				</div>
				<div className='tabs'>
					<button type='submit' className='signin'>
						Search
					</button>
				</div>
				<CurrencyStatsList />
			</form>
		</div>
	)
}

export default CurrencyStatsSort
