import { useState } from 'react'
import FaqArray from '../components/builders/FaqArray'

function Faq() {
	const [activeTab, setActiveTab] = useState('FaqEconomy')
	const [menu, setMenu] = useState(true)

	return (
		<div>
			<header>Starlight Nation FaQ</header>
			<div className='faq_container'>
				<div
					className={
						// set the class name to open or close the menu
						menu ? 'leftmenu_scholarships' : 'leftmenu_scholarships_closed'
					}
				>
					<div
						className='leftmenu_button'
						onClick={() => setMenu(!menu)}
						// display a > or < depending on the state of the menu
					>
						{menu ? <div className='leftmb'>{'<'}</div> : <div className='leftmb'>{'>'}</div>}
					</div>
					{menu && (
						<div className='lm_content'>
							<div className='lm_unit'>
								<button className='lmf_item ' onClick={() => setActiveTab('FaqEconomy')}>
									<p className=''>Economy</p>
								</button>

								<button className='lmf_item ' onClick={() => setActiveTab('FaqIP')}>
									<p className=''>IP & TPI</p>
								</button>

								<button className='lmf_item ' onClick={() => setActiveTab('FaqP2E')}>
									<p className=''>P2E</p>
								</button>

								<button className='lmf_item ' onClick={() => setActiveTab('FaqRandC')}>
									<p className=''>Reg. & Comp.</p>
								</button>

								<button className='lmf_item ' onClick={() => setActiveTab('FaqGuilds')}>
									<p className=''>Guilds</p>
								</button>

								<button className='lmf_item ' onClick={() => setActiveTab('FaqOrionsReach')}>
									<p className=''>Orions Reach</p>
								</button>
							</div>
						</div>
					)}
				</div>
				<div className='centraliser22'>
					<div className=''>
						{activeTab === 'FaqEconomy' && <FaqArray header='Economy' content='economy' />}
						{activeTab === 'FaqIP' && (
							<FaqArray header='Intellectual Property & Third Party Integrations' content='ip' />
						)}
						{activeTab === 'FaqP2E' && <FaqArray header='Play to Earn' content='p2e' />}
						{activeTab === 'FaqRandC' && <FaqArray header='Regulation & Compliance' content='randc' />}
						{activeTab === 'FaqGuilds' && <FaqArray header='Guilds' content='guilds' />}
						{activeTab === 'FaqOrionsReach' && <FaqArray header='Orions Reach' content='orionsreach' />}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Faq
