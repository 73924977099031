import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { logout, resetAuth, verifyEmail } from '../features/auth/authSlice'
import Validators from '../validators'
import Spinner from '../components/Spinner'

function VerifyEmail() {
	const { token } = useParams()
	const [{ requestSent }, setRequestSent] = useState({ requestSent: false })
	const { isLoadingAuth, isErrorAuth, isSuccessAuth, messageAuth } = useSelector(state => state.auth)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	//One-time api call on page load up
	useEffect(() => {
		let doIt = !requestSent
		if (doIt) {
			//Toggle this first to ensure we don't spam the API server
			setRequestSent(currentState => ({
				...currentState,
				requestSent: true,
			}))

			//Full schema validation
			const data = { token }
			const schemaValidationResult = Validators['verifyEmail'].validate(data)
			if (schemaValidationResult.error) {
				toast.error(schemaValidationResult.error.message)
				setTimeout(() => {
					navigate('/dashboard')
				}, 5000)
				return
			}

			dispatch(verifyEmail(data))
		}
	}, [requestSent, token, dispatch, navigate])

	//Response handler for API call
	useEffect(() => {
		if (isErrorAuth) {
			toast.error(messageAuth)
			setTimeout(() => {
				dispatch(logout())
				navigate('/dashboard')
			}, 5000)
		}
		if (isSuccessAuth) {
			toast.success(messageAuth + 'Please login to continue')
			setTimeout(() => {
				dispatch(logout())
				navigate('/dashboard')
			}, 5000)
		}

		dispatch(resetAuth())
	}, [isErrorAuth, isSuccessAuth, messageAuth, dispatch, navigate])

	if (isLoadingAuth) {
		return <Spinner />
	}

	return (
		<>
			<div>
				<div className='DevNotice'>
					<h3 className='h3'>Email Verification</h3>
					<p className='notice2'>Standby while we verify your account/email ...</p>
				</div>
			</div>
		</>
	)
}

export default VerifyEmail
