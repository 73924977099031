import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { register, resetAuth } from '../features/auth/authSlice'
import MetamaskButton from '../components/MetamaskButton'
import Spinner from '../components/Spinner'
import { nicknameField, emailField, registerPasswordField } from '../validators/fields'
import Validators from '../validators'
import VInput from '../components/VInput'

function Register() {
	const [formData, setFormData] = useState({
		nickname: '',
		email: '',
		password: '',
		password2: '',
	})

	const { nickname, email, password, password2 } = formData

	const { referrerId } = useParams()
	const referrer = referrerId || ''

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { user, isLoadingAuth, isErrorAuth, isSuccessAuth, messageAuth } = useSelector(state => state.auth)

	useEffect(() => {
		if (isErrorAuth) {
			toast.error(messageAuth)
		}

		if (isSuccessAuth || user) {
			navigate('/login')
			toast.success('Registration Complete - Please Login')
		}

		dispatch(resetAuth())
	}, [user, isErrorAuth, isSuccessAuth, messageAuth, navigate, dispatch])

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const userData = {
			nickname,
			email,
			password,
			referrer: referrer,
		}

		if (password !== password2) {
			toast.error('Passwords do not match')
			return
		}

		Object.keys(userData).forEach(key => {
			if (userData[key] === '') {
				delete userData[key]
			}
		})

		//Full schema validation
		const schemaValidationResult = Validators['register'].validate(userData)
		if (schemaValidationResult.error) {
			toast.error(schemaValidationResult.error.message)
			return
		}

		dispatch(register(userData))
	}

	if (isLoadingAuth) {
		return <Spinner />
	}

	return (
		<>
			<h1 className='h1'>Register{referrerId && <> with Referrer ID: {referrer}</>}</h1>
			<div className='loginbox'>
				<h3 className='h3'>Please create an account</h3>

				<div>
					<form onSubmit={onSubmit}>
						<div className='form-group'>
							<VInput
								type='text'
								className='form-input'
								id='nickname'
								name='nickname'
								value={nickname}
								placeholder='Enter your nickname'
								onChange={onChange}
								validationSchema={nicknameField}
							/>
						</div>
						<div className='form-group'>
							<VInput
								type='email'
								className='form-input'
								id='email'
								name='email'
								value={email}
								placeholder='Enter your email'
								onChange={onChange}
								validationSchema={emailField}
							/>
						</div>
						<div className='form-group'>
							<VInput
								type='password'
								className='form-input'
								id='password'
								name='password'
								value={password}
								placeholder='Enter password'
								onChange={onChange}
								validationSchema={registerPasswordField}
							/>
						</div>
						<div className='form-group'>
							<input
								type='password'
								className='form-input'
								id='password2'
								name='password2'
								value={password2}
								placeholder='Confirm password'
								onChange={onChange}
							/>
						</div>
						{/*if passwords do not match color red if they match color green, if password is '' do not show*/}
						{password && password2 && password !== password2 ? (
							<div className='pw_text_red'>Passwords do not match</div>
						) : (
							password &&
							password2 &&
							password === password2 && <div className='pw_text_green'>Passwords match</div>
						)}
						<div className='pw_text'>
							Password must contain 8-32 characters and contain
							<br />1 uppercase, 1 lowercase, 1 number and 1 special
						</div>
						<div className='form-group'>
							{/* if fields do not = '' show register if any fields = '' show complete all fields */}
							{nickname && email && password && password2 ? (
								<button className='signin' type='submit' onClick={onSubmit}>
									Register
								</button>
							) : (
								<button className='signintall disabled' disabled>
									Complete all fields
								</button>
							)}
						</div>
					</form>
					<div className='otherlogins'>
						<p className='otherloginp'>Or register with</p>
						<MetamaskButton action='register' />
					</div>
					<div>
						<p className='otherloginp'>
							<Link to='/login'>Back to Login</Link>
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default Register
