import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CurrencyControl from './currency/CurrencyControl'
import TransactionLogs from './transaction/TransactionSortAdmin'
import UserLogs from './user/UsersSortAdmin'
import AccessLogs from './user/UserAccessSort'
import AdminLogs from './AdminActionSort'
import UserControl from './user/UserControl'
import GuildControl from './guild/GuildControl'
import SetDefconLevel from './SetDefconLevel'
import CheckoutControl from './checkout/CheckoutControl'
import CheckoutLogs from './checkout/CheckoutSortAdmin'
import ForgeControl from './forge/ForgeControl'
import ForgeLogs from './forge/ProcessSortAdmin'
import CexControl from './cex/CexControl'
import { allTransactions, clearBankLogs, resetBank } from '../../features/bank/bankSlice'
import { toast } from 'react-toastify'
import { clearAuthLogs, getUsersListAdmin, logout, resetAuth } from '../../features/auth/authSlice'
import { clearAuditLogs, getAccessLogs, getAdminLogs, resetAudit } from '../../features/audit/auditSlice'
import {
	getPackages,
	resetCheckout,
	allCheckoutTransactions,
	clearCheckoutLogs,
} from '../../features/checkout/checkoutSlice'
import {
	getForgeRecipes,
	resetForge,
	getForgeProcessesAll,
	clearForgeProcessLogs,
} from '../../features/forge/forgeSlice'
import { clearCexLogs, getPairs, resetCex } from '../../features/cex/cexSlice'

function AdminConsole() {
	const [activeTab, setActiveTab] = useState('CurrencyControl')
	const { isErrorBank, isSuccessBank, messageBank } = useSelector(state => state.bank)
	const { isErrorAuth, isSuccessAuth, messageAuth } = useSelector(state => state.auth)
	const { isErrorAudit, isSuccessAudit, messageAudit } = useSelector(state => state.audit)
	const { isErrorCheckout, isSuccessCheckout, messageCheckout } = useSelector(state => state.checkout)
	const { isErrorForge, isSuccessForge, messageForge } = useSelector(state => state.forge)
	const { isErrorCex, isSuccessCex, messageCex } = useSelector(state => state.cex)

	const dispatch = useDispatch()

	const [pageNum, setPageNum] = useState(1)

	useEffect(() => {
		const initData = {
			paging: {
				limit: 10,
				page: 1,
			},
	}

		if (activeTab === 'ForgeControl') {
			dispatch(getForgeRecipes())
			return () => {
				dispatch(resetForge())
				dispatch(clearForgeProcessLogs())
			}
		}

		if (activeTab === 'CheckoutControl') {
			dispatch(getPackages())
			return () => {
				dispatch(resetCheckout())
			}
		}

		if (activeTab === 'TransactionLogs') {
			dispatch(allTransactions(initData))
			return () => {
				dispatch(resetBank())
				dispatch(clearBankLogs())
				setPageNum(1)
			}
		}

		if (activeTab === 'UserLogs') {
			dispatch(getUsersListAdmin(initData))
			return () => {
				dispatch(resetAuth())
				dispatch(clearAuthLogs())
				setPageNum(1)
			}
		}

		if (activeTab === 'AccessLogs') {
			dispatch(getAccessLogs(initData))
			return () => {
				dispatch(resetAudit())
				dispatch(clearAuditLogs())
				setPageNum(1)
			}
		}

		if (activeTab === 'AdminLogs') {
			dispatch(getAdminLogs(initData))
			return () => {
				dispatch(resetAudit())
				dispatch(clearAuditLogs())
				setPageNum(1)
			}
		}

		if (activeTab === 'ForgeLogs') {
			dispatch(getForgeRecipes())
			const init_data = {
				paging: {
					limit: 10,
					page: 1,
				},
			}
			dispatch(getForgeProcessesAll(init_data))
			return () => {
				dispatch(resetForge())
				dispatch(clearForgeProcessLogs())
				setPageNum(1)
			}
		}

		if (activeTab === 'CheckoutLogs') {
			dispatch(getPackages())
			const init_data = {
				paging: {
					limit: 10,
					page: 1,
				},
			}
			dispatch(allCheckoutTransactions(init_data))
			return () => {
				dispatch(resetCheckout())
				dispatch(clearCheckoutLogs())
				setPageNum(1)
			}
		}

		if (activeTab === 'CexControl') {
			dispatch(getPairs())
			return () => {
				dispatch(resetCex())
				dispatch(clearCexLogs())
			}
		}
	}, [activeTab, dispatch])

	useEffect(() => {
		if (isErrorBank) toast.error(messageBank)
		if (messageBank.includes('Not authorized') || messageBank.includes('Token expired')) dispatch(logout())

		if (isSuccessBank) toast.success(messageBank)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorBank, isSuccessBank, messageBank])

	useEffect(() => {
		if (isErrorBank) {
			setPageNum(pageNum - 1)
		}

		if (!pageNum || pageNum === 0) {
			setPageNum(1)
		}
	}, [isErrorBank, isSuccessBank, messageBank, dispatch, pageNum])

	useEffect(() => {
		dispatch(resetBank())
	}, [isErrorBank, isSuccessBank, dispatch])

	useEffect(() => {
		if (isErrorAuth) toast.error(messageAuth)
		if (messageAuth.includes('Not authorized') || messageAuth.includes('Token expired')) dispatch(logout())

		if (isSuccessAuth) toast.success(messageAuth)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorAuth, isSuccessAuth, messageAuth])

	useEffect(() => {
		if (isErrorAuth) {
			setPageNum(pageNum - 1)
		}
	}, [isErrorAuth, isSuccessAuth, messageAuth, dispatch, pageNum])

	useEffect(() => {
		dispatch(resetAuth())
	}, [isErrorAuth, isSuccessAuth, dispatch])

	useEffect(() => {
		console.log(messageAudit);
		if (isErrorAudit) toast.error(messageAudit)
		if (messageAudit.includes('Not authorized') || messageAudit.includes('Token expired')) dispatch(logout())

		if (isSuccessAudit) toast.success(messageAudit)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorAudit, isSuccessAudit, messageAudit])

	useEffect(() => {
		if (isErrorAudit) {
			setPageNum(pageNum - 1)
		}
	}, [isErrorAudit, isSuccessAudit, messageAudit, dispatch, pageNum])

	useEffect(() => {
		dispatch(resetAudit())
	}, [isErrorAudit, isSuccessAudit, dispatch])

	useEffect(() => {
		if (isErrorForge) toast.error(messageForge)
		if (messageForge.includes('Not authorized') || messageForge.includes('Token expired')) dispatch(logout())

		if (isSuccessForge) toast.success(messageForge)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorForge, isSuccessForge, messageForge])

	useEffect(() => {
		if (isErrorForge) {
			setPageNum(pageNum - 1)
		}
	}, [isErrorForge, isSuccessForge, messageForge, dispatch, pageNum])

	useEffect(() => {
		if (isSuccessForge) {
			dispatch(getForgeRecipes())
			return () => {
				dispatch(resetForge())
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessForge])

	useEffect(() => {
		dispatch(resetForge())
	}, [isErrorForge, isSuccessForge, dispatch])

	useEffect(() => {
		if (isErrorCheckout) toast.error(messageCheckout)
		if (messageCheckout.includes('Not authorized') || messageCheckout.includes('Token expired'))
			dispatch(logout())

		if (isSuccessCheckout) toast.success(messageCheckout)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorCheckout, isSuccessCheckout, messageCheckout])

	useEffect(() => {
		if (isErrorCheckout) {
			setPageNum(pageNum - 1)
		}
	}, [isErrorCheckout, isSuccessCheckout, messageCheckout, dispatch, pageNum])

	useEffect(() => {
		if (isSuccessCheckout) {
			dispatch(getPackages())
			return () => {
				dispatch(resetCheckout())
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessCheckout])

	useEffect(() => {
		dispatch(resetCheckout())
	}, [isErrorCheckout, isSuccessCheckout, dispatch])

	useEffect(() => {
		if (isErrorCex) toast.error(messageCex)
		if (messageCex.includes('Not authorized') || messageCex.includes('Token expired')) dispatch(logout())

		if (isSuccessCex) toast.success(messageCex)
		if (isSuccessCex && messageCex === 'Pair modified') dispatch(getPairs())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorCex, isSuccessCex, messageCex])

	useEffect(() => {
		if (isSuccessCex) {
			dispatch(resetCex())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessCex])

	return (
		<>
			<div className=''>
				<header>Admin Console</header>
				<div className='admin_tabs'>
					<div className='tabs'>
						<nav>
							<button className='tab' onClick={() => setActiveTab('CurrencyControl')}>
								<p className='glow'>Currency</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('TransactionLogs')}>
								<p className='glow'>Tnx Logs</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('UserLogs')}>
								<p className='glow'>User Logs</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('AccessLogs')}>
								<p className='glow'>Access Logs</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('AdminLogs')}>
								<p className='glow'>Admin Logs</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('UserControl')}>
								<p className='glow'>User Control</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('GuildControl')}>
								<p className='glow'>Guild Control</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('SetDefconLevel')}>
								<p className='glow'>Defcon Level</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('CheckoutControl')}>
								<p className='glow'>Checkout Control</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('CheckoutLogs')}>
								<p className='glow'>Checkout Logs</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('ForgeControl')}>
								<p className='glow'>Forge Control</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('ForgeLogs')}>
								<p className='glow'>Forge Process Logs</p>
							</button>
							<button className='tab' onClick={() => setActiveTab('CexControl')}>
								<p className='glow'>Cex Control</p>
							</button>
						</nav>
					</div>
				</div>
				<div className='admin_content'>
					{activeTab === 'CurrencyControl' && <CurrencyControl />}
					{activeTab === 'TransactionLogs' && (
						<TransactionLogs pageNum={pageNum} changePageNum={pageNum => setPageNum(pageNum)} />
					)}
					{activeTab === 'UserLogs' && (
						<UserLogs pageNum={pageNum} changePageNum={pageNum => setPageNum(pageNum)} />
					)}
					{activeTab === 'AccessLogs' && (
						<AccessLogs pageNum={pageNum} changePageNum={pageNum => setPageNum(pageNum)} />
					)}
					{activeTab === 'AdminLogs' && (
						<AdminLogs pageNum={pageNum} changePageNum={pageNum => setPageNum(pageNum)} />
					)}
					{activeTab === 'UserControl' && <UserControl />}
					{activeTab === 'GuildControl' && <GuildControl />}
					{activeTab === 'SetDefconLevel' && <SetDefconLevel />}
					{activeTab === 'CheckoutControl' && <CheckoutControl />}
					{activeTab === 'CheckoutLogs' && (
						<CheckoutLogs pageNum={pageNum} changePageNum={pageNum => setPageNum(pageNum)} />
					)}
					{activeTab === 'ForgeControl' && <ForgeControl />}
					{activeTab === 'ForgeLogs' && (
						<ForgeLogs pageNum={pageNum} changePageNum={pageNum => setPageNum(pageNum)} />
					)}
					{activeTab === 'CexControl' && <CexControl />}
				</div>
			</div>
		</>
	)
}

export default AdminConsole
