function CurrencyTransferMobile(props) {
	return (
		<div>
			<h1 className='centraliser'>Transfers</h1>
			<div className='tabs'>
				<button className='transfertabs' onClick={() => props.setActiveTab('P2PTransferMobile')}>
					<p className='glow'>P2P Transfer</p>
				</button>
				<button className='transfertabs' onClick={() => props.setActiveTab('DistributeCurrency')}>
					<p className='glow'>Distribute Currency</p>
				</button>
			</div>
		</div>
	)
}

export default CurrencyTransferMobile
