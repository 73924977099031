import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openSynthChips, resetInventory } from '../../features/inventory/inventorySlice';
import CreatedShips from './CreatedShips';
import { toast } from 'react-toastify';
import { getUser, logout } from '../../features/auth/authSlice';
import { getCurrencies } from '../../features/bank/bankSlice';

function OpenChips() {
	const { user } = useSelector(state => state.auth);
	const { currencies } = useSelector(state => state.bank);
	const [amount, setAmount] = useState(1);
	const { isSuccessInventory, isLoadingInventory, isErrorInventory, messageInventory } =
		useSelector(state => state.inventory);

	const dispatch = useDispatch();

	const handleQuantityChange = e => {
		setAmount(parseInt(e.target.value));
	};

	const ASC = currencies && currencies.find(x => x.name === 'Alpha Synth Chip');
	console.log(ASC);
	const ChipCount =
		// check for currencies and user
		currencies && user && user.balances.find(x => x.currencyId === ASC.currencyId)
			? user.balances.find(x => x.currencyId === ASC.currencyId).amount
			: 0;

	const onSubmit = e => {
		e.preventDefault();

		const data = {
			amount: amount * 1,
		};

		dispatch(openSynthChips(data));
		setAmount(1);
	};

	const [isGlitching, setIsGlitching] = useState(false);

	useEffect(() => {
		if (!currencies) {
			dispatch(getCurrencies());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencies]);

	useEffect(() => {
		if (isSuccessInventory) {
			toast.success(messageInventory);
			dispatch(getUser());
			dispatch(resetInventory());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessInventory, messageInventory]);

	useEffect(() => {
		if (isErrorInventory) {
			toast.error(messageInventory);
			dispatch(resetInventory());
			// if error contains Unauthorized, logout user
			if (messageInventory.includes('Unauthorized')) dispatch(logout());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorInventory, messageInventory]);

	useEffect(() => {
		if (isLoadingInventory) {
			setIsGlitching(true);
			setTimeout(() => {
				setIsGlitching(false);
			}, 2000);
		}
	}, [isLoadingInventory]);

	if (!user) {
		return <div>Please Login</div>;
	}

	return (
		<div className=''>
			<div className='openchip'>
				{/* <img src='https://dl.dropboxusercontent.com/s/ym4vhzkco3jj5pb/ascopen.png?raw=1' alt='' /> */}
				<div className={`glitch ${isGlitching ? 'glitching' : ''}`} />
			</div>
			<div className='openchip'>You have {ChipCount} ASC ready to Open</div>

			<div className='centraliser openchip'>
				Open {amount}
				<input
					type='range'
					min={1}
					max={ChipCount < 100 ? ChipCount : 100}
					name='quantity'
					value={amount}
					onChange={handleQuantityChange}
				/>{' '}
				Alpha Synth Chip{'(s)'}
			</div>
			<div className='centraliser'>
				<button type='submit' className='signin' onClick={onSubmit}>
					Commence
				</button>
			</div>
			<CreatedShips />
		</div>
	);
}

export default OpenChips;
