import OpenChips from '../components/orionsreach/OpenChips'

function OpenASC() {
	return (
		<>
			<header className='synthchipsale'>Open Synth Chips</header>
			<div className='synthchipback' />
			<OpenChips />
		</>
	)
}

export default OpenASC
