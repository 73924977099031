import axios from 'axios'

const API_URL = '/api/audit/'

// Get User Access Logs
const getAccessLogs = async (logData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL + 'getAccessLogs', logData, config)

	return response.data
}

// Get Admin Action Logs
const getAdminLogs = async (logData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL + 'getAdminLogs', logData, config)

	return response.data
}

// Set Defcon Level
const setDefconLevel = async (defconData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL + 'setDefconLevel', defconData, config)

	return response.data
}

// Clear Logs
const clearAuthLogs = () => {}

const auditService = {
	getAccessLogs,
	getAdminLogs,
	setDefconLevel,
	clearAuthLogs,
}

export default auditService
