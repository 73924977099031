const Joi = require('joi')
const fields = require('./fields')

const updateProfileSchema = Joi.object({
    nickname: fields.nicknameField,
    email: fields.emailField,
    password: fields.registerPasswordField,
    isAnonymous: fields.boolField.label("Anonymous"),
})

module.exports = updateProfileSchema