const Joi = require('joi')
const { nicknameField, currencyIdField, currencyAmountField, noteField } = require('./fields')

const p2pTransferSchema = Joi.object({
    recipientNickName: nicknameField,
    currencyId: currencyIdField,
    amount: currencyAmountField,
    note: noteField,
})

module.exports = p2pTransferSchema