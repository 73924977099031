export function getErrMsg(error) {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
}
export function tryParseJson(data) {
    try { return JSON.parse(data) }
    catch { return null }
}

export function removeEmptyKeys(data) {
    Object.keys(data).forEach(key => {
        if (
            data[key] === undefined ||
            data[key] === '' ||
            data[key] === null
        ) {
            delete data[key]
        }
    })

    return data;
}
