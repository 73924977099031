import { useSelector } from 'react-redux'
import States from '../../../util/checkoutTransactionStates.json'
import Providers from '../../../util/checkoutProviders.json'
import Modal from '../../builders/modal'

function CheckoutListAdmin() {
	const { logs } = useSelector(state => state.checkout)

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}
	const time = {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timezone: 'UTC',
	}

	return (
		<>
			<div className='transactions_grid'>
				<div className='h4_2'>Checkout Log List</div>
				<div className='tg_header'>
					<div>Date (UTC) :</div>
					<div>Purchaser :</div>
					<div>Package Name :</div>
					<div>Cost :</div>
					<div>Provider :</div>
					<div>State :</div>
					<div>Event History :</div>
				</div>

				{logs && logs.length > 0 ? (
					logs.map(logs => (
						<div className='tg_listing' key={logs.createdAt}>
							<div>
								{new Date(logs.createdAt).toLocaleDateString('en-GB', date)}
								&nbsp;
								{new Date(logs.createdAt).toLocaleTimeString('en-GB', time)}
							</div>
							<div>{logs.user.nickname}</div>
							<div>{logs.package.name}</div>
							<div>{logs.package.cost}</div>
							<div>
								{logs.checkoutProvider
									? Providers.type.find(type => type.id === logs.checkoutProvider).name
									: ''}
							</div>
							<div>{logs.state ? States.type.find(type => type.id === logs.state).name : ''}</div>
							<div>
								{/* if eventHistory array is empty then display nothing else display the array in a list of state - date/time */}
								{logs.eventHistory && logs.eventHistory.length > 0 ? (
									<Modal
										header='Event History'
										content={
											<div className='tb_margin2'>
												{logs.eventHistory.map((event, created) => (
													<div key={created}>
														{logs && States.type.find(type => type.id === event.eventType).name}{' '}
														{new Date(event.dateReceived).toLocaleDateString('en-GB', date)}{' '}
														{new Date(event.dateReceived).toLocaleTimeString('en-GB', time)}
													</div>
												))}
											</div>
										}
										btnTextorIcon='Event History'
										btnclass='silver_b'
										modalCloseBtnText='Close'
									/>
								) : (
									<>None</>
								)}
							</div>
						</div>
					))
				) : (
					<div>No Transactions</div>
				)}
			</div>
		</>
	)
}

export default CheckoutListAdmin
