import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getErrMsg } from '../../util/generic'
import authService from './authService'

export const OpStates = {
	Idle: 0,
	WaitForNonce: 2,
	GotNonce: 3,
	WaitForSig: 4,
	WaitForFinish: 5,
}

const initialState = {
	isErrorWallet: false,
	isLoadingWallet: false,
	messageWallet: '',
	opState: OpStates.Idle,
	walletAction: 'login',
	cAppAuthId: '',
}

// Get nonce message
export const getNonce = createAsyncThunk('wallet/getNonce', async (data, thunkAPI) => {
	try {
		return await authService.getNonce(data)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

export const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		reset: state => {
			state.isLoadingWallet = false
			state.isErrorWallet = false
			state.messageWallet = ''
		},
		completeOp: state => {
			state.isLoadingWallet = false
			state.isErrorWallet = false
			state.opState = OpStates.Idle
			state.messageToSign = undefined
		},
		failOp: (state, action) => {
			state.isLoadingWallet = false
			state.messageWallet = action.payload
			state.isErrorWallet = true
			state.opState = OpStates.Idle
			state.messageToSign = undefined
		},
		setOpState: (state, action) => {
			state.opState = action.payload
		},
		setWalletAction: (state, action) => {
			state.walletAction = action.payload
		},
		setWalletCAppAuthId: (state, action) => {
			state.cAppAuthId = action.payload
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getNonce.pending, state => {
				state.isLoadingWallet = true
				state.opState = OpStates.WaitForNonce
			})
			.addCase(getNonce.fulfilled, (state, action) => {
				state.opState = OpStates.GotNonce
				state.isErrorWallet = false
				state.messageToSign = action.payload.messageToSign
			})
			.addCase(getNonce.rejected, (state, action) => {
				state.opState = OpStates.Idle
				state.isLoadingWallet = false
				state.isErrorWallet = true
				state.messageWallet = action.payload
			})
	},
})

export const { reset, completeOp, failOp, setOpState, setWalletAction, setWalletCAppAuthId } = walletSlice.actions
export default walletSlice.reducer
