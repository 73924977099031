import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import modalReducer from '../features/auth/modalSlice'
import walletReducer from '../features/auth/walletSlice'
import bankReducer from '../features/bank/bankSlice'
import auditReducer from '../features/audit/auditSlice'
import checkoutReducer from '../features/checkout/checkoutSlice'
import publicReducer from '../features/public/publicSlice'
import forgeReducer from '../features/forge/forgeSlice'
import cexReducer from '../features/cex/cexSlice'
import inventoryReducer from '../features/inventory/inventorySlice'

export const store = configureStore({
	reducer: {
		auth: authReducer,
		modal: modalReducer,
		wallet: walletReducer,
		bank: bankReducer,
		audit: auditReducer,
		checkout: checkoutReducer,
		public: publicReducer,
		forge: forgeReducer,
		cex: cexReducer,
		inventory: inventoryReducer,
	},
})
