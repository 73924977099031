import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { logout, resetAuth, resetPassword } from '../features/auth/authSlice'
import { registerPasswordField } from '../validators/fields'
import Validators from '../validators'
import VInput from '../components/VInput'
import Spinner from '../components/Spinner'

function ResetPassword() {
	const { token } = useParams()
	const [formData, setFormData] = useState({
		token: '',
		password: '',
		password2: '',
	})
	const { password, password2 } = formData
	const { isLoadingAuth, isErrorAuth, isSuccessAuth, messageAuth } = useSelector(state => state.auth)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const data = {
			token,
			password,
		}

		//Full schema validation
		const schemaValidationResult = Validators['passwordReset'].validate(data)
		if (schemaValidationResult.error) {
			toast.error(schemaValidationResult.error.messageAuth)
			return
		}

		if (password !== password2) {
			toast.error('Passwords do not match')
			return
		}

		dispatch(resetPassword(data))
	}

	//Response handler for API call
	useEffect(() => {
		if (isErrorAuth) {
			toast.error(messageAuth)
			setTimeout(() => {
				dispatch(logout())
				navigate('/dashboard')
			}, 5000)
		}
		if (isSuccessAuth) {
			toast.success(messageAuth)
			setTimeout(() => {
				dispatch(logout())
				navigate('/dashboard')
			}, 5000)
		}

		dispatch(resetAuth())
	}, [isErrorAuth, isSuccessAuth, messageAuth, dispatch, navigate])

	if (isLoadingAuth) {
		return <Spinner />
	}

	return (
		<>
			<div>
				<div className='DevNotice'>
					<h3 className='h3'>Password Reset</h3>
					<p className='notice2'>Please enter your new password</p>
					<form onSubmit={onSubmit}>
						<div className='form-group'>
							<VInput
								type='password'
								className='form-input'
								id='password'
								name='password'
								value={password}
								placeholder='Enter password'
								onChange={onChange}
								validationSchema={registerPasswordField}
							/>
						</div>
						<div className='form-group'>
							<input
								type='password'
								className='form-input'
								id='password2'
								name='password2'
								value={password2}
								placeholder='Confirm password'
								onChange={onChange}
							/>
						</div>
						<p className='notice2'>
							Password must contain 8-32 characters and contain
							<br />1 uppercase, 1 lowercase, 1 number and 1 special
						</p>
						<div className='form-group'>
							<button type='submit' className='signin'>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default ResetPassword
