import React, { useState } from 'react'

function Modal(params) {
	const [isOpen, setIsOpen] = useState(false)

	const handleClick = e => {
		e.preventDefault()
		setIsOpen(true)
		e.stopPropagation()
	}

	const RenderModal = () => {
		if (!isOpen) {
			return null
		}
		return (
			<>
				{/* -----This is the modal container----- */}
				<div className='darkBG centered' onClick={() => setIsOpen(false)} />
				<div className='centered'>
					{/* set the class of the div to params.class or 'popup' */}
					<div className={params.className ? params.className : 'popup'}>
						{/* if no params.header dont display header */}
						{params.header && <h3 className='h3'>{params.header}</h3>}
						<button className='closeBtn' onClick={() => setIsOpen(false)}>
							x
						</button>
						<>{params.content}</>
						<div className='tabs2'>
							<button className='signin tb_margin' onClick={() => setIsOpen(false)}>
								{params.modalCloseBtnText ? params.modalCloseBtnText : 'Cancel'}
							</button>
						</div>
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			{/* -----This is the button----- */}
			<button
				className={params.btnclass}
				title={params.btnHoverText ? params.btnHoverText : params.header}
				onClick={handleClick}
			>
				{params.btnTextorIcon}
			</button>
			{isOpen && RenderModal()}
		</>
	)
}

export default Modal
