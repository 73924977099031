import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../features/auth/authSlice'
import { initBurn } from '../../../features/bank/bankSlice'

function BurnCurrency() {
	const [formData, setFormData] = useState({
		recipientNickName: '',
		amount: '',
		currencyId: '',
		note: '',
	})

	const { recipientNickName, amount, currencyId, note } = formData

	const dispatch = useDispatch()
	const { currencies, isSuccessBank } = useSelector(state => state.bank)

	useEffect(() => {
		if (isSuccessBank) {
			dispatch(getUser())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessBank])

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const userData = {
			recipientNickName,
			currencyId: currencyId * 1,
			amount: amount * 1,
			note,
		}

		dispatch(initBurn(userData))
	}

	return (
		<>
			<div>
				<form onSubmit={onSubmit}>
					<div className='wrap2'>
						<div className='f1_2'>User: </div>
						<input
							type='text'
							className='form-input2'
							id='recipientNickName'
							name='recipientNickName'
							value={recipientNickName}
							placeholder='Recipients nickname '
							onChange={onChange}
						/>
					</div>
					<div className='wrap2'>
						<div className='f1_2'>Currency: </div>
						<select
							className='form-input2'
							id='currencyId'
							name='currencyId'
							value={currencyId}
							onChange={onChange}
						>
							<option></option>
							{currencies.map(currencies => (
								<option key={currencies.currencyId} value={currencies.currencyId}>
									{currencies.ticker}
								</option>
							))}
						</select>
					</div>
					<div className='wrap2'>
						<div className='f1_2'>Amount: </div>
						<input
							type='number'
							className='form-input3'
							id='amount'
							name='amount'
							value={amount}
							placeholder='Enter amount to Burn'
							onChange={onChange}
						/>
					</div>
					<div className='wrap2'>
						<div className='f1_2'>Note: </div>
						<input
							type='text'
							className='form-input3'
							id='note'
							name='note'
							value={note}
							placeholder='Enter note to recipient'
							onChange={onChange}
						/>
					</div>
					<div className='tabs2'>
						<button type='submit' className='signin'>
							Burn
						</button>
					</div>
				</form>
			</div>
		</>
	)
}

export default BurnCurrency
