import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCollections, getUserItemsGrouped } from '../../../features/inventory/inventorySlice'
import './ViewUserItemsMobile.css'

function ViewUserItemsMobile(props) {
	const { collections } = useSelector(state => state.inventory)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getCollections())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function onCollectionChange(e) {
		const data = {
			filters: {
				itemCollection: e,
			},
			paging: {
				page: 1,
				limit: 10,
			},
		}
		dispatch(getUserItemsGrouped(data))
		props.setCollectionName(collections.find(collection => collection._id === e).name)
		props.setActiveTab('ViewUserItemsMobile')
	}

	return (
		<div className=''>
			{collections &&
				collections.map(collection => (
					<button
						className='selectgame'
						key={collection._id}
						onClick={() => {
							onCollectionChange(collection._id)
							props.setActiveTab('ViewUserItemsMobile')
						}}
					>
						<div className='assetselect'>{collection.name}</div>
					</button>
				))}
		</div>
	)
}

export default ViewUserItemsMobile
