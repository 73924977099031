import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, resetAuth } from '../../features/auth/authSlice'

import MetamaskButton from '../../components/MetamaskButton'
import Spinner from '../../components/Spinner'
import ForgotPassword from '../ForgotPassword'

function LoginMobile() {
	const [formData, setFormData] = useState({
		nickOrEmail: '',
		password: '',
	})

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { user, isLoadingAuth, isErrorAuth, isSuccessAuth, messageAuth } = useSelector(state => state.auth)

	const { nickOrEmail, password } = formData

	useEffect(() => {
		if (isErrorAuth) {
			toast.error(messageAuth)
		}

		if (isSuccessAuth) {
			toast.success('Login successful!')

			let expires = new Date()
			expires = expires.setHours(expires.getHours() + 24)
			localStorage.setItem('tokenexpires', expires)

			navigate('/dashboard')
		}

		dispatch(resetAuth())
	}, [user, isErrorAuth, isSuccessAuth, messageAuth, navigate, dispatch])

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const userData = {
			nickOrEmail,
			password,
		}

		dispatch(login(userData))
	}

	if (isLoadingAuth) {
		return <Spinner />
	}

	return (
		<>
			<div className='loginboxmobile'>
				<h1 className='h1mob'>Welcome to </h1>
				<div className='homegraphic'>
					<img
						alt='bankofstarlight'
						src='https://dl.dropboxusercontent.com/s/ek027xs662gcft7/boslogo.png?raw=1'
					/>
				</div>

				<div>
					<form onSubmit={onSubmit} id='login'>
						<div className='form-group-mobile'>
							<input
								type='text'
								className='form-input'
								id='nickOrEmail'
								name='nickOrEmail'
								value={nickOrEmail}
								placeholder='Nickname or Email '
								onChange={onChange}
							/>
						</div>
						<div className='form-group'>
							<input
								type='password'
								className='form-input'
								id='password'
								name='password'
								value={password}
								placeholder='Password'
								onChange={onChange}
							/>
						</div>
					</form>
					<div className='form-group'>
						<button className='signin' onClick={onSubmit}>
							Sign in
						</button>

						<MetamaskButton action='login' />
					</div>

					<div className='form-group'>
						<ForgotPassword />
						<Link to='/register'>
							<button className='signin'>Register</button>
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default LoginMobile
