const Joi = require('joi')
const fields = require('../fields')
const paginationSchema = require('../queries/pagination.validator');

const getItemsSchema = Joi.object({
    filters: Joi.object({
        itemCollection: fields.objectIdField.optional().label('Collection'),
        owner: fields.nicknameField.optional().label('Owner'),
        creator: fields.nicknameField.optional().label('Creator'),
        name: fields.nameField.optional(),
    }).optional(),
    paging: paginationSchema.optional(),
})

module.exports = getItemsSchema