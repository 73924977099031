import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { updateProfile } from '../../features/auth/authSlice'

import { nicknameField } from '../../validators/fields'
import Validators from '../../validators'
import VInput from '../VInput'

function UpdateNickname() {
	const [formData, setFormData] = useState({
		Nickname: '',
		Nickname2: '',
	})

	const dispatch = useDispatch()

	const { nickname, nickname2 } = formData

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const userData = {
			nickname,
		}

		//Full schema validation
		const schemaValidationResult = Validators['updateProfile'].validate(userData)
		if (schemaValidationResult.error) {
			toast.error(schemaValidationResult.error.message)
			return
		}

		if (nickname !== nickname2) {
			toast.error('Nicknames do not match')
			return
		}

		dispatch(updateProfile(userData))
	}

	return (
		<>
			<form onSubmit={onSubmit} id='changeNickname'>
				<div className='form-group'>
					<VInput
						type='nickname'
						className='form-input'
						id='nickname'
						name='nickname'
						value={nickname}
						placeholder='Enter your Nickname'
						onChange={onChange}
						validationSchema={nicknameField}
					/>
				</div>
				<div className='form-group'>
					<input
						type='text'
						className='form-input'
						id='nickname2'
						name='nickname2'
						value={nickname2}
						placeholder='Verify new Nickname'
						onChange={onChange}
					/>
				</div>
				<div className='tabs2'>
					<button className='signin signin3' onClick={onSubmit}>
						Update Nickname
					</button>
				</div>
			</form>
		</>
	)
}

export default UpdateNickname
