import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, resetAuth } from '../features/auth/authSlice'
import truncator from '../components/builders/truncator'
import UserConsole from '../components/userservices/UserConsole'
import Disclaimer from '../components/disclaimer/Disclaimer'
import AdminConsole from '../components/adminservices/AdminConsole'
import { toast } from 'react-toastify'
import { clearBankLogs, resetBank } from '../features/bank/bankSlice'
import DefconMsg from '../components/DefconMsg'
import Modal from '../components/builders/modal'
import { clearCexLogs, resetCex } from '../features/cex/cexSlice'
import CreatorConsole from '../components/creatorservices/CreatorConsole'

function Dashboard() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const onLogout = () => {
		dispatch(logout())
		dispatch(resetAuth())
		dispatch(clearBankLogs())
		dispatch(resetBank())
		dispatch(resetCex())
		dispatch(clearCexLogs())
		navigate('/login')
	}

	const [activeTab, setActiveTab] = useState('UserConsole')
	const { user, token } = useSelector(state => state.auth)
	const tokenexpires = localStorage.getItem('tokenexpires')
	const { defconLvl, defconMsg } = useSelector(state => state.audit)

	useEffect(() => {
		if (defconLvl > 0) {
			toast.warn(defconMsg)
			return () => {
				;<defconMsg />
			}
		}
	}, [defconLvl, defconMsg])

	useEffect(() => {
		if (!token || !tokenexpires || !user) {
			navigate('/login')
		}

		if (tokenexpires && tokenexpires < new Date()) {
			dispatch(logout())
			toast.error('Your credentials have expired, please login again')
			navigate('/login')
		}
	}, [token, tokenexpires, user, navigate, dispatch])

	function isAdmin() {
		if (!user || !user.role) return null

		const buttons = [
			<button className='signin' key='user' onClick={() => setActiveTab('UserConsole')}>
				User
			</button>,
		]

		if (user.role === 'admin') {
			buttons.push(
				<button className='signin' key='admin' onClick={() => setActiveTab('AdminConsole')}>
					Admin
				</button>,
			)
		}

		if (user.role === 'creator') {
			buttons.push(
				<button className='signin' key='creator' onClick={() => setActiveTab('CreatorConsole')}>
					Creator
				</button>,
			)
		}

		return <div className='admin_b'>{buttons}</div>
	}

	function isDefcon() {
		if (!defconLvl || !defconMsg) return
		if (defconLvl > 0) {
			return (
				<div className='defcon_b'>
					<Modal
						header={`Defcon Level ${defconLvl}`}
						content={
							<>
								<div className='defcon_message'>
									<p className=''>We are in devcon level {defconLvl}</p>
									<p className=''>{defconMsg}</p>
								</div>
							</>
						}
						btnTextorIcon={`Defcon Level ${defconLvl}`}
						btnclass='defconbutton'
						modalCloseBtnText='Acknowledged'
					/>
				</div>
			)
		} else {
			return
		}
	}

	function truncatedUser() {
		if (!user || !user.nickname) return

		return <>{truncator(user.nickname)}</>
	}

	return (
		<>
			<div>
				<Disclaimer />
				<DefconMsg />
			</div>
			<div className='bosback' />
			<div className='homegraphic'>
				<img
					alt='bankofstarlight'
					src='https://dl.dropboxusercontent.com/s/ek027xs662gcft7/boslogo.png?raw=1'
				/>
			</div>
			<h1 className='h1'>Welcome to Bank of Starlight {truncatedUser()}</h1>
			<div className='profile_buttons'>
				{isAdmin()}
				{isDefcon()}
				<div className='logout_b'>
					<button className='signin' onClick={onLogout}>
						Logout
					</button>
				</div>
			</div>
			<div>
				{activeTab === 'UserConsole' && <UserConsole />}
				{activeTab === 'AdminConsole' && <AdminConsole />}
				{activeTab === 'CreatorConsole' && <CreatorConsole />}
			</div>
		</>
	)
}

export default Dashboard
