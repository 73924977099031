import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { endForgeProcess, getForgeProcesses } from '../../../features/forge/forgeSlice'
import States from '../../../util/forgeProcessStates.json'
import './ProcessListUserMobile.css'

function ProcessListUser() {
	const { logs, messageForge } = useSelector(state => state.forge)
	const { currencies } = useSelector(state => state.bank)

	const date = {
		year: 'numeric',
		month: 'numeric',
		day: '2-digit',
		timezone: 'UTC',
	}

	const dispatch = useDispatch()

	function Claim(props) {
		const data = {
			processId: props,
		}

		dispatch(endForgeProcess(data))
	}

	useEffect(() => {
		if (messageForge.includes('Process finished') || messageForge.includes('Process cancelled')) {
			const init_data = {
				paging: {
					limit: 10,
					page: 1,
				},
			}
			dispatch(getForgeProcesses(init_data))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messageForge])

	function Cancel(props) {
		const data = {
			processId: props,
			cancel: true,
		}
		dispatch(endForgeProcess(data))
	}

	function ProcessInfo({ state, createdAt, finishDate, input, output, _id }) {
		if (state === 1 && new Date(finishDate) < new Date()) {
			return (
				<button
					className='signin'
					onClick={() => {
						Claim(_id)
					}}
				>
					Claim
				</button>
			)
		}

		if (state === 1 && new Date(createdAt) + 600000 < new Date()) {
			return (
				<button
					className='signin'
					onClick={() => {
						Cancel(_id)
					}}
				>
					Cancel
				</button>
			)
		}

		if (state === 1 && input.amount > output.amount) {
			return <div className='forging'>...Forging</div>
		}

		if (state === 1 && input.amount < output.amount) {
			return <div className='melting'>...Melting</div>
		}

		if (input.amount > output.amount) {
			return 'Forged'
		}

		if (input.amount < output.amount) {
			return 'Melted'
		} else {
			return <>nothing</>
		}
	}

	return (
		<table>
			<thead>
				<tr>
					<th>Start Date/</th>
					<th>Process</th>
					<th>Input/</th>
					<th>State/</th>
				</tr>
				<tr>
					<th>Finish Date</th>
					<th>Name</th>
					<th>Output</th>
					<th>Info</th>
				</tr>
			</thead>
			<tbody>
				{logs && logs.length > 0 ? (
					logs.map((logs, createdAt) => (
						<tr className='' key={createdAt}>
							<td>
								{new Date(logs.createdAt).toLocaleDateString('en-US', date)}
								<br />
								{new Date(logs.finishDate).toLocaleDateString('en-US', date)}
							</td>
							<td>{logs.recipe.name}</td>
							<td>
								{currencies.find(currency => currency.currencyId === logs.input.currencyId).ticker}
								{' x '}
								{logs.input.amount * logs.quantity}
								<br />
								{currencies.find(currency => currency.currencyId === logs.output.currencyId).ticker}
								{' x '}
								{logs.output.amount * logs.quantity}
							</td>
							<td>
								{logs.state ? States.type.find(type => type.id === logs.state).name : ''}
								<br />
								<ProcessInfo {...logs} />
							</td>
						</tr>
					))
				) : (
					<tr className='tg_listing'>
						<td>No logs found</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}

export default ProcessListUser
