import React, { useState } from 'react'
import Twitterfeed from './Twitterfeed'
import Instagram from './Instagram'
import Discord from './Discord'

function Socialmedia() {
	const [activeTab, setActiveTab] = useState('Discord')
	return (
		<div>
			<header>Our Social Media</header>
			<div className='centraliser2'>
				<div className='tabs'>
					<nav>
						<button className='tab' onClick={() => setActiveTab('Discord')}>
							<p className='glow'>Discord</p>
						</button>
						<button className='tab' onClick={() => setActiveTab('Twitterfeed')}>
							<p className='glow'>Twitter</p>
						</button>
						{/* <button className='tab' onClick={() => setActiveTab('Instagram')}>
							<p className='glow'>Instagram</p>
						</button> */}
					</nav>
				</div>
			</div>
			<div className=''>
				{activeTab === 'Discord' && <Discord />}
				{activeTab === 'Twitterfeed' && <Twitterfeed />}
				{activeTab === 'Instagram' && <Instagram />}
			</div>
		</div>
	)
}

export default Socialmedia
