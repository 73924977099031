import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUserStats } from '../../../features/auth/authSlice'

import UserStatsList from './UserStatsList'

function UserStatsSort() {
	const [formData, setFormData] = useState({
		createdAfter: '',
		createdBefore: '',
	})

	const { createdAfter, createdBefore } = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	function yesterday() {
		const yesterday = new Date()
		yesterday.setDate(yesterday.getDate() - 1)
		const yesterdayString = yesterday.toISOString().split('T')[0]
		const todayString = new Date().toISOString().split('T')[0]
		const logData = {
			createdAfter: createdAfter ? createdAfter : yesterdayString,
			createdBefore: createdBefore ? createdBefore : todayString,
		}
		Object.keys(logData).forEach(field => {
			if (logData[field] === '') {
				delete logData[field]
			}
		})

		dispatch(getUserStats(logData))
	}

	function lastWeek() {
		const lastWeek = new Date()
		lastWeek.setDate(lastWeek.getDate() - 7)
		const lastWeekString = lastWeek.toISOString().split('T')[0]
		const todayString = new Date().toISOString().split('T')[0]
		const logData = {
			createdAfter: createdAfter ? createdAfter : lastWeekString,
			createdBefore: createdBefore ? createdBefore : todayString,
		}
		Object.keys(logData).forEach(field => {
			if (logData[field] === '') {
				delete logData[field]
			}
		})
		dispatch(getUserStats(logData))
	}

	function lastMonth() {
		const lastMonth = new Date()
		lastMonth.setMonth(lastMonth.getMonth() - 1)
		const lastMonthString = lastMonth.toISOString().split('T')[0]
		const todayString = new Date().toISOString().split('T')[0]
		const logData = {
			createdAfter: createdAfter ? createdAfter : lastMonthString,
			createdBefore: createdBefore ? createdBefore : todayString,
		}
		Object.keys(logData).forEach(field => {
			if (logData[field] === '') {
				delete logData[field]
			}
		})
		dispatch(getUserStats(logData))
	}

	const onSubmit = e => {
		e.preventDefault()

		const logData = {
			createdAfter: createdAfter ? createdAfter : '',
			createdBefore: createdBefore ? createdBefore : '',
		}

		Object.keys(logData).forEach(field => {
			if (logData[field] === '') {
				delete logData[field]
			}
		})

		dispatch(getUserStats(logData))
	}

	return (
		<div className='centraliser'>
			<h1 className='h4_2'>User Stats</h1>
			<div className='tabs'>
				<button className='signin' onClick={yesterday}>
					Prev day
				</button>
				<button className='signin' onClick={lastWeek}>
					Prev week
				</button>
				<button className='signin' onClick={lastMonth}>
					Prev month
				</button>
			</div>
			<form className='cuss_form' onSubmit={onSubmit}>
				<div className='cussf_selectors'>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Start Date: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='createdAfter'
								name='createdAfter'
								value={createdAfter}
								placeholder='start date'
								onChange={onChange}
							/>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>End Date: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='createdBefore'
								name='createdBefore'
								value={createdBefore}
								placeholder='end date'
								onChange={onChange}
							/>
						</div>
					</div>
				</div>
				<div className='tabs'>
					<button type='submit' className='signin'>
						Search
					</button>
				</div>
				<UserStatsList />
			</form>
		</div>
	)
}

export default UserStatsSort
