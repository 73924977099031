import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createForgeRecipe, resetForge } from '../../../features/forge/forgeSlice'
import Spinner from '../../Spinner'

function CreateRecipe() {
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		imgUrl: '',
		timeRequired: '',
		currencyIdIn: '',
		amountIn: '',
		currencyIdOut: '',
		amountOut: '',
	})

	const { name, description, imgUrl, timeRequired, currencyIdIn, amountIn, currencyIdOut, amountOut } =
		formData
	const { currencies } = useSelector(state => state.bank)
	const { isLoadingForge } = useSelector(state => state.forge)

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const recipeData = {
			name: name,
			description: description,
			imgUrl: imgUrl,
			timeRequired: timeRequired * 1,
			input: {
				currencyId: currencyIdIn * 1,
				amount: amountIn * 1,
			},
			output: {
				currencyId: currencyIdOut * 1,
				amount: amountOut * 1,
			},
		}

		dispatch(createForgeRecipe(recipeData))
		dispatch(resetForge())
	}

	if (isLoadingForge) {
		return <Spinner />
	}

	return (
		<div>
			<form onSubmit={onSubmit}>
				<div className='wrap2'>
					<div className='f1_2'>Name: </div>
					<input
						type='text'
						className='form-input3'
						placeholder='Name'
						name='name'
						value={name}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Description: </div>
					<input
						type='text'
						className='form-input3'
						placeholder='Description'
						name='description'
						value={description}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Image URL: </div>
					<input
						type='text'
						className='form-input3'
						placeholder='Image URL'
						name='imgUrl'
						value={imgUrl}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Time Required: </div>
					<input
						type='text'
						className='form-input3'
						placeholder='Time Required'
						name='timeRequired'
						value={timeRequired}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Currency Input: </div>
					<select
						className='form-input3'
						id='currencyIdIn'
						name='currencyIdIn'
						value={currencyIdIn}
						onChange={onChange}
					>
						<option />
						{currencies.map(currenciesinput => (
							<option key={currenciesinput.currencyId} value={currenciesinput.currencyId}>
								{currenciesinput.name}
							</option>
						))}
					</select>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Amount In: </div>
					<input
						type='text'
						className='form-input3'
						placeholder='Amount In'
						name='amountIn'
						value={amountIn}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Currency Output: </div>
					<select
						className='form-input3'
						id='currencyIdOut'
						name='currencyIdOut'
						value={currencyIdOut}
						onChange={onChange}
					>
						<option />
						{currencies.map(currencies => (
							<option key={currencies.currencyId} value={currencies.currencyId}>
								{currencies.name}
							</option>
						))}
					</select>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Amount Out: </div>
					<input
						type='text'
						className='form-input3'
						placeholder='Amount Out'
						name='amountOut'
						value={amountOut}
						onChange={onChange}
					/>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin'>
						Create
					</button>
				</div>
			</form>
		</div>
	)
}

export default CreateRecipe
