import { useState } from 'react'

import UserCurrency from './bank/UserCurrency'
import UserItems from './inventory/ViewUserItems'

const BoSConsole = () => {
	const [activeTab, setActiveTab] = useState('Currency')

	return (
		<div className='profilebox'>
			<div className='tabs settingsgrid'>
				<button
					className={activeTab === 'Currency' ? 'signin signinwide activetab' : 'signin signinwide'}
					onClick={() => setActiveTab('Currency')}
				>
					Currency
				</button>
				{'     '}
				<button
					className={activeTab === 'GameItems' ? 'signin signinwide activetab' : 'signin signinwide'}
					onClick={() => setActiveTab('GameItems')}
				>
					Game Items
				</button>
			</div>
			<div>
				{activeTab === 'Currency' && <UserCurrency />}
				{activeTab === 'GameItems' && <UserItems />}
			</div>
		</div>
	)
}

export default BoSConsole
