const Joi = require('joi')
const fields = require('./fields')

const modifyCurrencySchema = Joi.object({
    currencyId: fields.currencyIdField.required(),
    ticker: fields.currencyTickerField.optional(),
    name: fields.currencyNameField.optional(),
    precision: fields.currencyPrecisionField.optional(),
    canDeposit: fields.canDepositField.optional(),
    canWithdraw: fields.canWithdrawField.optional(),
    canTransfer: fields.canTransferField.optional(),
    depositTax: fields.depositTaxField.optional(),
    withdrawTax: fields.withdrawTaxField.optional(),
    transferTax: fields.transferTaxField.optional(),
    taxCollector: fields.taxCollectorField.optional(),
    isFrozen: fields.isFrozenField.optional(),
    frozenNote: fields.frozenNoteField.optional(),
    imgUrl: fields.urlField.optional().label('Image URL'),
})

module.exports = modifyCurrencySchema