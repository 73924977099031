import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { distributeCurrency } from '../../../features/bank/bankSlice'

function DistributeCurrency() {
	const [formData, setFormData] = useState({
		distributedCurrency: '',
		amount: '',
		targetCurrency: '',
		note: '',
	})

	const { distributedCurrency, amount, targetCurrency, note } = formData
	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const { currencies } = useSelector(state => state.bank)

	const onSubmit = e => {
		e.preventDefault()

		const currencyData = {
			currencyId: distributedCurrency * 1,
			amount: amount * 1,
			targetCurrencyId: targetCurrency * 1,
			note: note ? note : '',
		}

		Object.keys(currencyData).forEach(key => {
			if (currencyData[key] === '') {
				delete currencyData[key]
			}
		})

		dispatch(distributeCurrency(currencyData))
		setFormData({
			distributedCurrency: '',
			amount: '',
			targetCurrency: '',
			note: '',
		})
	}

	return (
		<>
			<h3 className='centraliser b_margin2 lr_padding'>
				Distribute currency to multiple users who hold the target currency
			</h3>
			<div>
				<form onSubmit={onSubmit}>
					<div className='wrap2_3'>
						<div className='f1_2'>Distributed Currency: </div>
						<select
							className='form-input3'
							id='distributedCurrency'
							name='distributedCurrency'
							value={distributedCurrency}
							onChange={onChange}
						>
							<option />
							{currencies.map(currencies => (
								<option key={currencies.currencyId} value={currencies.currencyId}>
									{currencies.name}
								</option>
							))}
						</select>
					</div>
					<div className='wrap2_3'>
						<div className='f1_2'>Amount: </div>
						<input
							type='number'
							className='form-input3'
							id='amount'
							name='amount'
							value={amount}
							placeholder='Enter amount to transfer'
							onChange={onChange}
						/>
					</div>
					<div className='wrap2_3'>
						<div className='f1_2'>Target Currency: </div>
						<select
							className='form-input3'
							id='targetCurrency'
							name='targetCurrency'
							value={targetCurrency}
							onChange={onChange}
						>
							<option />
							{currencies.map(currencies => (
								<option key={currencies.currencyId} value={currencies.currencyId}>
									{currencies.name}
								</option>
							))}
						</select>
					</div>
					<div className='wrap2_3'>
						<div className='f1_2'>Note: </div>
						<input
							type='text'
							className='form-input3'
							id='note'
							name='note'
							value={note}
							placeholder='Enter note to recipient'
							onChange={onChange}
						/>
					</div>
					<div className='tabs2'>
						<button type='submit' className='signin'>
							Submit
						</button>
					</div>
				</form>
			</div>
		</>
	)
}

export default DistributeCurrency
