import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile } from '../../features/auth/authSlice'

function Anonymous() {
	const anonymousRef = useRef(null)

	const user = useSelector(state => state.auth.user)

	const dispatch = useDispatch()

	const onSubmit = e => {
		e.preventDefault()
		const userData = {
			isAnonymous: anonymousRef.current.checked,
		}
		dispatch(updateProfile(userData))
	}

	return (
		<div className='pg-settings fg-left'>
			<div className='pg1'>Private</div>
			<input
				ref={anonymousRef}
				type='checkbox'
				className='pg2 checkbox'
				id='isAnonymous'
				name='isAnonymous'
				defaultChecked={user.isAnonymous}
			/>
			<button className='pg3 signin signin3' onClick={onSubmit}>
				Update Status
			</button>
		</div>
	)
}

export default Anonymous
