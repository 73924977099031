import axios from 'axios'

const getFaq = async () => {
	const response = await axios.get('https://dl.dropboxusercontent.com/s/l3drq1wo2merpsz/faq.json?raw=1')

	if (response.data) {
		localStorage.setItem('faq', JSON.stringify(response.data))
	}

	return response.data
}

const publicService = {
	getFaq,
}

export default publicService
