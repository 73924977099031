import axios from 'axios'

const API_URL_CEX = '/api/cex/'

// Create Trading Pair
const createTradingPair = async (tradingPairData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CEX + 'createPair', tradingPairData, config)

	return response.data
}

// Modify Trading Pair
const modifyTradingPair = async (tradingPairData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CEX + 'modifyPair', tradingPairData, config)

	return response.data
}

// Delete Trading Pair
const deleteTradingPair = async (tradingPairData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CEX + 'deletePair', tradingPairData, config)

	return response.data
}

// Place Order
const placeOrder = async (orderData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CEX + 'placeOrder', orderData, config)

	return response.data
}

// Cancel Order
const cancelOrder = async (orderData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CEX + 'cancelOrder', orderData, config)

	return response.data
}

// Get Pairs
const getPairs = async () => {
	const response = await axios.get(API_URL_CEX + 'getPairs')

	return response.data
}

// Get Orders
const getOrders = async orderData => {
	const response = await axios.post(API_URL_CEX + 'getOrders', orderData)

	return response.data
}

// Get User Orders
const getUserOrders = async token => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.get(API_URL_CEX + 'getUserOrders', config)

	return response.data
}

// Payout Order
const payoutOrder = async (orderData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL_CEX + 'payoutOrder', orderData, config)

	return response.data
}

// Clear CEX Logs
const clearCexLogs = () => {}

const cexService = {
	createTradingPair,
	modifyTradingPair,
	deleteTradingPair,
	placeOrder,
	cancelOrder,
	getPairs,
	getOrders,
	getUserOrders,
	clearCexLogs,
	payoutOrder,
}

export default cexService
