import { useEffect, useState } from 'react'
import './Disclaimer.css'

function Disclaimer() {
	const [isOpen, setIsOpen] = useState(false)
	const [isDisabled, setIsDisabled] = useState(true)
	const [isChecked, setIsChecked] = useState(false)
	const version = 'v0.0.1'

	function handleChecked(e) {
		setIsChecked(e => !e)
		setIsDisabled(e => !e)
	}

	useEffect(() => {
		const nextDisclaimer = localStorage.getItem('nextDisclaimer')
		//extract version and expires from nextDisclaimer array
		if (nextDisclaimer) {
			const [setExpires, setVersion] = nextDisclaimer.split('|')
			if (nextDisclaimer && setExpires > new Date() && nextDisclaimer && setVersion >= version) {
				return
			} else setIsOpen(true)
		}

		localStorage.removeItem('nextDisclaimer')
		setIsOpen(true)
		var expires = new Date()
		expires = expires.setMonth(expires.getMonth() + 1)

		localStorage.setItem('nextDisclaimer', expires + '|' + version)
	}, [])

	const RenderModal = ({ setIsOpen }) => {
		return (
			<>
				<div className='darkBG' />
				<div className='centered'>
					<div className='disclaimer'>
						<h3 className='h3'>Beta Disclaimer {version}</h3>
						<p className='notice2'>
							Please note that this is a beta version of the Starlight Nation website which is still
							undergoing final testing before its official release. The website, which is not a bank, its
							software and all content found on it are provided for entertainment purposes only on an “as is”
							and “as available” basis. Starlight Nation or its parent entity does not give any warranties,
							whether express or implied, as to the suitability or usability of the website, its software or
							any of its content. Starlight Nation will not be liable for any loss, whether such loss is
							direct, indirect, special or consequential, suffered by any party as a result of their use of
							the Starlight Nation website, its software or content. Should you encounter any bugs, glitches,
							lack of functionality or other problems on the website, please let us know immediately so we can
							rectify these accordingly. Your help in this regard is greatly appreciated.
						</p>
						<div className='tabs2'>
							I have read and agree to the above disclaimer.
							<input type='checkbox' id='checkbox' checked={isChecked} onChange={handleChecked} />
							<button
								className={isDisabled ? 'signin disabled' : 'signin'}
								id='accept'
								disabled={isDisabled}
								onClick={() => setIsOpen(false)}
							>
								{isDisabled ? 'Please read…' : 'Accept'}
							</button>
						</div>
					</div>
				</div>
			</>
		)
	}

	return <>{isOpen && RenderModal({ setIsOpen })}</>
}

export default Disclaimer
