import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import publicService from './publicService'
import { getErrMsg } from '../../util/generic'

const initialState = {
	isErrorPublic: false,
	isSuccessPublic: false,
	isLoadingPublic: false,
	messagePublic: '',
}

// Get Faq
export const getFaq = createAsyncThunk('public/faq', async (_, thunkAPI) => {
	try {
		return await publicService.getFaq()
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

const publicSlice = createSlice({
	name: 'public',
	initialState,
	reducers: {
		resetPublic: state => {
			state.isErrorPublic = false
			state.isSuccessPublic = false
			state.isLoadingPublic = false
			state.messagePublic = ''
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getFaq.pending, state => {
				state.isLoadingPublic = true
			})
			.addCase(getFaq.fulfilled, (state, action) => {
				state.isLoadingPublic = false
				state.isSuccessPublic = true
				state.faq = action.payload
			})
			.addCase(getFaq.rejected, (state, action) => {
				state.isLoadingPublic = false
				state.isErrorPublic = true
				state.messagePublic = action.payload
			})
	},
})

export const { resetPublic } = publicSlice.actions

export default publicSlice.reducer
