import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getForgeProcessesAll } from '../../../features/forge/forgeSlice'

import States from '../../../util/forgeProcessStates.json'
import ProcessListAdmin from './ProcessListAdmin'

function ProcessSortAdmin(props) {
	const [formData, setFormData] = useState({
		limit: '',
		page: '',
		nickname: '',
		startAfter: '',
		startBefore: '',
		finishBefore: '',
		finishAfter: '',
		state: '',
		recipe: '',
	})

	const pageNum = props.pageNum
	const { recipes } = useSelector(state => state.forge)

	const { limit, nickname, startAfter, startBefore, finishBefore, finishAfter, state, recipe } = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const logData = {
			paging: {
				limit: limit ? limit * 1 : 10,
				page: pageNum * 1,
			},
			filters: {
				nickname: nickname ? nickname : '',
				startAfter: startAfter ? startAfter : '',
				startBefore: startBefore ? startBefore : '',
				finishAfter: finishAfter ? finishAfter : '',
				finishBefore: finishBefore ? finishBefore : '',
				state: state ? state * 1 : '',
				recipe: recipe ? recipe : '',
			},
		}

		Object.keys(logData.filters).forEach(key => {
			if (logData.filters[key] === '') {
				delete logData.filters[key]
			}
		})

		dispatch(getForgeProcessesAll(logData))
	}

	return (
		<div className='centraliser'>
			<form onSubmit={onSubmit}>
				<div className='tsa_unit'>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Record Limit: </div>
							<select className='form-input3 fi_mini' name='recLimit' value={limit} onChange={onChange}>
								<option value='10'>10</option>
								<option value='20'>20</option>
								<option value='50'>50</option>
							</select>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>Nickname: </div>
							<input
								type='text'
								className='form-input3 fi_mini'
								id='nickname'
								name='nickname'
								value={nickname}
								placeholder='nickname'
								onChange={onChange}
							/>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Start After: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='startAfter'
								name='startAfter'
								value={startAfter}
								placeholder='startAfter'
								onChange={onChange}
							/>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>Start Before: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='startBefore'
								name='startBefore'
								value={startBefore}
								placeholder='startBefore'
								onChange={onChange}
							/>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Finished After: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='finishAfter'
								name='finishAfter'
								value={finishAfter}
								placeholder='finishAfter'
								onChange={onChange}
							/>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>Finished Before: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='finishBefore'
								name='finishBefore'
								value={finishBefore}
								placeholder='finishBefore'
								onChange={onChange}
							/>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>State: </div>
							<select className='form-input3 fi_mini' name='state' value={state} onChange={onChange}>
								<option value=''>All</option>
								{States.type.map((state, index) => (
									<option key={index} value={state.id}>
										{state.name}
									</option>
								))}
							</select>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>Recipe: </div>
							<select className='form-input3 fi_mini' name='recipeId' value={recipe} onChange={onChange}>
								<option value=''>All</option>
								{recipe &&
									recipes.map((recipe, index) => (
										<option key={index} value={recipe._id}>
											{recipe.name}
										</option>
									))}
							</select>
						</div>
					</div>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin' onClick={() => props.changePageNum(1)}>
						Search
					</button>
				</div>
				<div className='centraliser c_vmargin'>
					<button
						type='submit'
						className='signin signinmini'
						name='previous'
						onClick={() => {
							if (pageNum === 2) {
								props.changePageNum(1)
							} else if (pageNum > 2) {
								props.changePageNum(pageNum - 1)
							}
						}}
					>
						{pageNum === 1 ? '⭮' : '«'}
					</button>
					<span>Page : {pageNum} </span>
					<button
						type='submit'
						className='signin signinmini'
						name='next'
						onClick={() => props.changePageNum(pageNum + 1)}
					>
						»
					</button>
				</div>
				<ProcessListAdmin />
				<div className='centraliser c_vmargin'>
					<button
						type='submit'
						className='signin signinmini'
						name='previous'
						onClick={() => {
							if (pageNum === 2) {
								props.changePageNum(1)
							} else if (pageNum > 2) {
								props.changePageNum(pageNum - 1)
							}
						}}
					>
						{pageNum === 1 ? '⭮' : '«'}
					</button>
					<span>Page : {pageNum} </span>
					<button
						type='submit'
						className='signin signinmini'
						name='next'
						onClick={() => props.changePageNum(pageNum + 1)}
					>
						»
					</button>
				</div>
			</form>
		</div>
	)
}

export default ProcessSortAdmin
