import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { reset, forgotPassword } from '../features/auth/modalSlice'
import { emailField } from '../validators/fields'
import VInput from './VInput'
import Spinner from './Spinner'

function ForgotPassword() {
	const [email, setEmail] = useState('')
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const onChangeFPW = e => {
		setEmail(e.target.value)
	}

	const { isLoadingModal, isErrorModal, isSuccessModal, messageModal } = useSelector(state => state.modal)

	const [IsOpen, setIsOpen] = useState(false)
	const [isButtonDisabled, setIsButtonDisabled] = useState(false)

	const onSubmitFPW = e => {
		e.preventDefault()
		const data = { email }

		//Full schema validation
		const validationResult = emailField.validate(email)
		if (validationResult.error) {
			toast.error(validationResult.error.message)
			return
		}

		setIsButtonDisabled(true)
		setTimeout(() => setIsButtonDisabled(false), 5000)
		dispatch(forgotPassword(data))
	}

	//Response handler for API call
	useEffect(() => {
		if (!IsOpen) return

		let errMsg
		let successMsg

		if (isErrorModal) {
			errMsg = messageModal
		}
		if (isSuccessModal) {
			successMsg = messageModal
		}

		dispatch(reset())
		if (errMsg) toast.error(errMsg)
		if (successMsg) toast.success(successMsg)
	}, [IsOpen, isErrorModal, isSuccessModal, messageModal, dispatch, navigate])

	if (isLoadingModal) {
		return <Spinner />
	}

	const RenderModal = () => {
		return (
			<>
				{/* -----This is the modal container----- */}
				<div className='darkBG' onClick={() => setIsOpen(false)} />
				<div className='centered'>
					<div className='popup'>
						<h3 className='h3'>Forgot Password</h3>
						<button className='closeBtn' onClick={() => setIsOpen(false)}>
							x
						</button>
						<p className='notice2'>Please enter the email address for your account</p>
						<form onSubmit={onSubmitFPW} id='forgotpassword'>
							<div className='form-group'>
								<VInput
									type='email'
									className='form-input'
									id='emailFPW'
									name='email'
									value={email}
									placeholder='email address'
									onChange={onChangeFPW}
									validationSchema={emailField}
								/>
							</div>
							<div className='form-group'>
								<button
									type='submit'
									className='signin'
									id='submitFPW'
									title='submitFPW'
									disabled={isButtonDisabled}
								>
									{isButtonDisabled ? 'Please wait…' : 'Submit'}
								</button>
							</div>
						</form>
					</div>
				</div>
			</>
		)
	}
	return (
		<>
			{/* -----This is the button----- */}
			<button
				type='button'
				className='signin signin4'
				title='Forgot Password?'
				onClick={() => setIsOpen(true)}
			>
				<p>Forgot Password?</p>
			</button>
			{IsOpen && RenderModal()}
		</>
	)
}

export default ForgotPassword
