import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { loginCApp, resetAuth, getCAppAuthRequest } from '../../features/auth/authSlice'
import './LoginCApp.css'

import MetamaskButton from '../../components/MetamaskButton'
import Spinner from '../../components/Spinner'
import ForgotPassword from '../../components/ForgotPassword'

function Login() {
	const [formData, setFormData] = useState({
		nickOrEmail: '',
		password: '',
	})

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const {
		isLoadingAuth,
		isErrorAuth,
		isSuccessAuth,
		messageAuth,
		startedFetchingCAppRequest,
		isFetchCAppRequestError,
		cAppFetchRequestErrorMessage,
		cappUrl,
		cappAuthRequestData,
	} = useSelector(state => state.auth)
	const { nickOrEmail, password } = formData
	const { authId } = useParams()

	useEffect(() => {
		if (isErrorAuth) {
			toast.error(messageAuth)
		}

		if (isSuccessAuth) {
			toast.success('Authorization request approved')

			//Redirect back to CApp's website
			window.location.assign(cappUrl)
		}

		dispatch(resetAuth())

		if (!startedFetchingCAppRequest) {
			dispatch(getCAppAuthRequest({ requestId: authId }))
		}
	}, [
		isErrorAuth,
		isSuccessAuth,
		messageAuth,
		authId,
		cappUrl,
		startedFetchingCAppRequest,
		dispatch,
		navigate,
	])

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const userData = {
			nickOrEmail,
			password,
			cAppAuthRequest: authId,
		}

		dispatch(loginCApp(userData))
	}

	if (isFetchCAppRequestError) {
		return (
			<>
				<h1 className='h1'>{cAppFetchRequestErrorMessage}</h1>
			</>
		)
	}

	if (isLoadingAuth) {
		return <Spinner />
	}

	return (
		<>
			<h1 className='h1'>Bank of Starlight Creator App Authorization</h1>
			<div className='loginbox'>
				<h3 className='h3'>Authorization Login</h3>
				<p className='caapmessage'>{cappAuthRequestData ? cappAuthRequestData.requestMessage : ''}</p>
				<p className='caapmessage'>{cappAuthRequestData ? cappAuthRequestData.customMessage : ''}</p>
				<div>
					<form onSubmit={onSubmit} id='login'>
						<div className='form-group'>
							<input
								type='text'
								className='form-input'
								id='nickOrEmail'
								name='nickOrEmail'
								value={nickOrEmail}
								placeholder='Nickname or Email '
								onChange={onChange}
							/>
						</div>
						<div className='form-group'>
							<input
								type='password'
								className='form-input'
								id='password'
								name='password'
								value={password}
								placeholder='Password'
								onChange={onChange}
							/>
						</div>
					</form>
					<div className='form-group'>
						<button className='signin' onClick={onSubmit}>
							Authorize
						</button>
						<ForgotPassword />
					</div>
					<div className='otherlogins'>
						<p className='otherloginp'>Or Authorize In With</p>
						<button className='signin2'>.</button>
						<MetamaskButton action='login' authId={authId} />
					</div>
					<div className='form-group'>
						<span>Dont have an account?{'  '}</span>
					</div>
					<div className='form-group'>
						<Link to='/register'>
							<button className='signin'>Register</button>
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default Login
