// TwitterContainer.js
import { useEffect } from 'react'
import { VscTwitter } from 'react-icons/vsc'

const Twitterfeed = () => {
	useEffect(() => {
		const script = document.createElement('script')
		script.src = 'https://platform.twitter.com/widgets.js'
		document.getElementsByClassName('twitter-embed')[0].appendChild(script)
	}, [])

	return (
		<div className='centraliser2'>
			<a href='https://twitter.com/NationStarlight' rel='noreferrer' target='_blank'>
				<div className='smlink tb_margin'>
					Our <VscTwitter />
				</div>
			</a>
			<div className='twitter-embed'>
				<a
					className='twitter-timeline'
					data-theme='dark'
					href='https://twitter.com/NationStarlight?ref_src=twsrc%5Etfw'
				>
					Tweets by Starlight Nation
				</a>{' '}
				<script async src='https://platform.twitter.com/widgets.js'></script>
			</div>
		</div>
	)
}

export default Twitterfeed
