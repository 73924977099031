import { useDispatch, useSelector } from 'react-redux'
import { getApiKey, disableApiKeyAccess, newApiKey, clearApiKey } from '../../features/auth/authSlice'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

function ApiKey() {
	const dispatch = useDispatch()
	const { apiKey } = useSelector(state => state.auth)

	const [showKey, toggleShowKey] = useState(false)
	const [isCopied, setIsCopied] = useState(false)

	const handleShowApiKey = () => {
		toggleShowKey(!showKey)
	}

	const handleGetApiKey = () => {
		dispatch(getApiKey())
	}

	const handleDisableApiKeyAccess = () => {
		dispatch(disableApiKeyAccess())
	}

	const handleNewApiKey = () => {
		dispatch(newApiKey())
	}

	const handleCopyClick = () => {
		if (apiKey) {
			navigator.clipboard.writeText(apiKey)
			setIsCopied(true)
			toast.success('Copied to clipboard')
		}
	}

	useEffect(() => {
		return () => {
			dispatch(clearApiKey())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<div className='pg-settings fg-left'>
				<div className='pg1'>Api Key :</div>
				<div className='pg2 ps2'>{apiKey ? (showKey ? apiKey : '••••••••••••••') : 'No Key'}</div>
				<div className='pg3'>
					{apiKey && (
						<button className={'signin signinmini3'} onClick={handleShowApiKey}>
							{showKey ? 'Hide' : 'Show'}
						</button>
					)}
					{apiKey && (
						<button className={'signin signinmini3'} onClick={handleCopyClick}>
							{isCopied ? 'Copied' : 'Copy'}
						</button>
					)}
				</div>
			</div>
			<div className='pg-settings fg-left'>
				<button className='pg1 signin signin3' onClick={handleGetApiKey}>
					Get Key
				</button>
				<button className='pg2 signin signin3' onClick={handleDisableApiKeyAccess}>
					Disable
				</button>
				<button className='pg3 signin signin3' onClick={handleNewApiKey}>
					New Key
				</button>
			</div>
		</>
	)
}

export default ApiKey
