import { useSelector } from 'react-redux'
import './CurrencyArrayMobile.css'
import { BiReceipt } from 'react-icons/bi'

function CurrencyArrayMobile(props) {
	const { user } = useSelector(state => state.auth)
	const { currencies } = useSelector(state => state.bank)

	function Amount(currencyId) {
		return user && user.balances.find(x => x.currencyId === currencyId)
			? user.balances.find(x => x.currencyId === currencyId).amount
			: 0
	}

	if (currencies) {
		const filteredCurrencies = currencies.filter(currency =>
			// if the currency has a balance of 0, don't show it
			{
				return (
					Amount(currency.currencyId) > 0 &&
					(currency.canTransfer || currency.canWithdraw || currency.canDeposit)
				)
			},
		)
		if (filteredCurrencies.length > 0) {
			return (
				<>
					{filteredCurrencies.map((data, currencyId) => (
						<div key={currencyId}>
							<div className='bankgrid'>
								{/* use a button called logs to send the setLogId to the currencyId and setActiveTab to UserLogs */}

								<div className='b1'>
									<div className='cu_icons'>
										<button
											className='logsicon'
											onClick={() => {
												props.setLogId(data.currencyId)
												props.setActiveTab('UserLogs')
											}}
										>
											<BiReceipt />
										</button>
										<img
											alt='token'
											src={
												data.imgUrl
													? data.imgUrl
													: 'https://dl.dropboxusercontent.com/s/s2oyo7dzqi8vsyr/default.gif?raw=1'
											}
										/>
										{`${data.name} ( ${data.ticker} )`} :{' '}
									</div>
								</div>
								<div className='b2'>
									{/* run the Amount function with the currencyId */}
									{Amount(data.currencyId)}
								</div>
							</div>
						</div>
					))}
				</>
			)
		} else {
			return <div className='notice2'>You have no tokens.</div>
		}
	} else {
		return <div className='notice2'>No currencies loaded, please refresh page.</div>
	}
}

export default CurrencyArrayMobile
