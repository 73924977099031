const Joi = require('joi')
const fields = require('../fields')

const forgeProcessFiltersSchema = Joi.object({
    recipe: fields.objectIdField.label('Recipe'),
    finished: fields.boolField.label('Finished'),
    state: fields.integerAboveZeroField.label('State'),
    startBefore: Joi.date().optional().label('Start After'),
    startAfter: Joi.date().optional().label('Start Before'),
    finishBefore: Joi.date().optional().label('Finish After'),
    finishAfter: Joi.date().optional().label('Finish Before'),
})

module.exports = forgeProcessFiltersSchema