import { useSelector } from 'react-redux'
import Categories from '../../../util/transaction'
import Modal from '../../builders/modal'

function TransactionListUserMobile() {
	const { logs, currencies } = useSelector(state => state.bank)
	const { user } = useSelector(state => state.auth)

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}
	const time = {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timezone: 'UTC',
	}

	return (
		<>
			<div className='transactions_grid_mobile'>
				<div className='h4_2'>Transaction Log List</div>
				<div className='tg_user_header_mobile'>
					<div>Date (UTC) :</div>

					<div>Amount :</div>
					<div>Currency :</div>
				</div>

				{logs && logs.length > 0 ? (
					logs.map(logs => (
						<Modal
							key={logs.createdAt}
							header='Transaction Details'
							className='popup_mobile'
							content={
								<div className='popup_mobile_content'>
									<div>
										Date :&nbsp;
										{new Date(logs.createdAt).toLocaleDateString('en-GB', date)}
										&nbsp;
										{new Date(logs.createdAt).toLocaleTimeString('en-GB', time)}
									</div>
									<div>Sender: {logs.sender ? logs.sender.nickname : ''}</div>
									<div>Receipient: {logs.recipient ? logs.recipient.nickname : ''}</div>
									<div>Amount: {logs.balance.amount}</div>
									<div>
										Currency :&nbsp;
										{currencies &&
											currencies.find(currency => currency.currencyId === logs.balance.currencyId).name}
										(
										{currencies &&
											currencies.find(currency => currency.currencyId === logs.balance.currencyId).ticker}
										)
									</div>
									<div>
										Category :&nbsp;
										{logs.category
											? Categories.type.find(category => category.id === logs.category).name
											: ''}
									</div>
									<div className='tg_admin_note'>Note : {logs.note}</div>
								</div>
							}
							btnTextorIcon={
								<div
									className='tg_user_listing_mobile'
									style={{
										color:
											logs &&
											logs.balance.amount > 0 &&
											logs.recipient &&
											logs.recipient.nickname &&
											user &&
											user.nickname
												? logs.recipient.nickname === user.nickname
													? 'green'
													: 'red'
												: 'red',
									}}
								>
									<div>{new Date(logs.createdAt).toLocaleDateString('en-GB', date)}</div>

									<div className='tg_ul_mob_amount'>{logs.balance.amount}</div>
									<div>
										{currencies &&
											currencies.find(currency => currency.currencyId === logs.balance.currencyId).name}
										(
										{currencies &&
											currencies.find(currency => currency.currencyId === logs.balance.currencyId).ticker}
										)
									</div>
								</div>
							}
						/>
					))
				) : (
					<div>No Transactions</div>
				)}
			</div>
		</>
	)
}

export default TransactionListUserMobile
