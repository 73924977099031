import { useState } from 'react';

import './MobileMenu.css';
import { BiMenu } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { GoCommentDiscussion } from 'react-icons/go';
import { SiAtom } from 'react-icons/si';
import { RiQuestionnaireFill, RiShoppingCartFill } from 'react-icons/ri';
import { GiWoodenCrate } from 'react-icons/gi';
import { FaRegPlayCircle } from 'react-icons/fa';

function MobileMenu() {
	const [IsOpen, setIsOpen] = useState(false);
	const [Class, setClass] = useState('accordion');

	function handleClick() {
		setIsOpen(!IsOpen);
		if (Class === 'accordion') {
			setClass('accordion is-openopen');
		} else {
			setClass('accordion');
		}
	}

	return (
		<>
			{IsOpen && (
				<div className='mobilemenutabs' onClick={handleClick}>
					<button className='closeBtn' onClick={handleClick}>
						x
					</button>
					<nav className='navlist'>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>H </span>
								<Link to='/'> Home </Link>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>$ </span>
								<Link to='/underdev'> Rewards </Link>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>F </span>
								<Link to='/analytics'> Analytics </Link>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>
									<GoCommentDiscussion />
								</span>
								<Link to='/media'> Media </Link>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>
									<SiAtom />{' '}
								</span>
								<Link to='/quantumhub'>Quantum Hub</Link>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>
									<FaRegPlayCircle />{' '}
								</span>
								<Link to='/games'>Games</Link>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>A </span>
								<Link to='/dashboard'>Account</Link>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>D </span>
								<a href='https://discord.gg/d7YxABCcR2' target='blank'>
									Discord
								</a>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>L </span>
								<Link to='/leaderboard'>Leader Board</Link>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>
									<RiQuestionnaireFill />{' '}
								</span>
								<Link to='/Faq'>FaQ</Link>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>
									<RiShoppingCartFill />{' '}
								</span>
								<Link to='/checkout'>Buy Credits</Link>
							</p>
						</button>
						<button className='menuoption'>
							<p>
								<span className='menuicon'>
									<GiWoodenCrate />{' '}
								</span>
								<Link to='/synthchips'>Synth Chips</Link>
							</p>
						</button>
					</nav>
				</div>
			)}
			<div className='mobilemenu'>
				<div className='menu_button' onClick={handleClick}>
					<div className='menutext'>Menu</div>
					<div className='svg_size'>
						<BiMenu />
					</div>
				</div>
			</div>
		</>
	);
}

export default MobileMenu;
