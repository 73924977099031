import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { endForgeProcess, getForgeProcesses } from '../../../features/forge/forgeSlice'
import States from '../../../util/forgeProcessStates.json'

function ProcessListUser() {
	const { logs, messageForge } = useSelector(state => state.forge)
	const { currencies } = useSelector(state => state.bank)

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}
	const time = {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timezone: 'UTC',
	}

	const dispatch = useDispatch()

	function Claim(props) {
		const data = {
			processId: props,
		}

		dispatch(endForgeProcess(data))
	}

	useEffect(() => {
		if (messageForge.includes('Process finished') || messageForge.includes('Process cancelled')) {
			const init_data = {
				paging: {
					limit: 10,
					page: 1,
				},
			}
			dispatch(getForgeProcesses(init_data))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messageForge])

	function Cancel(props) {
		const data = {
			processId: props,
			cancel: true,
		}
		dispatch(endForgeProcess(data))
	}

	function ProcessInfo({ state, createdAt, finishDate, input, output, _id }) {
		if (state === 1 && new Date(finishDate) < new Date()) {
			return (
				<button
					className='signin'
					onClick={() => {
						Claim(_id)
					}}
				>
					Claim
				</button>
			)
		}

		if (state === 1 && new Date(createdAt) + 600000 < new Date()) {
			return (
				<button
					className='signin'
					onClick={() => {
						Cancel(_id)
					}}
				>
					Cancel
				</button>
			)
		}

		if (state === 1 && input.amount > output.amount) {
			return <div className='forging'>...Forging</div>
		}

		if (state === 1 && input.amount < output.amount) {
			return <div className='melting'>...Melting</div>
		}

		if (input.amount > output.amount) {
			return 'Forged'
		}

		if (input.amount < output.amount) {
			return 'Melted'
		} else {
			return <>nothing</>
		}
	}

	return (
		<div className='transactions_grid'>
			<div className='h4_2'>Forge Process List</div>
			<div className='tg_header'>
				<div>Start Date (UTC) :</div>
				<div>Finish Date (UTC) :</div>
				<div>Process Name :</div>
				<div>Input :</div>
				<div>Output :</div>
				<div>State :</div>
			</div>
			{logs && logs.length > 0 ? (
				logs.map((logs, createdAt) => (
					<div className='tg_listing' key={createdAt}>
						<div>
							{new Date(logs.createdAt).toLocaleDateString('en-GB', date)}
							&nbsp;
							{new Date(logs.createdAt).toLocaleTimeString('en-GB', time)}
						</div>
						<div>
							{new Date(logs.finishDate).toLocaleDateString('en-GB', date)}
							&nbsp;
							{new Date(logs.finishDate).toLocaleTimeString('en-GB', time)}
						</div>
						<div>{logs.recipe.name}</div>
						<div>
							{currencies.find(currency => currency.currencyId === logs.input.currencyId).ticker}
							{' x '}
							{logs.input.amount * logs.quantity}
						</div>
						<div>
							{currencies.find(currency => currency.currencyId === logs.output.currencyId).ticker}
							{' x '}
							{logs.output.amount * logs.quantity}
						</div>
						<div>{logs.state ? States.type.find(type => type.id === logs.state).name : ''}</div>
						<div>
							<ProcessInfo {...logs} />
						</div>
					</div>
				))
			) : (
				<div className='tg_listing'>
					<div>No logs found</div>
				</div>
			)}
		</div>
	)
}

export default ProcessListUser
