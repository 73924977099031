import React, { useState } from 'react'
import IconBarLeft from '../components/IconBarLeft'
import IconBarRight from '../components/IconBarRight'
import ContentWindow from '../components/ContentWindow'
import MenuBarLeft from '../components/MenuBarLeft'
import MenuBarRight from '../components/MenuBarRight'

function Main() {
	const [IsShownMenuBarLeft, setIsShownMenuBarLeft] = useState(true)
	const [IsShownMenuBarRight, setIsShownMenuBarRight] = useState(true)

	function closeMenuBars() {
		setIsShownMenuBarLeft(false)
		setIsShownMenuBarRight(false)
	}

	return (
		<section>
			<div onMouseEnter={() => setIsShownMenuBarLeft(true)}>
				<IconBarLeft />
			</div>
			{IsShownMenuBarLeft && <MenuBarLeft />}
			<div className='content' onMouseEnter={closeMenuBars}>
				<ContentWindow />
			</div>
			{IsShownMenuBarRight && <MenuBarRight />}
			<div onMouseEnter={() => setIsShownMenuBarRight(true)}>
				<IconBarRight />
			</div>
		</section>
	)
}

export default Main
