const Joi = require('joi')
const fields = require('./fields')

const createCurrencySchema = Joi.object({
    ticker: fields.currencyTickerField.required(),
    name: fields.currencyNameField.required(),
    precision: fields.currencyPrecisionField.required(),
    canDeposit: fields.canDepositField.required(),
    canWithdraw: fields.canWithdrawField.required(),
    canTransfer: fields.canTransferField.required(),
    isFrozen: fields.isFrozenField.required(),
    frozenNote: fields.frozenNoteField.optional(),
    depositTax: fields.depositTaxField.optional(),
    withdrawTax: fields.withdrawTaxField.optional(),
    transferTax: fields.transferTaxField.optional(),
    taxCollector: fields.taxCollectorField.optional(),
    imgUrl: fields.urlField.optional().label('Image URL'),
})

module.exports = createCurrencySchema