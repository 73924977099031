const Joi = require('joi')
const fields = require('../fields')

const createItemSchema = Joi.object({
    itemCollection: fields.objectIdField.required().label('Item Collection'),
    owner: fields.nicknameField.optional().label('Owner'),
    name: fields.nameField.required(),
    description: fields.descriptionField.optional(),
    imgUrl: fields.urlField.optional().label('Image URL'),
    metadata: fields.nestedObjectField.optional(),
    variabledata: fields.nestedObjectField.optional(),
    hiddendata: fields.nestedObjectField.optional(),
})

module.exports = createItemSchema