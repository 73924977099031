import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getErrMsg } from '../../util/generic'
import authService from './authService'

const initialState = {
	isErrorModal: false,
	isSuccessModal: false,
	isLoadingModal: false,
	messageModal: '',
}

// Send verification email
export const sendVerificationEmail = createAsyncThunk(
	'modal/sendVerificationEmail',
	async (token, thunkAPI) => {
		try {
			return await authService.sendVerificationEmail(token)
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error))
		}
	},
)

// Forgot Password
export const forgotPassword = createAsyncThunk('modal/forgotPassword', async (data, thunkAPI) => {
	try {
		return await authService.forgotPassword(data)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		reset: state => {
			state.isLoadingModal = false
			state.isSuccessModal = false
			state.isErrorModal = false
			state.messageModal = ''
		},
	},
	extraReducers: builder => {
		builder
			.addCase(sendVerificationEmail.pending, state => {
				state.isLoadingModal = true
			})
			.addCase(sendVerificationEmail.fulfilled, (state, action) => {
				state.isLoadingModal = false
				state.isSuccessModal = true
				state.messageModal = action.payload.message
			})
			.addCase(sendVerificationEmail.rejected, (state, action) => {
				state.isLoadingModal = false
				state.isErrorModal = true
				state.messageModal = action.payload
			})
			.addCase(forgotPassword.pending, state => {
				state.isLoadingModal = true
			})
			.addCase(forgotPassword.fulfilled, (state, action) => {
				state.isLoadingModal = false
				state.isSuccessModal = true
				state.messageModal = action.payload.message
			})
			.addCase(forgotPassword.rejected, (state, action) => {
				state.isLoadingModal = false
				state.isErrorModal = true
				state.messageModal = action.payload
			})
	},
})

export const { reset } = modalSlice.actions
export default modalSlice.reducer
