import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../features/auth/authSlice'
import { toast } from 'react-toastify'
import { resetInventory } from '../../features/inventory/inventorySlice'
import CollectionControl from './CollectionControl'

function CreatorConsole() {
	const [activeTab, setActiveTab] = useState('CollectionControl')
	const { isErrorInventory, isSuccessInventory, messageInventory } = useSelector(state => state.inventory)

	const dispatch = useDispatch()

	useEffect(() => {
		if (isErrorInventory) toast.error(messageInventory)
		if (messageInventory.includes('Not authorized') || messageInventory.includes('Token expired'))
			dispatch(logout())

		dispatch(resetInventory())

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrorInventory, messageInventory])

	useEffect(() => {
		if (isSuccessInventory) {
			toast.success(messageInventory)
			dispatch(resetInventory())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessInventory, messageInventory])

	return (
		<>
			<div className=''>
				<header>Admin Console</header>
				<div className='admin_tabs'>
					<div className='tabs'>
						<nav>
							<button className='tab' onClick={() => setActiveTab('CollectionControl')}>
								<p className='glow'>Collection Control</p>
							</button>
						</nav>
					</div>
				</div>
				<div className='admin_content'>{activeTab === 'CollectionControl' && <CollectionControl />}</div>
			</div>
		</>
	)
}

export default CreatorConsole
