import { useDispatch } from 'react-redux'
import { BsFillQuestionDiamondFill } from 'react-icons/bs'
import { getUser } from '../../../features/auth/authSlice'
import CurrencyArray from '../../builders/CurrencyArray'
import Modal from '../../builders/modal'
import DistributeCurrency from './DistributeCurrency'
import WhatIsDistCur from './WhatIsDistCur'

function UserCurrency() {
	const dispatch = useDispatch()

	const onRefresh = () => {
		dispatch(getUser())
	}
	return (
		<div className='centraliser'>
			<div className='subheaderwrap'>
				<h4 className='h4_2'>My Tokens</h4>
				<div className='centraliser b_margin'>
					<Modal
						header={`Distribute Currency`}
						content={<DistributeCurrency />}
						btnHoverText={`Distribute currency to multiple users who hold the target currency`}
						btnTextorIcon={`Distribute Currency`}
						btnclass='signin signinwide2'
					/>

					<Modal
						header='What is Distribute Currency ?'
						content={<WhatIsDistCur />}
						btnTextorIcon={<BsFillQuestionDiamondFill />}
						btnclass='question float_right'
					/>
				</div>

				<div onClick={onRefresh} className='refreshbutton'>
					<button className='signin signinmini'>⭮</button>
				</div>
			</div>

			<div>
				<CurrencyArray />
			</div>
		</div>
	)
}

export default UserCurrency
