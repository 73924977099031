import { RiQuestionnaireFill, RiShoppingCartFill } from 'react-icons/ri'
import { GiWoodenCrate } from 'react-icons/gi'
import { Link } from 'react-router-dom'

function IconBarRight() {
	return (
		<div className='iconsbarright'>
			<h1 className='spinnercw'>SN</h1>
			<Link to='/dashboard'>
				{' '}
				<div className='iconbox'>
					{' '}
					<p className='glow'>A</p>{' '}
				</div>{' '}
			</Link>
			<a href='https://discord.gg/d7YxABCcR2' target='blank'>
				{' '}
				<div className='iconbox'>
					{' '}
					<p className='glow'>D</p>{' '}
				</div>{' '}
			</a>

			<Link to='/leaderboard'>
				{' '}
				<div className='iconbox'>
					{' '}
					<p className='glow'>L</p>{' '}
				</div>{' '}
			</Link>
			<Link to='/Faq'>
				{' '}
				<div className='iconbox'>
					{' '}
					<p className='glow'>
						<RiQuestionnaireFill />
					</p>{' '}
				</div>{' '}
			</Link>
			<Link to='/checkout'>
				{' '}
				<div className='iconbox'>
					{' '}
					<p className='glow'>
						<RiShoppingCartFill />
					</p>{' '}
				</div>{' '}
			</Link>
			<Link to='/synthchips'>
				{' '}
				<div className='iconbox'>
					{' '}
					<p className='glow'>
						<GiWoodenCrate />
					</p>{' '}
				</div>{' '}
			</Link>
		</div>
	)
}

export default IconBarRight
