const Joi = require('joi')
const fields = require('../fields')

const distributeCurrency = Joi.object({
    currencyId: fields.currencyIdField.required(),
    amount: fields.currencyAmountField.required(),
    targetCurrencyId: fields.currencyIdField.required().label('Target Currency'),
    note: fields.noteField,
})

module.exports = distributeCurrency