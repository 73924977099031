import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDefconLevel } from '../../features/audit/auditSlice'

function SetDefconLevel() {
	const [formData, setFormData] = useState({
		defconLvl: '',
		defconMsg: '',
	})

	const { defconLvl, defconMsg } = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const defconData = {
			defconLvl: defconLvl * 1,
			defconMsg: defconMsg,
		}

		dispatch(setDefconLevel(defconData))
	}

	return (
		<>
			<div className='width90'>
				<form onSubmit={onSubmit}>
					<div className='wrap1_2'>
						<div className='f1 f1_2wider'>Set Defcon Level : </div>
						<select
							type='number'
							className='form-input3'
							id='defconLvl'
							name='defconLvl'
							value={defconLvl}
							placeholder='Role to Assign'
							onChange={onChange}
						>
							<option value=''>Select Defcon Lvl</option>
							<option value='0'>0</option>
							<option value='1'>1</option>
							<option value='2'>2</option>
							<option value='3'>3</option>
							<option value='4'>4</option>
						</select>
					</div>
					<div className='wrap1_2'>
						<div className='f1 f1_2wider'>Defcon Message : </div>
						<input
							type='text'
							className='form-input3 width90'
							id='defconMsg'
							name='defconMsg'
							value={defconMsg}
							placeholder='Message'
							onChange={onChange}
						/>
					</div>
					<div className='tabs2'>
						<button type='submit' className='signin'>
							Submit
						</button>
					</div>
				</form>
			</div>
			<div className='defcon_message'>
				<div className='h4_2'>Defcon levels explained</div>
				<p> reserved for the explanation</p>

			</div>
		</>
	)
}

export default SetDefconLevel
