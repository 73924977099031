import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import inventoryService from './inventoryService'
import { getErrMsg } from '../../util/generic'

const initialState = {
	isErrorInventory: false,
	isSuccessInventory: false,
	isLoadingInventory: false,
	messageInventory: '',
	logs: null,
}

// Create Item Collection

export const createItemCollection = createAsyncThunk(
	'inventory/createItemCollection',
	async (itemCollectionData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token
			return await inventoryService.createItemCollection(itemCollectionData, token)
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error))
		}
	},
)

// Create Item

export const createItem = createAsyncThunk('invnetory/createItem', async (itemData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await inventoryService.createItem(itemData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Open Alpha Synth Chip

export const openSynthChips = createAsyncThunk('inventory/openSynthChips', async (itemData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await inventoryService.openSynthChips(itemData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Get Item Collections

export const getCollections = createAsyncThunk('inventory/getCollections', async (_, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await inventoryService.getCollections(token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Get Items

export const getItems = createAsyncThunk('inventory/getItems', async (data, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await inventoryService.getItems(data, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Get User Items Grouped

export const getUserItemsGrouped = createAsyncThunk(
	'inventory/getUserItemsGrouped',
	async (data, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token
			return await inventoryService.getUserItemsGrouped(data, token)
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error))
		}
	},
)

// Get User Items

export const getUserItems = createAsyncThunk('inventory/getUserItems', async (data, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await inventoryService.getUserItems(data, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Clear Logs

export const clearInventoryLogs = createAsyncThunk('inventory/clearInventoryLogs', async () => {
	inventoryService.clearInventoryLogs()
})

const inventorySlice = createSlice({
	name: 'inventory',
	initialState,
	reducers: {
		resetInventory: state => {
			state.isErrorInventory = false
			state.isSuccessInventory = false
			state.isLoadingInventory = false
			state.messageInventory = ''
		},
	},
	extraReducers: {
		// Create Item Collection
		[createItemCollection.pending]: state => {
			state.isLoadingInventory = true
		},
		[createItemCollection.fulfilled]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isSuccessInventory = true
			state.messageInventory = payload.message
		},
		[createItemCollection.rejected]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isErrorInventory = true
			state.messageInventory = payload
		},
		// Create Item
		[createItem.pending]: state => {
			state.isLoadingInventory = true
		},
		[createItem.fulfilled]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isSuccessInventory = true
			state.messageInventory = payload.message
		},
		[createItem.rejected]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isErrorInventory = true
			state.messageInventory = payload.message
		},
		// Open Alpha Synth Chip
		[openSynthChips.pending]: state => {
			state.isLoadingInventory = true
		},
		[openSynthChips.fulfilled]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isSuccessInventory = true
			state.data = payload.createdItems
			state.messageInventory = payload.message
		},
		[openSynthChips.rejected]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isErrorInventory = true
			state.messageInventory = payload.message || payload
		},
		// Get Item Collections
		[getCollections.pending]: state => {
			state.isLoadingInventory = true
		},
		[getCollections.fulfilled]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isSuccessInventory = true
			state.collections = payload.data
			state.messageInventory = payload.message
		},
		[getCollections.rejected]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isErrorInventory = true
			state.messageInventory = payload.message
		},
		// Get Items
		[getItems.pending]: state => {
			state.isLoadingInventory = true
		},
		[getItems.fulfilled]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isSuccessInventory = true
			state.items = payload.data
			state.messageInventory = payload.message
		},
		[getItems.rejected]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isErrorInventory = true
			state.messageInventory = payload.message
		},
		// Get User Items Grouped
		[getUserItemsGrouped.pending]: state => {
			state.isLoadingInventory = true
		},
		[getUserItemsGrouped.fulfilled]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isSuccessInventory = true
			state.grouped = payload.data
			state.messageInventory = payload.message
		},
		[getUserItemsGrouped.rejected]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isErrorInventory = true
			state.messageInventory = payload.message
		},
		// Get User Items
		[getUserItems.pending]: state => {
			state.isLoadingInventory = true
		},
		[getUserItems.fulfilled]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isSuccessInventory = true
			state.userItems = payload.data
			state.messageInventory = payload.message
		},
		[getUserItems.rejected]: (state, { payload }) => {
			state.isLoadingInventory = false
			state.isErrorInventory = true
			state.messageInventory = payload.message
		},
		// Clear Logs
		[clearInventoryLogs.fulfilled]: state => {
			state.logs = []
			state.data = []
		},
	},
})

export const { resetInventory } = inventorySlice.actions

export default inventorySlice.reducer
