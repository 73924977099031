import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createItemCollection, resetInventory } from '../../features/inventory/inventorySlice'

import Spinner from '../Spinner'

function CreateItemCollection() {
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		imgUrl: '',
	})

	const { name, description, imgUrl } = formData
	const { isLoadingInventory } = useSelector(state => state.inventory)

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const data = {
			name: name,
			description: description,
			imgUrl: imgUrl,
		}

		Object.keys(data).forEach(key => data[key] === '' && delete data[key])

		dispatch(createItemCollection(data))
		dispatch(resetInventory())
	}

	if (isLoadingInventory) {
		return <Spinner />
	}

	return (
		<div>
			<form onSubmit={onSubmit}>
				<div className='wrap2'>
					<div className='f1_2'>Name: </div>
					<input
						type='text'
						className='form-input3'
						placeholder='Name'
						name='name'
						value={name}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Description: </div>
					<input
						type='text'
						className='form-input3'
						placeholder='Description'
						name='description'
						value={description}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Image URL: </div>
					<input
						type='text'
						className='form-input3'
						placeholder='Image URL'
						name='imgUrl'
						value={imgUrl}
						onChange={onChange}
					/>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin'>
						Create
					</button>
				</div>
			</form>
		</div>
	)
}

export default CreateItemCollection
