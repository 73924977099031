import { useSelector } from 'react-redux'
import Modal from '../../builders/modal'
import truncator from '../../builders/truncator'

function UsersListAdmin() {
	const { logs } = useSelector(state => state.auth)

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}

	return (
		<>
			<div className='transactions_grid'>
				<div className='h4_2'>Users List</div>
				<div className='ul_header'>
					<div>Nickname :</div>
					<div>Created :</div>
					<div>Updated :</div>
					<div>Email Verified :</div>
					<div>Wallet Only :</div>
					<div>Anonymous :</div>
					<div>Active :</div>
					<div>Role :</div>
					<div>Has Currency :</div>
				</div>

				{logs && logs.length > 0 ? (
					logs.map(logs => (
						<div className='ul_listing' key={logs.createdAt}>
							<div>
								{logs.nickname.length < 12 ? (
									logs.nickname
								) : (
									<Modal
										header='Full Nickname'
										content={<div>{logs.nickname}</div>}
										btnTextorIcon={truncator(logs.nickname)}
										btnclass=''
										modalCloseBtnText='Close'
									/>
								)}
							</div>
							<div>{new Date(logs.createdAt).toLocaleDateString('en-GB', date)}</div>
							<div>{new Date(logs.updatedAt).toLocaleDateString('en-GB', date)}</div>
							<div>{logs && logs.isEmailVerified === true ? 'Yes' : 'No'}</div>
							<div>{logs && logs.isWalletOnly === true ? 'Yes' : 'No'}</div>
							<div>{logs && logs.isAnonymous === true ? 'Yes' : 'No'}</div>
							<div>{logs && logs.isActive === true ? 'Yes' : 'No'}</div>
							<div>{logs && logs.role}</div>
							<div>{logs && logs.balances.length > 0 ? 'Yes' : 'No'}</div>
						</div>
					))
				) : (
					<div>No Users</div>
				)}
			</div>
		</>
	)
}

export default UsersListAdmin
