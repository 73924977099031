import { useSelector } from 'react-redux'

function UserStats() {
	const userStats = useSelector(state => state.auth.userStats)

	return (
		<div className='cu_card_wrap'>
			{userStats ? (
				<div className='cu_card usl_card'>
					<div className='h2_2'>User Stats</div>
					<div className='ccc_border'>
					<div className='usl_card_content'>
						<div>
						<strong>Total : </strong>
						<div>{userStats.total}</div>
						<br></br>
						</div>
					</div>
					<div className='usl_card_content'>
						<div>
					    <strong>Verified Email Accounts :</strong> 
						<div>{userStats.verifiedEmailAccounts}</div>
						<br></br>
						</div>
					</div>
					<div className='usl_card_content'>
						<div>
					    <strong>Wallet Users :</strong>
					    <div> {userStats.walletUsers}</div>
						<br></br>
						</div>
					</div>	
					<div className='usl_card_content'>
						<div>
					    <strong>Anonymous Users : </strong>
						<div>{userStats.anonymousUsers}</div>
						<br></br>
						</div>
					</div>
					<div className='usl_card_content'>
						<div>
					    <strong>Disabled Accounts :</strong> 
						<div>{userStats.disabledAccounts}</div>
						<br></br>
						</div>
					</div>
					<div className='usl_card_content'>
						<div>
					    <strong>Admin Users :</strong> 
						<div>{userStats.adminUsers}</div>
						<br></br>
						</div>
					</div>
					<div className='usl_card_content'>
						<div>
					    <strong>Has Currency : </strong>
						<div>{userStats.haveCurrency}</div>
						<br></br>
						</div>
					</div>
					</div>
				</div>
			) : (
				<div>No User Stats</div>
			)}
		</div>
	)
}

export default UserStats
