import { useDispatch } from 'react-redux'
import { cancelOrder, resetCex } from '../../../features/cex/cexSlice'

function CancelOrder(props) {
	const dispatch = useDispatch()

	const onSubmit = e => {
		e.preventDefault()

		const orderData = {
			orderId: props.orderId,
		}

		dispatch(cancelOrder(orderData))
		dispatch(resetCex())
	}

	return (
		<div>
			<form onSubmit={onSubmit}>
				<div>
					<button type='submit' value='Cancel' name='Cancel' className='signin signinmini2'>
						{' '}
						Cancel
					</button>
				</div>
			</form>
		</div>
	)
}

export default CancelOrder
