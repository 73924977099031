import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getErrMsg, tryParseJson } from '../../util/generic';
import { initTransfer, withdraw, deposit } from '../bank/bankSlice';
import authService from './authService';

//Get user/token from localstorage
const user = tryParseJson(localStorage.getItem('user'));
const token = tryParseJson(localStorage.getItem('token'));

const initialState = {
	token: token ? token : null,
	user: user ? user : null,
	isErrorAuth: false,
	isSuccessAuth: false,
	isLoadingAuth: false,
	messageAuth: '',
	messageToSign: '',
	logs: null,
	cappUrl: '',
	startedFetchingCAppRequest: false,
	isFetchCAppRequestError: false,
	cAppFetchRequestErrorMessage: '',
	cappAuthRequestData: null,
};

// Register user Wallet
export const registerWallet = createAsyncThunk('auth/registerWallet', async (user, thunkAPI) => {
	try {
		return await authService.registerWallet(user);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Login user Wallet
export const loginWallet = createAsyncThunk('auth/loginWallet', async (user, thunkAPI) => {
	try {
		return await authService.loginWallet(user);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Login user Wallet
export const loginWalletCApp = createAsyncThunk('auth/loginWalletCApp', async (user, thunkAPI) => {
	try {
		return await authService.loginWalletCApp(user);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Register user
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
	try {
		return await authService.register(user);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
	try {
		return await authService.login(user);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Fetch CApp Auth Request
export const getCAppAuthRequest = createAsyncThunk(
	'auth/getCAppAuthRequest',
	async (requestId, thunkAPI) => {
		try {
			return await authService.getCAppAuthRequest(requestId);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error));
		}
	}
);

// Login CApp
export const loginCApp = createAsyncThunk('auth/loginCApp', async (cappUrl, thunkAPI) => {
	try {
		return await authService.loginCApp(cappUrl);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Logout user
export const logout = createAsyncThunk('auth/logout', async () => {
	await authService.logout();
});

// Send verification email
export const sendVerificationEmail = createAsyncThunk(
	'auth/sendVerificationEmail',
	async (token, thunkAPI) => {
		try {
			return await authService.sendVerificationEmail(token);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error));
		}
	}
);

// Verify email
export const verifyEmail = createAsyncThunk('auth/verifyEmail', async (token, thunkAPI) => {
	try {
		return await authService.verifyEmail(token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Forgot Password
export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (data, thunkAPI) => {
	try {
		return await authService.forgotPassword(data);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Reset Password
export const resetPassword = createAsyncThunk('auth/resetPassword', async (data, thunkAPI) => {
	try {
		return await authService.resetPassword(data);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Update user profile
export const updateProfile = createAsyncThunk('auth/updateProfile', async (data, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await authService.updateProfile(data, token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Update user Roll
export const administrateUser = createAsyncThunk(
	'auth/administrateUser',
	async (data, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			return await authService.administrateUser(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error));
		}
	}
);

// Get Leaderboard
export const getLeaderBoard = createAsyncThunk('auth/getLeaderBoard', async (logData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await authService.getLeaderBoard(logData, token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Deactivate user
/*export const deactivate = createAsyncThunk('auth/deactivate', async (id, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await authService.deactivateUser(id, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(extractError(error))
	}
})*/

// Get user
export const getUser = createAsyncThunk('auth/getUser', async (_, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await authService.getUser(token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Get Users List Admin
export const getUsersListAdmin = createAsyncThunk(
	'auth/getUsersListAdmin',
	async (logData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			return await authService.getUsersListAdmin(logData, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error));
		}
	}
);

// Get User Stats
export const getUserStats = createAsyncThunk('auth/getUserStats', async (logData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await authService.getUserStats(logData, token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Get API Key
export const getApiKey = createAsyncThunk('auth/getApiKey', async (_, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await authService.getApiKey(token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Disable API Key Access
export const disableApiKeyAccess = createAsyncThunk(
	'auth/disableApiKeyAccess',
	async (_, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token;
			return await authService.disableApiKeyAccess(token);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error));
		}
	}
);

// New API Key
export const newApiKey = createAsyncThunk('auth/newApiKey', async (_, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token;
		return await authService.newApiKey(token);
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error));
	}
});

// Clear Logs
export const clearAuthLogs = createAsyncThunk('auth/clearLogs', async () => {
	authService.clearAuthLogs();
});

// Clear API Key
export const clearApiKey = createAsyncThunk('auth/clearApiKey', async () => {
	authService.clearApiKey();
});

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		resetAuth: (state) => {
			state.isLoadingAuth = false;
			state.isSuccessAuth = false;
			state.isErrorAuth = false;
			state.messageAuth = '';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(register.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(register.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.user = null;
			})
			.addCase(register.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
				state.user = null;
			})
			.addCase(login.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.user = action.payload.user;
				state.token = action.payload.token;
			})
			.addCase(login.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
				state.user = null;
			})
			.addCase(loginCApp.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(loginCApp.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.cappUrl = action.payload.url;
			})
			.addCase(loginCApp.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isFetchCAppRequestError = true;
				state.cAppFetchRequestErrorMessage = action.payload;
			})
			.addCase(getCAppAuthRequest.pending, (state) => {
				state.isLoadingAuth = true;
				state.startedFetchingCAppRequest = true;
			})
			.addCase(getCAppAuthRequest.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.cappAuthRequestData = action.payload.data;
			})
			.addCase(getCAppAuthRequest.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isFetchCAppRequestError = true;
				state.cAppFetchRequestErrorMessage = action.payload;
			})
			.addCase(logout.rejected, (state) => {
				state.isSuccessAuth = false;
				state.user = null;
			})
			.addCase(logout.pending, (state) => {
				state.isLoadingAuth = true;
				state.isSuccessAuth = false;
				state.user = null;
			})
			.addCase(logout.fulfilled, (state) => {
				state.isSuccessAuth = false;
				state.user = null;
			})
			/*.addCase(deactivate.pending, state => {
				state.isLoadingAuth = true
			})
			.addCase(deactivate.fulfilled, (state, action) => {
				state.isLoadingAuth = false
				state.isSuccessAuth = true
				state.user = action.payload
			})
			.addCase(deactivate.rejected, (state, action) => {
				state.isLoadingAuth = false
				state.isErrorAuth = true
				state.messageAuth = action.payload
				state.user = null
			})*/
			.addCase(registerWallet.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(registerWallet.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.user = action.payload.user;
				state.token = action.payload.token;
			})
			.addCase(registerWallet.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
				state.user = null;
			})
			.addCase(loginWallet.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(loginWallet.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.user = action.payload.user;
				state.token = action.payload.token;
			})
			.addCase(loginWallet.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
				state.user = null;
			})
			.addCase(loginWalletCApp.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(loginWalletCApp.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.cappUrl = action.payload.url;
			})
			.addCase(loginWalletCApp.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(initTransfer.fulfilled, (state, action) => {
				state.user = action.payload.senderUser;
			})
			.addCase(withdraw.fulfilled, (state, action) => {
				state.user = action.payload.user;
			})
			.addCase(deposit.fulfilled, (state, action) => {
				state.user = action.payload.user;
			})
			.addCase(sendVerificationEmail.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(sendVerificationEmail.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.messageAuth = action.payload.message;
			})
			.addCase(sendVerificationEmail.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(verifyEmail.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(verifyEmail.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.messageAuth = action.payload.message;
				state.user = null;
				state.token = null;
			})
			.addCase(verifyEmail.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(resetPassword.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(resetPassword.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.messageAuth = action.payload.message;
			})
			.addCase(resetPassword.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(updateProfile.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(updateProfile.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.user = action.payload.user;
				state.messageAuth = 'Your account has been updated';
			})
			.addCase(updateProfile.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(administrateUser.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(administrateUser.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.messageAuth = action.payload.message;
			})
			.addCase(administrateUser.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(getLeaderBoard.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(getLeaderBoard.fulfilled, (state, action) => {
				state.leaderslist = action.payload;
			})
			.addCase(getLeaderBoard.rejected, (state, action) => {
				state.messageAuth = action.payload;
			})
			.addCase(getUser.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(getUser.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.user = action.payload.user;
				state.messageAuth = 'Your account has been updated';
			})
			.addCase(getUser.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
				state.user = null;
			})
			.addCase(getUsersListAdmin.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(getUsersListAdmin.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.logs = action.payload.users;
				state.messageAuth = action.payload.message;
			})
			.addCase(getUsersListAdmin.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(getUserStats.pending, (state) => {
				state.isLoadingAuth = true;
			})
			.addCase(getUserStats.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.userStats = action.payload.stats;
				state.messageAuth = action.payload.message;
			})
			.addCase(getUserStats.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(getApiKey.pending, (state, action) => {
				state.isLoadingAuth = true;
			})
			.addCase(getApiKey.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.messageAuth = action.payload.message;
				state.apiKey = action.payload.apiKey;
			})
			.addCase(getApiKey.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(disableApiKeyAccess.pending, (state, action) => {
				state.isLoadingAuth = true;
			})
			.addCase(disableApiKeyAccess.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.messageAuth = action.payload.message;
				state.apiKey = null;
			})
			.addCase(disableApiKeyAccess.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(newApiKey.pending, (state, action) => {
				state.isLoadingAuth = true;
			})
			.addCase(newApiKey.fulfilled, (state, action) => {
				state.isLoadingAuth = false;
				state.isSuccessAuth = true;
				state.messageAuth = action.payload.message;
				state.apiKey = action.payload.apiKey;
			})
			.addCase(newApiKey.rejected, (state, action) => {
				state.isLoadingAuth = false;
				state.isErrorAuth = true;
				state.messageAuth = action.payload;
			})
			.addCase(clearAuthLogs.pending, (state) => {
				state.logs = null;
			})
			.addCase(clearAuthLogs.fulfilled, (state) => {
				state.logs = null;
			})
			.addCase(clearAuthLogs.rejected, (state) => {
				state.logs = null;
			})
			.addCase(clearApiKey.pending, (state) => {
				state.apiKey = null;
			})
			.addCase(clearApiKey.fulfilled, (state) => {
				state.apiKey = null;
			})
			.addCase(clearApiKey.rejected, (state) => {
				state.apiKey = null;
			});
	},
});

export const { resetAuth } = authSlice.actions;
export default authSlice.reducer;
