import { useSelector } from 'react-redux'

import Modal from '../../builders/modal'
import DeleteTradingPair from './DeleteTradingPair'
import ModifyTradingPair from './ModifyTradingPair'
import ViewOrders from './ViewOrders'

function ViewTradingPairs() {
	const { tradingPairs } = useSelector(state => state.cex)
	const { currencies } = useSelector(state => state.bank)

	return (
		<div className='transactions_grid'>
			<div className='h4_2'>Trading Pairs</div>
			<div className='tg_head4'>
				<div>Name</div>
				<div>Buy Currency</div>
				<div>Sale Currency</div>
			</div>

			{tradingPairs &&
				tradingPairs.map(tradingPair => (
					<div key={tradingPair._id} className='tg_list4'>
						{tradingPair.name}
						<div>{currencies.find(currency => currency.currencyId === tradingPair.buyCurrency).name}</div>
						<div>{currencies.find(currency => currency.currencyId === tradingPair.sellCurrency).name}</div>
						<Modal
							header={tradingPair.name + ' Modify'}
							content={
								<ModifyTradingPair
									sellCurrency={tradingPair.sellCurrency}
									buyCurrency={tradingPair.buyCurrency}
									pairId={tradingPair._id}
									frozen={tradingPair.frozen}
									frozenNote={tradingPair.frozenNote}
									canBuy={tradingPair.canBuy}
									canSell={tradingPair.canSell}
									fee={tradingPair.fee}
									feeCollector={tradingPair.feeCollector}
								/>
							}
							btnTextorIcon='Modify'
							modalCloseBtnText='Close'
							btnclass='signin'
						/>
						<DeleteTradingPair _id={tradingPair._id} />
						<Modal
							header={tradingPair.name + ' Orders'}
							content={<ViewOrders pair={tradingPair._id} name={tradingPair.name} buySide={false} />}
							btnTextorIcon='View Orders'
							modalCloseBtnText='Close'
							btnclass='signin'
						/>
					</div>
				))}
		</div>
	)
}

export default ViewTradingPairs
