import { useEffect } from 'react';
import CurrencyStatsList from '../../components/adminservices/currency/CurrencyStatsList';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencies, getCurrencyStats } from '../../features/bank/bankSlice';

function Analytics() {
	const dispatch = useDispatch();
	const currencies = useSelector(state => state.bank.currencies);

	useEffect(() => {
		if (!currencies) {
			dispatch(getCurrencies());
		}
		dispatch(getCurrencyStats());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='analytics_container'>
			<header>Currency Analytics</header>
			<CurrencyStatsList />
		</div>
	);
}

export default Analytics;
