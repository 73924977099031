import { Link } from 'react-router-dom'

function MenuBarRight() {
	return (
		<div className='menubarright'>
			<h1>⠀</h1>
			<div className='menu'>
				<p className='glow'>
					<Link to='/dashboard'>Account</Link>
				</p>
			</div>
			<div className='menu'>
				<p className='glow'>
					<a href='https://discord.gg/d7YxABCcR2' target='blank'>
						Discord
					</a>
				</p>
			</div>

			<div className='menu'>
				<p className='glow'>
					<Link to='/leaderboard'>Leader Board</Link>
				</p>
			</div>
			<div className='menu'>
				<p className='glow'>
					<Link to='/Faq'>FaQ</Link>
				</p>
			</div>
			<div className='menu'>
				<p className='glow'>
					<Link to='/checkout'>Buy Credits</Link>
				</p>
			</div>
			<div className='menu'>
				<p className='glow'>
					<Link to='/synthchips'>Synth Chips</Link>
				</p>
			</div>
		</div>
	)
}

export default MenuBarRight
