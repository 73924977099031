import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrders } from '../../../features/cex/cexSlice'

import States from '../../../util/orderStates.json'
import PlaceOrder from './PlaceOrder'
import PayoutOrder from './PayoutOrder'

function ViewOrder(props) {
	const { orders } = useSelector(state => state.cex)
	const dispatch = useDispatch()

	const date = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timezone: 'UTC',
	}

	useEffect(() => {
		const orderData = {
			pair: props.pair,
		}

		dispatch(getOrders(orderData))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	// get the buys form orders.buys
	const buys = orders && orders.buys
	// get the sells form orders.sells
	const sells = orders && orders.sells

	return (
		<div className='transactions_grid'>
			<div className='tg_header'>
				<div>Created At</div>
				<div>Initial Amount</div>
				<div>Filled Amount</div>
				<div>Price</div>
				<div>State</div>
				<div></div>
			</div>
			{/* if orders is not and empty array display map if empty display 'No orders Found'*/}
			<div>Buys</div>
			{buys && buys.length > 0 ? (
				buys.map(order => (
					<div className='tg_listing' key={order._id}>
						<div>{new Date(order.createdAt).toLocaleDateString('en-GB', date)}</div>
						<div>{order.initialAmount}</div>
						<div>{order.filledAmount}</div>
						<div>{order.price}</div>
						<div>{order.state ? States.type.find(type => type.id === order.state).name : ''}</div>
					</div>
				))
			) : (
				<div className='tg_listing'>No orders Found</div>
			)}
			<div>Sells</div>
			{sells && sells.length > 0 ? (
				sells.map(order => (
					<div className='tg_listing' key={order._id}>
						<div>{new Date(order.createdAt).toLocaleDateString('en-GB', date)}</div>
						<div>{order.initialAmount}</div>
						<div>{order.filledAmount}</div>
						<div>{order.price}</div>
						<div>{order.state ? States.type.find(type => type.id === order.state).name : ''}</div>
						<PayoutOrder orderId={order._id} />
					</div>
				))
			) : (
				<div className='tg_listing'>No orders Found</div>
			)}

			<PlaceOrder buySide={props.buySide} pair={props.pair} name={props.name} />
		</div>
	)
}

export default ViewOrder
