import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modifyForgeRecipe, resetForge } from '../../../features/forge/forgeSlice'
import Spinner from '../../Spinner'

function ModifyRecipe(props) {
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		imgUrl: '',
		timeRequired: '',
		currencyIdIn: '',
		amountIn: '',
		currencyIdOut: '',
		amountOut: '',
	})

	const { name, description, imgUrl, timeRequired, currencyIdIn, amountIn, currencyIdOut, amountOut } =
		formData
	const { currencies } = useSelector(state => state.bank)
	const { isLoadingForge } = useSelector(state => state.forge)
	const { recipes } = useSelector(state => state.forge)
	const recipe = recipes.find(recipe => recipe._id === props.recipeId)

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const recipeData = {
			recipeId: recipe._id,
			name: name ? name : '',
			description: description ? description : '',
			imgUrl: imgUrl ? imgUrl : '',
			timeRequired: timeRequired ? timeRequired * 1 : '',
			input:
				currencyIdIn || amountIn
					? {
							currencyId: currencyIdIn ? currencyIdIn * 1 : '',
							amount: amountIn ? amountIn * 1 : '',
					  }
					: '',
			output:
				currencyIdOut || amountOut
					? {
							currencyId: currencyIdOut ? currencyIdOut * 1 : '',
							amount: amountOut ? amountOut * 1 : '',
					  }
					: '',
		}

		Object.keys(recipeData.input).forEach(key => recipeData.input[key] === '' && delete recipeData.input[key])
		Object.keys(recipeData.output).forEach(
			key => recipeData.output[key] === '' && delete recipeData.output[key],
		)
		Object.keys(recipeData).forEach(key => {
			if (recipeData[key] === '' || recipeData[key].length === 0) {
				delete recipeData[key]
			}
		})

		dispatch(modifyForgeRecipe(recipeData))
		dispatch(resetForge())
	}

	if (isLoadingForge) {
		return <Spinner />
	}

	return (
		<div>
			<form onSubmit={onSubmit}>
				<div className='wrap2'>
					<div className='f1_2'>Name: </div>
					<input
						type='text'
						className='form-input3'
						placeholder={recipe.name}
						name='name'
						value={name}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Description: </div>
					<input
						type='text'
						className='form-input3'
						placeholder={recipe.description}
						name='description'
						value={description}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Image URL: </div>
					<input
						type='text'
						className='form-input3'
						placeholder={recipe.imgUrl}
						name='imgUrl'
						value={imgUrl}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Time Required: </div>
					<input
						type='text'
						className='form-input3'
						placeholder={recipe.timeRequired}
						name='timeRequired'
						value={timeRequired}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Currency Input: </div>
					<select
						className='form-input3'
						id='currencyIdIn'
						name='currencyIdIn'
						value={currencyIdIn}
						onChange={onChange}
					>
						<option value=''>{currencies.find(c => c.currencyId === recipe.input.currencyId).ticker}</option>
						{currencies.map(currencies => (
							<option key={currencies.currencyId} value={currencies.currencyId}>
								{currencies.name}
							</option>
						))}
					</select>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Amount In: </div>
					<input
						type='text'
						className='form-input3'
						placeholder={recipe.input.amount}
						name='amountIn'
						value={amountIn}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Currency Output: </div>
					<select
						className='form-input3'
						id='currencyIdOut'
						name='currencyIdOut'
						value={currencyIdOut}
						onChange={onChange}
					>
						<option value=''>{currencies.find(c => c.currencyId === recipe.output.currencyId).ticker}</option>
						{currencies.map(currencies => (
							<option key={currencies.currencyId} value={currencies.currencyId}>
								{currencies.name}
							</option>
						))}
					</select>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Amount Out: </div>
					<input
						type='text'
						className='form-input3'
						placeholder={recipe.output.amount}
						name='amountOut'
						value={amountOut}
						onChange={onChange}
					/>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin'>
						Modify
					</button>
				</div>
			</form>
		</div>
	)
}

export default ModifyRecipe
