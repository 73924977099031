import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAccessLogs } from '../../../features/audit/auditSlice'

import Categories from '../../../util/accessLogTypes'
import UserAccessLogs from './UserAccessLogs'

function UserAccessSort(props) {
	const [formData, setFormData] = useState({
		recLimit: '',
		pageNum: '',
		user: '',
		startDate: '',
		endDate: '',
		category: '',
		address: '',
	})

	const pageNum = props.pageNum

	const { recLimit, user, startDate, endDate, category, address } = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const logData = {
			paging: {
				limit: recLimit ? recLimit * 1 : 10,
				page: pageNum * 1,
			},
			user: user ? user : '',
			startDate: startDate ? startDate : '',
			endDate: endDate ? endDate : '',
			category: category ? category : '',
			address: address ? address : '',
		}

		Object.keys(logData).forEach(field => {
			if (logData[field] === '') {
				delete logData[field]
			}
		})

		dispatch(getAccessLogs(logData))
	}

	return (
		<div className='centraliser'>
			<form onSubmit={onSubmit}>
				<div className='tsa_unit'>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Record Limit: </div>
							<select className='form-input3 fi_mini' name='recLimit' value={recLimit} onChange={onChange}>
								<option value='10'>10</option>
								<option value='20'>20</option>
								<option value='50'>50</option>
							</select>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>Nickname: </div>
							<input
								type='text'
								className='form-input3 fi_mini'
								id='user'
								name='user'
								value={user}
								placeholder='user'
								onChange={onChange}
							/>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Start Date: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='startDate'
								name='startDate'
								value={startDate}
								placeholder='start date'
								onChange={onChange}
							/>
						</div>
						<div className='wrap2'>
							<div className='f1_2'>End Date: </div>
							<input
								type='date'
								className='form-input3 fi_mini'
								id='endDate'
								name='endDate'
								value={endDate}
								placeholder='end date'
								onChange={onChange}
							/>
						</div>
					</div>
					<div>
						<div className='wrap2'>
							<div className='f1_2'>Category: </div>
							<select
								className='form-input3 fi_mini'
								id='category'
								name='category'
								value={category}
								onChange={onChange}
							>
								<option value=''>All</option>
								{/* get category list from import transactions JSON file */}
								{Categories.type.map(data => (
									<option key={data.id} value={data.id}>
										{data.name}
									</option>
								))}
							</select>
						</div>

						<div className='wrap2'>
							<div className='f1_2'>Address: </div>
							<input
								type='text'
								className='form-input3 fi_mini'
								id='address'
								name='address'
								value={address}
								placeholder='address'
								onChange={onChange}
							/>
						</div>
					</div>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin' onClick={() => props.changePageNum(1)}>
						Search
					</button>
				</div>
				<div className='centraliser c_vmargin'>
					<button
						type='submit'
						className='signin signinmini'
						name='previous'
						onClick={() => {
							if (pageNum === 2) {
								props.changePageNum(1)
							} else if (pageNum > 2) {
								props.changePageNum(pageNum - 1)
							}
						}}
					>
						{pageNum === 1 ? '⭮' : '«'}
					</button>
					<span>Page : {pageNum} </span>
					<button
						type='submit'
						className='signin signinmini'
						name='next'
						onClick={() => props.changePageNum(pageNum + 1)}
					>
						»
					</button>
				</div>
				<UserAccessLogs />
				<div className='centraliser c_vmargin'>
					<button
						type='submit'
						className='signin signinmini'
						name='previous'
						onClick={() => {
							if (pageNum === 2) {
								props.changePageNum(1)
							} else if (pageNum > 2) {
								props.changePageNum(pageNum - 1)
							}
						}}
					>
						{pageNum === 1 ? '⭮' : '«'}
					</button>
					<span>Page : {pageNum} </span>
					<button
						type='submit'
						className='signin signinmini'
						name='next'
						onClick={() => props.changePageNum(pageNum + 1)}
					>
						»
					</button>
				</div>
			</form>
		</div>
	)
}

export default UserAccessSort
