function UnderDev() {
	return (
		<div>
			<div className='DevNotice'>
				<h5 className='h5'>Under Development</h5>
				<p className='notice'>
					This page is currently under development. <br></br>
					Keep an eye on our social media or discord. <br></br>
					Once this page is ready, an announcement <br></br> will be made.
				</p>
				<h5 className='h5'>Under Development</h5>
			</div>
		</div>
	)
}

export default UnderDev
