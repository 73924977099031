import Transfer from './p2pTransfer'
// import Withdraw from './withdraw'
// import Deposit from './deposit'
import Modal from './modal'
import { useSelector } from 'react-redux'
// import UnderDev from '../../pages/UnderDev'

function CurrencyArray() {
	const { user } = useSelector(state => state.auth)
	const { currencies } = useSelector(state => state.bank)

	function Amount(currencyId) {
		return user && user.balances.find(x => x.currencyId === currencyId)
			? user.balances.find(x => x.currencyId === currencyId).amount
			: 0
	}

	function TransferBtns({ ticker, name, currencyId, canTransfer }) {
		if (canTransfer) {
			return (
				<div className='b3'>
					<Modal
						header={`Transfer ${ticker}`}
						content={<Transfer currency={ticker} Id={currencyId} name={name} />}
						btnTextorIcon={`Transfer ${ticker}`}
						btnclass='signin'
					/>
				</div>
			)
		} else {
			return <div className='b3'>{''}</div>
		}
	}

	// function WithdrawBtns({ ticker, name, currencyId, canWithdraw }) {
	// 	if (canWithdraw) {
	// 		return (
	// 			<div className='b3'>
	// 				<Modal
	// 					// header={`Withdraw ${ticker}`}
	// 					// content={<Withdraw currency={ticker} Id={currencyId} name={name} />}
	// 					content={<UnderDev />}
	// 					btnTextorIcon={`Withdraw ${ticker}`}
	// 					btnclass='signin'
	// 				/>
	// 			</div>
	// 		)
	// 	} else {
	// 		return <div className='b3'></div>
	// 	}
	// }

	// function DepositBtns({ ticker, name, currencyId, canDeposit }) {
	// 	if (canDeposit) {
	// 		return (
	// 			<div className='b3'>
	// 				<Modal
	// 					// header={`Deposit ${ticker}`}
	// 					// content={<Deposit currency={ticker} Id={currencyId} name={name} />}
	// 					content={<UnderDev />}
	// 					btnTextorIcon={`Deposit ${ticker}`}
	// 					btnclass='signin'
	// 				/>
	// 			</div>
	// 		)
	// 	} else {
	// 		return <div className='b3'></div>
	// 	}
	// }

	if (currencies) {
		const filteredCurrencies = currencies.filter(currency =>
			// if the currency has a balance of 0, don't show it
			{
				return (
					Amount(currency.currencyId) > 0 &&
					(currency.canTransfer || currency.canWithdraw || currency.canDeposit)
				)
			},
		)
		if (filteredCurrencies.length > 0) {
			return (
				<>
					{filteredCurrencies.map((data, currencyId) => (
						<div key={currencyId}>
							<div className='bankgrid'>
								<div className='b1'>
									<div className='cu_icons'>
										<img
											alt='token'
											src={
												data.imgUrl
													? data.imgUrl
													: 'https://dl.dropboxusercontent.com/s/s2oyo7dzqi8vsyr/default.gif?raw=1'
											}
										/>
										{`${data.name} ( ${data.ticker} )`} :{' '}
									</div>
								</div>
								<div className='b2'>
									{/* run the Amount function with the currencyId */}
									{Amount(data.currencyId)}
								</div>
							</div>
							<div className='bankbuttongrid'>
								<div className='b3'>
									<TransferBtns {...data} />
								</div>
								{/* <div className='b3'>
									<WithdrawBtns {...data} />
								</div>
								<div className='b3'>
									<DepositBtns {...data} />
								</div> */}
							</div>
						</div>
					))}
				</>
			)
		} else {
			return <div className='notice2'>You have no tokens.</div>
		}
	} else {
		return <div className='notice2'>No currencies loaded, please refresh page.</div>
	}
}

export default CurrencyArray
