import './Roadmap.css';

function Roadmap() {
	return (
		<div className='container'>
			<div className='timeline'>
				<ul>
					<li>
						<div className='timeline-content'>
							<h1 className='h6'>2022 Summary</h1>
							★．Website Started January 2022 <p className='checkmark'>&#10004;</p> <br></br>
							★．SD 2.0 Launched on BSC <p className='checkmark'>&#10004;</p> <br></br>
							★．The Bank of Starlight was created <p className='checkmark'>&#10004;</p> <br></br>
							★．Full BoS admin suite featuring : <br></br>
							<div className='rm_paragraph'>
								. currency controls <p className='checkmark'>&#10004;</p> <br></br>. detailed transaction
								records <p className='checkmark'>&#10004;</p> <br></br>. access logs{' '}
								<p className='checkmark'>&#10004;</p> <br></br>. user/checkout & forge controls{' '}
								<p className='checkmark'>&#10004;</p> <br></br>. Defcon security tiers{' '}
								<p className='checkmark'>&#10004;</p> <br></br>
							</div>
							★．Gamer BoS Suite :
							<div className='rm_paragraph'>
								. Email, Gmail & wallet regisration/login options <p className='checkmark'>&#10004;</p>{' '}
								<br></br>. Starter user profiles <p className='checkmark'>&#10004;</p> <br></br>. Currency
								Storage, p2p transfers & distribution transfers <p className='checkmark'>&#10004;</p>{' '}
								<br></br>. Personal activity records <p className='checkmark'>&#10004;</p> <br></br>. Forge
								Access <p className='checkmark'>&#10004;</p> <br></br>
							</div>
							★．Leaderboards implemented <p className='checkmark'>&#10004;</p> <br></br>
							★．Media Embeds <p className='checkmark'>&#10004;</p> <br></br>
							★．FAQ created <p className='checkmark'>&#10004;</p> <br></br>
							★．Integrated with Coinbase Commerce to enable automated Credit purchases{' '}
							<p className='checkmark'>&#10004;</p> <br></br>
							★．Home page now features quick overview, project team members & Roadmap{' '}
							<p className='checkmark'>&#10004;</p> <br></br>
							★．Access Cards designed <p className='checkmark'>&#10004;</p> <br></br>
						</div>
					</li>
					<li>
						<div className='timeline-content'>
							<h1 className='h6'>Q1 2023</h1>
							★．Spaceships Card and Synth Chip Packs designed and announced{' '}
							<p className='checkmark'>&#10004;</p> <br></br>
							★．Synth Chip sales panel created & sale begins <p className='checkmark'>&#10004;</p> <br></br>
						</div>
					</li>
					<li>
						<div className='timeline-content'>
							<h1 className='h6'>Q2 2023</h1>
							★．In house Cex created <p className='checkmark'>&#10004;</p> <br></br>
							★．Game Development begins <p className='checkmark'>&#10004;</p> <br></br>
						</div>
					</li>
					<li>
						<div className='timeline-content'>
							<h1 className='h6'>Q3 2023</h1>
							★．Alpha Synth Chips Open <p className='checkmark'>&#10004;</p> <br></br>
							★．BoS Game Items Implementation <p className='checkmark'>&#10004;</p> <br></br>
							<div className='rm_paragraph'>
								. View your game items in your BoS account ( Ships gained from opening Synth Chips )<br></br>
							</div>
							★．Fiat Gateway <p className='checkmark'>&#10004;</p> <br></br>
							★．Game Development Continues <p className='checkmark'>&#10004;</p> <br></br>
							★．Lore for Orion's Reach starts to release <p className='checkmark'>&#10004;</p> <br></br>
						</div>
					</li>
					<li>
						<div className='timeline-content'>
							<h1 className='h6'>Soon</h1>
							★．Game Launch with Salvage Missions <p className='checkmark'>&#10004;</p> <br></br>
							★．Resetable Seasonal Leaderboards <p className='checkmark'>&#10004;</p> <br></br>
							★．Game Resource storage within BoS<br></br>
							★．Marketplace for Game Items and Resources<br></br>
							★．Game Development Continues<br></br>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default Roadmap;
