import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modifyTradingPair } from '../../../features/cex/cexSlice'
import Spinner from '../../Spinner'

function ModifyTradingPair(props) {
	const [formData, setFormData] = useState({
		frozen: '',
		frozenNote: '',
		canBuy: '',
		canSell: '',
		fee: '',
		feeCollector: '',
	})

	const { frozen, frozenNote, canBuy, canSell, fee, feeCollector } = formData
	const { isLoadingCex } = useSelector(state => state.cex)
	const { currencies } = useSelector(state => state.bank)

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const tradingPairData = {
			name: props
				? `${currencies.find(currency => currency.currencyId === props.buyCurrency * 1).name} -> 
      ${currencies.find(currency => currency.currencyId === props.sellCurrency * 1).name}`
				: '',
			frozen: frozen,
			frozenNote,
			pairId: props.pairId,
			canBuy: canBuy,
			canSell: canSell,
			fee: fee * 0.01,
			feeCollector,
		}

		dispatch(modifyTradingPair(tradingPairData))
	}

	if (isLoadingCex) {
		return <Spinner />
	}

	return (
		<div>
			<form onSubmit={onSubmit}>
				<div className='wrap2'>
					<div className='f1_2'>Name: </div>
					<div className='f1_22'>
						{props.buyCurrency > 0 &&
							currencies.find(currency => currency.currencyId === props.buyCurrency * 1).name + ' -> '}
						{props.sellCurrency > 0 &&
							currencies.find(currency => currency.currencyId === props.sellCurrency * 1).name}
					</div>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Frozen: </div>
					<select className='form-input2' id='frozen' name='frozen' onChange={onChange}>
						{props.frozen && props.frozen === true ? (
							<>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</>
						) : (
							<>
								<option value={false}>No</option>
								<option value={true}>Yes</option>
							</>
						)}
					</select>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Frozen Note: </div>
					<input
						className='form-input3'
						name='frozenNote'
						value={frozenNote}
						onChange={onChange}
						placeholder={props.frozenNote}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Can Buy: </div>
					<select className='form-input2' id='canBuy' name='canBuy' onChange={onChange}>
						{props.canBuy && props.canBuy === true ? (
							<>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</>
						) : (
							<>
								<option value={false}>No</option>
								<option value={true}>Yes</option>
							</>
						)}
					</select>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Can Sell: </div>
					<select className='form-input2' id='canSell' name='canSell' onChange={onChange}>
						{props.canSell && props.canSell === true ? (
							<>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</>
						) : (
							<>
								<option value={false}>No</option>
								<option value={true}>Yes</option>
							</>
						)}
					</select>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Fee %: </div>
					<input
						className='form-input3'
						type='number'
						name='fee'
						value={fee}
						onChange={onChange}
						placeholder={props.fee * 100}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Fee Collector: </div>
					<input
						className='form-input3'
						type='text'
						name='feeCollector'
						value={feeCollector}
						onChange={onChange}
						placeholder={props.feeCollector}
					/>
				</div>
				<div>
					<button type='submit' className='signin' value='Create'>
						{' '}
						Modify
					</button>
				</div>
			</form>
		</div>
	)
}

export default ModifyTradingPair
