import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCurrency, getCurrencies } from '../../../features/bank/bankSlice'

import Spinner from '../../Spinner'

function CreateCurrency() {
	const [formData, setFormData] = useState({
		ticker: '',
		precision: '',
		name: '',
		canDeposit: '',
		canWithdraw: '',
		canTransfer: '',
		depositTax: '',
		withdrawTax: '',
		transferTax: '',
		isFrozen: '',
		frozenNote: '',
		taxCollector: '',
		imgUrl: '',
	})

	const {
		ticker,
		precision,
		name,
		canDeposit,
		canWithdraw,
		canTransfer,
		depositTax,
		withdrawTax,
		transferTax,
		isFrozen,
		frozenNote,
		taxCollector,
		imgUrl,
	} = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const { isLoadingBank, isSuccessBank } = useSelector(state => state.bank)

	useEffect(() => {
		if (isSuccessBank) {
			dispatch(getCurrencies())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessBank])

	const onSubmit = e => {
		e.preventDefault()

		const currencyData = {
			ticker,
			precision: precision * 1,
			name,
			canDeposit: canDeposit === 'on' ? true : false,
			canWithdraw: canWithdraw === 'on' ? true : false,
			canTransfer: canTransfer === 'on' ? true : false,
			depositTax: depositTax * 0.01,
			withdrawTax: withdrawTax * 0.01,
			transferTax: transferTax * 0.01,
			isFrozen: isFrozen === 'on' ? true : false,
			frozenNote: frozenNote ? frozenNote : '',
			taxCollector: taxCollector ? taxCollector : '',
			imgUrl: imgUrl ? imgUrl : '',
		}

		Object.keys(currencyData).forEach(key => {
			if (currencyData[key] === '') {
				delete currencyData[key]
			}
		})

		dispatch(createCurrency(currencyData))
	}

	if (isLoadingBank) {
		return <Spinner />
	}

	return (
		<div>
			<form onSubmit={onSubmit}>
				<div className='wrap2'>
					<div className='f1_2'>Ticker: </div>
					<input
						type='text'
						className='form-input3'
						id='ticker'
						name='ticker'
						value={ticker}
						placeholder='Currency ticker'
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Name: </div>
					<input
						type='text'
						className='form-input3'
						id='name'
						name='name'
						value={name}
						placeholder='Name'
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Precision: </div>
					<input
						type='number'
						className='form-input3'
						id='precision'
						name='precision'
						value={precision}
						placeholder='Precision'
						onChange={onChange}
					/>
				</div>
				<div className='wrap3'>
					<div className='f1_2'>Deposit: </div>
					<input type='checkbox' className='checkbox' id='canDeposit' name='canDeposit' onChange={onChange} />
					<div className='f1_2'>Tax %: </div>
					<input
						type='number'
						className='form-input3'
						id='depositTax'
						name='depositTax'
						value={depositTax}
						placeholder='Deposit Tax'
						onChange={onChange}
					/>
				</div>
				<div className='wrap3'>
					<div className='f1_2'>Withdraw: </div>
					<input
						type='checkbox'
						className='checkbox'
						id='canWithdraw'
						name='canWithdraw'
						onChange={onChange}
					/>
					<div className='f1_2'>Tax %: </div>
					<input
						type='number'
						className='form-input3'
						id='withdrawTax'
						name='withdrawTax'
						value={withdrawTax}
						placeholder='Withdraw Tax'
						onChange={onChange}
					/>
				</div>
				<div className='wrap3'>
					<div className='f1_2'>Transfer: </div>
					<input
						type='checkbox'
						className='checkbox'
						id='canTransfer'
						name='canTransfer'
						onChange={onChange}
					/>
					<div className='f1_2'>Tax %: </div>
					<input
						type='number'
						className='form-input3'
						id='transferTax'
						name='transferTax'
						value={transferTax}
						placeholder='Transfer Tax'
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Frozen: </div>
					<input type='checkbox' className='checkbox' id='isFrozen' name='isFrozen' onChange={onChange} />
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Frozen Note: </div>
					<input
						type='text'
						className='form-input3'
						id='frozenNote'
						name='frozenNote'
						value={frozenNote}
						placeholder='note'
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Tax Collector: </div>
					<input
						type='text'
						className='form-input3'
						id='taxCollector'
						name='taxCollector'
						value={taxCollector}
						placeholder='Tax Collector'
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Image URL: </div>
					<input
						type='text'
						className='form-input3'
						id='imgUrl'
						name='imgUrl'
						value={imgUrl}
						placeholder='Image Url'
						onChange={onChange}
					/>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin'>
						Create
					</button>
				</div>
			</form>
		</div>
	)
}

export default CreateCurrency
