import { useState, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import './TeamProfile.css'

export default function App() {
	let scrl = useRef(null)
	const [scrollX, setscrollX] = useState(0)
	const [scrolEnd, setscrolEnd] = useState(false)

	const slide = shift => {
		scrl.current.scrollLeft += shift
		setscrollX(scrollX + shift)

		if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
			setscrolEnd(true)
		} else {
			setscrolEnd(false)
		}
	}

	const scrollCheck = () => {
		setscrollX(scrl.current.scrollLeft)
		if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
			setscrolEnd(true)
		} else {
			setscrolEnd(false)
		}
	}

	return (
		<div className='teamwrapper'>
			<div className='agliner'>
				{!isMobile && (
					<>
						{scrollX !== 0 && (
							<button className='btnprev' onLoad={() => slide(-350)} onClick={() => slide(-350)} />
						)}
					</>
				)}
				{/* if isMobile add flexdirection: column to css for ul */}
				<ul className={isMobile ? 'teammobile' : ''} ref={scrl} onScroll={scrollCheck}>
					<li>
						<div className='colored_card' id='0'>
							<div className='avatar'>
								<img alt='' src='https://dl.dropboxusercontent.com/s/xqkha0xg0osf9cw/_Star.png?raw=1' />
							</div>
							<div className='Name'>(Kyle Oosting) aka - StarChild</div>
							<div className='position' title='Chief Executive Officer'>
								CEO / Founder
							</div>
							<div className='quote'>
								Its complicated. I like to be professionally unprofessional, but professional. Throws the
								competition off.{' '}
							</div>
						</div>
					</li>
					<li>
						<div className='colored_card' id='1'>
							<div className='avatar2'>
								<img alt='' src='https://dl.dropboxusercontent.com/s/61mjatengst9hbm/_Lystashe.png?raw=1' />
							</div>
							<div className='Name'>(Charles Houge) aka - Lystashe</div>
							<div className='position' title='Chief Operating Officer'>
								COO / Co-Founder
							</div>
							<div className='quote'>
								I believe in the dream. The inevitable reality. I support the greatest team around,
								underground with the will and commitment to succeed.
							</div>
						</div>
					</li>
					<li>
						<div className='colored_card' id='2'>
							<div className='avatar'>
								<img alt='' src='https://dl.dropboxusercontent.com/s/2o3l728hadjttkf/_Nio.png?raw=1' />
							</div>
							<div className='Name' title='Likes fringe rocks'>
								(Paul Miller) aka - Niofox
							</div>
							<div className='position' title='Deputy Chief of Ops'>
								DCO
							</div>
							<div className='quote'>
								Hard work and persistence yields rewards. The pursuit of knowledge and understanding never
								returns null;
							</div>
						</div>
					</li>
					<li>
						<div className='colored_card' id='3'>
							<div className='avatar'>
								<img alt='' src='https://dl.dropboxusercontent.com/s/bspzo9v6lbibq1v/_Skylove.png?raw=1' />
							</div>
							<div className='Name'>(Chris Spoonts) aka - †SkyLove⁵¹²</div>
							<div className='position' title='Builder Architect Team Lead'>
								BATL Chief
							</div>
							<div className='quote'>
								Why buy it when you can build it or throw it away when you can fix it. Sometimes you have to
								tear something apart to build something great.
							</div>
						</div>
					</li>
					<li>
						<div className='colored_card' id='4'>
							<div className='avatar'>
								<img
									alt=''
									src='https://dl.dropboxusercontent.com/s/0g95bth963fjfz5/_Omega.png?raw=1'
									title='&#128525;'
								/>
							</div>
							<div className='Name'>(Dave Timmermans) aka - OmegaOne</div>
							<div className='position' title='Front-End Webflow Wizard'>
								FEWW
							</div>
							<div className='quote'>
								I was always told that there is no future in playing video games. Let's prove them wrong !
							</div>
						</div>
					</li>
				</ul>
				{!isMobile && <>{!scrolEnd && <button className='btnnext' onClick={() => slide(+350)} />}</>}
			</div>
		</div>
	)
}
