import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTradingPair, getOrders, resetCex } from '../../../features/cex/cexSlice'

function DeleteTradingPair(props) {
	const dispatch = useDispatch()
	const { messageCex } = useSelector(state => state.cex)

	const onSubmit = e => {
		e.preventDefault()

		const orderData = {
			pairId: props._id,
		}

		dispatch(deleteTradingPair(orderData))
	}

	useEffect(() => {
		if (messageCex === 'Sell order cancelled') {
			const orderData = {
				pair: props.pair,
			}

			dispatch(getOrders(orderData))
			dispatch(resetCex())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, messageCex])

	return (
		<div>
			<form onSubmit={onSubmit}>
				<div>
					<button type='submit' value='Delete' name='Delete' className='signin'>
						{' '}
						Delete
					</button>
				</div>
			</form>
		</div>
	)
}

export default DeleteTradingPair
