import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { placeOrder, getOrders } from '../../../features/cex/cexSlice'
import { removeEmptyKeys } from '../../../util/generic'

function PlaceOrder(props) {
	const [formData, setFormData] = useState({
		quantity: '',
		price: '',
	})

	const { quantity, price } = formData
	const { messageCex } = useSelector(state => state.cex)
	const dispatch = useDispatch()
	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	const onSubmit = e => {
		e.preventDefault()

		const orderData = removeEmptyKeys({
			pair: props.pair,
			buySide: props.buySide,
			initialAmount: quantity,
			price: price,
		})

		dispatch(placeOrder(orderData))
	}

	useEffect(() => {
		if (messageCex.includes('Placed a buy order of') || messageCex.includes('Placed a Sell Order of')) {
			const orderData = {
				pair: props.pair,
			}

			dispatch(getOrders(orderData))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messageCex])

	return (
		<div>
			<h1>Create Sell Order</h1>
			<form onSubmit={onSubmit}>
				<div className='wrap2'>
					<div className='f1_2'>Quantity: </div>
					<input
						className='form-input3'
						type='number'
						placeholder='Quantity'
						name='quantity'
						value={quantity}
						onChange={e => onChange(e)}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Price: </div>
					<input
						type='number'
						className='form-input3'
						placeholder='Price'
						name='price'
						value={price}
						onChange={e => onChange(e)}
					/>
				</div>
				<div className='tabs2'>
					<button type='submit' value='Create' className='signin'>
						Create
					</button>
				</div>
			</form>
		</div>
	)
}

export default PlaceOrder
