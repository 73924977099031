import { Link } from 'react-router-dom';

function MenuBarLeft() {
	return (
		<div className='menubar'>
			<h1>⠀</h1>
			<div className='menu'>
				{' '}
				<p className='glow'>
					<Link to='/'> Home </Link>
				</p>{' '}
			</div>
			<div className='menu'>
				{' '}
				<p className='glow'>
					<Link to='/underdev'> Rewards </Link>
				</p>{' '}
			</div>
			<div className='menu'>
				{' '}
				<p className='glow'>
					<Link to='/analytics'> Analytics </Link>
				</p>{' '}
			</div>
			<div className='menu'>
				{' '}
				<p className='glow'>
					<Link to='/media'> Media </Link>
				</p>{' '}
			</div>
			<div className='menu'>
				{' '}
				<p className='glow'>
					<Link to='/quantumhub'> Quantum Hub </Link>
				</p>{' '}
			</div>
			<div className='menu'>
				{' '}
				<p className='glow'>
					<Link to='/games'> Games </Link>
				</p>{' '}
			</div>
		</div>
	);
}

export default MenuBarLeft;
