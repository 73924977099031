import React from 'react'
import Roadmap from '../../components/roadmap/Roadmap'
import TeamProfile from '../../components/teamprofile/TeamProfile'
import './Home.css'

function Home() {
	return (
		<div className='maincontainer'>
			<div className='homegraphic2'>
				<img
					alt='starlightnation'
					src='https://dl.dropboxusercontent.com/s/rbb67ucj20zbgma/starlightnation.png?raw=1'
				/>
				<img alt='cloudcity' src='https://dl.dropboxusercontent.com/s/1s50gr4gz0f8uk1/cloudcity.gif?raw=1' />
				<img
					alt='builtinthecloud'
					src='https://dl.dropboxusercontent.com/s/uqeqnxfo11uijpc/builtinthecloud.png?raw=1'
				/>
			</div>
			<div className='adresses'>
				<a href='https://bscscan.com/token/0x198d70093d2d01dc79d6f6bcde9a66ca2b09374e' target='blank'>
					<button className='tokenadress'>SN Contract</button>
				</a>
				<a href='https://bscscan.com/token/0x7f48DedcF760d4FDD91638eae6f0a137895a8D3C' target='blank'>
					<button className='tokenadress'>SD Contract</button>
				</a>
			</div>

			<div className='dynamichomecontainer'>
				<h2 className='h2home'>About</h2>
				<p className='maincontent'>
					Starlight Nation is a p2e community that seeks to position itself as the world's first cloud
					country.<br></br>
					Through gamified investment & play, we will develop a utility centric currency and new economy
					bridging p2e and the real world.
				</p>
			</div>

			<div className='dynamichomecontainer'>
				<h2 className='h2home'>Play to Invest</h2>
				<p className='maincontent'>
					Earn Credits by gaming with us and our partnered guilds while contributing to the ecosystem.
					<br></br>
					Stardust is the sole p2e currency of our new world and the only coin that can purchase a stake in
					the nation.
				</p>
			</div>

			<div className='homegraphic'>
				<img
					alt='cargobaywire'
					src='https://dl.dropboxusercontent.com/s/xfnlc9rcjatlqbt/cargobaywire.png?raw=1'
				/>
			</div>

			<div className='dynamichomecontainer'>
				<h2 className='h2home'>Invest in the Best</h2>
				<p className='maincontent'>
					Tired of 'play to LOSE' games? Starlight Nation turns the tide by empowering gamers to unite as a
					collective rather than compete against each other.<br></br>
					No time? No problem. Investors can purchase Stardust from us if available or from the open market at
					any time.
				</p>
			</div>

			<div className='homegraphic'>
				<img alt='sdsnbos' src='https://dl.dropboxusercontent.com/s/bspq5v5ep0svh7u/sdsnbos.png?raw=1' />
			</div>

			<div className='dynamichomecontainer'>
				<h2 className='h2home'>Sound Money</h2>
				<p className='maincontent'>
					Sorry, no air coins here! <br></br>
					Stardust is treated as a liability cementing its value on our platform.<br></br>
					Starlight Nation (SN) is our rewards coin and is secured by a share of BoS Credit earnings at the
					discretion of the parent company.
				</p>
			</div>

			<div className='homegraphic3'>
				<img alt='SN' src='https://dl.dropboxusercontent.com/s/1155shmhx9s9sob/sngif.gif?raw=1' />
				<img alt='SD' src='https://dl.dropboxusercontent.com/s/y3sncaapyfaq3cw/sdgif.gif?raw=1' />
			</div>

			<div className='dynamichomecontainer'>
				<h2 className='h2home'>Changing the World</h2>
				<p className='maincontent'>
					We play many games as a community but we only invest in our ecosystem. <br></br>
					Starlight Nation will be a creditor nation and never run a deficit. <br></br>
					Our focus is on building a strong currency, community and the acquisition of real world property to
					bring it all together.
				</p>
			</div>

			<div className=''>
				<h2 className='h2home'>Meet the Team</h2>
				<div className='maincontent'>
					<div className=''>
						<TeamProfile />
					</div>
				</div>
			</div>

			<div className='dynamichomecontainer'>
				<h2 className='h2home'>Roadmap</h2>
				<div className='maincontent'>
					<div className='roadmapcontainer'>
						<Roadmap />
					</div>
				</div>
			</div>
			<div className='homefooter'>
				<header>Starlight Nation, Gaming for a better tomorrow</header>
			</div>
		</div>
	)
}

export default Home
