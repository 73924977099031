import axios from 'axios'

const API_URL_FORGE = '/api/forge/'

// Get Forge Recipes
const getForgeRecipes = async token => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.get(API_URL_FORGE + 'recipes/fetch', config)

	return response.data
}

// Create Forge Recipe
const createForgeRecipe = async (forgeRecipeData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_FORGE + 'recipes/create', forgeRecipeData, config)

	return response.data
}

// Delete Forge Recipe
const deleteForgeRecipe = async (forgeRecipeData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_FORGE + 'recipes/delete', forgeRecipeData, config)

	return response.data
}

// Modify Forge Recipe
const modifyForgeRecipe = async (forgeRecipeData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_FORGE + 'recipes/modify', forgeRecipeData, config)

	return response.data
}

// Set Active state of Forge Recipe
const setActiveForgeRecipe = async (forgeRecipeData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_FORGE + 'recipes/setactive', forgeRecipeData, config)

	return response.data
}

// Start Forge Process
const startForgeProcess = async (forgeProcessData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_FORGE + 'startProcess', forgeProcessData, config)

	return response.data
}

// End Forge Process
const endForgeProcess = async (forgeProcessData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_FORGE + 'endProcess', forgeProcessData, config)

	return response.data
}

// Get User Forge Processes
const getForgeProcesses = async (forgeProcessData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_FORGE + 'getForgeProcesses', forgeProcessData, config)

	return response.data
}

// Get All Forge Processes
const getForgeProcessesAll = async (forgeProcessData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL_FORGE + 'getForgeProcessesAll', forgeProcessData, config)

	return response.data
}

// Clear Logs
const clearForgeProcessLogs = () => {}

const forgeService = {
	getForgeRecipes,
	createForgeRecipe,
	deleteForgeRecipe,
	modifyForgeRecipe,
	setActiveForgeRecipe,
	startForgeProcess,
	endForgeProcess,
	getForgeProcesses,
	getForgeProcessesAll,
	clearForgeProcessLogs,
}

export default forgeService
