const Joi = require('joi')
const fields = require('../fields')

const forgeRecipeSchema = Joi.object({
    recipeId: fields.objectIdField.required().label('Recipe Id'),
    name: fields.nameField,
    description: fields.descriptionField,
    imgUrl: fields.urlField,
    timeRequired: fields.integerAboveZeroField,
    input: fields.balanceSchema,
    output: fields.balanceSchema,
    active: fields.boolField,
});

module.exports = forgeRecipeSchema;