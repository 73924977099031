const Joi = require('joi')
const fields = require('../fields')

const checkoutPackageItemsSchema = Joi.object({
    type: Joi.number().integer().min(1).max(2).required().label("Item Type"),
    amount: fields.currencyAmountField.min(1).required(),
});

const checkoutPackageSchema = Joi.object({
    name: Joi.string().min(3).max(127).required(),
    description: Joi.string().min(12).max(511).required(),
    imgUrl: fields.urlField,
    cost: fields.currencyAmountField.required().label("USD Cost"),
    currency: fields.currencyIdField.required(),
    amount: fields.currencyAmountField.required(),
    items: Joi.array().items(checkoutPackageItemsSchema),
});

module.exports = checkoutPackageSchema;