import axios from 'axios'
import { toast } from 'react-toastify'

const API_URL = '/api/user/'
const CAPP_API_URL = '/api/capp/'

// Get nonce
const getNonce = async userData => {
	const response = await axios.post(API_URL + 'getNoncedMessage', userData)
	if (response.data) {
		localStorage.setItem('messageToSign', JSON.stringify(response.data.messageToSign))
	}

	return response.data
}

// Register user Wallet
const registerWallet = async userData => {
	const response = await axios.post(API_URL + 'registerWallet', userData)
	if (response.data) {
		localStorage.setItem('user', JSON.stringify(response.data.user))
		localStorage.setItem('token', JSON.stringify(response.data.token))
	}

	return response.data
}

// Login user Wallet
const loginWallet = async userData => {
	const response = await axios.post(API_URL + 'loginWallet', userData)
	if (response.data) {
		localStorage.setItem('user', JSON.stringify(response.data.user))
		localStorage.setItem('token', JSON.stringify(response.data.token))
	}

	return response.data
}

// Login user Wallet
const loginWalletCApp = async userData => {
	const response = await axios.post(API_URL + 'loginWallet', userData)
	if (response.data) {
		localStorage.setItem('cappurl', JSON.stringify(response.data.url))
	}

	return response.data
}

// Register user
const register = async userData => {
	const response = await axios.post(API_URL + 'register', userData)
	if (response.data) {
		localStorage.setItem('user', JSON.stringify(response.data.user))
		localStorage.setItem('token', JSON.stringify(response.data.token))
	}

	return response.data
}

// Login user
const login = async userData => {
	const response = await axios.post(API_URL + 'login', userData)
	if (response.data) {
		localStorage.setItem('user', JSON.stringify(response.data.user))
		localStorage.setItem('token', JSON.stringify(response.data.token))
	}

	return response.data
}

// Login CApp
const loginCApp = async userData => {
	const response = await axios.post(API_URL + 'login', userData)
	return response.data
}

// Get CApp Auth Request
const getCAppAuthRequest = async requestId => {
	const response = await axios.post(CAPP_API_URL + 'getAuthRequest', requestId)

	return response.data
}

// Logout user
const logout = () => {
	localStorage.removeItem('user')
	localStorage.removeItem('token')
	localStorage.removeItem('tokenexpires')
	toast.success('You have been logged out')
}

//Request verification email be sent
const sendVerificationEmail = async data => {
	const response = await axios.post(API_URL + 'sendVerificationEmail', data.email, {
		headers: { Authorization: `Bearer ${data.token}` },
	})

	return response.data
}

//Submit email verification token
const verifyEmail = async data => {
	const response = await axios.post(API_URL + 'verifyEmail', data)
	return response.data
}

//Submit password reset token + new password
const forgotPassword = async data => {
	const response = await axios.post(API_URL + 'forgotPassword', data)
	return response.data
}

//Submit password reset token + new password
const resetPassword = async data => {
	const response = await axios.post(API_URL + 'resetPassword', data)
	return response.data
}

// Update user profile
const updateProfile = async (userData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL + 'updateProfile', userData, config)

	if (response.data) {
		localStorage.setItem('user', JSON.stringify(response.data.user))
	}

	return response.data
}

// Administrate User
const administrateUser = async (userData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.post(API_URL + 'administrateUser', userData, config)

	if (response.data) {
		localStorage.setItem('user', JSON.stringify(response.data.user))
	}

	return response.data
}

// Get Leaderboard
const getLeaderBoard = async (logData, token) => {
	let config
	const user = localStorage.getItem('user')
	if (user)
		config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	const response = await axios.post(API_URL + 'leaderboard', logData, config ? config : undefined)

	return response.data
}

// Get User

const getUser = async token => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.get(API_URL + 'me', config)

	if (response.data) {
		localStorage.setItem('user', JSON.stringify(response.data.user))
	}

	return response.data
}

// Get Users List Admin
const getUsersListAdmin = async (logData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL + 'getUsers', logData, config)

	return response.data
}

// Get Currency Stats
const getUserStats = async (logData, token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.post(API_URL + 'getUserStats', logData, config)

	return response.data
}

// Enable API Key Access
const getApiKey = async token => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const response = await axios.get(API_URL + 'getApiKey', config)

	return response.data
}

// Disable API Key Access
const disableApiKeyAccess = async token => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.get(API_URL + 'disableApiKeyAccess', config)

	return response.data
}

// Change API Key
const newApiKey = async token => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	const response = await axios.get(API_URL + 'newApiKey', config)

	return response.data
}

// Clear Logs
const clearAuthLogs = () => { }

// Clear API Key
const clearApiKey = () => { }

const authService = {
	register,
	login,
	loginCApp,
	getCAppAuthRequest,
	logout,
	getNonce,
	registerWallet,
	loginWallet,
	loginWalletCApp,
	sendVerificationEmail,
	verifyEmail,
	forgotPassword,
	resetPassword,
	updateProfile,
	administrateUser,
	getLeaderBoard,
	getUser,
	getUsersListAdmin,
	getUserStats,
	clearAuthLogs,
	getApiKey,
	disableApiKeyAccess,
	newApiKey,
	clearApiKey,
}

export default authService
