function LightPaper() {
	return (
		<div>
			<iframe
				title='Q12022'
				className='iframe'
				src='https://docs.google.com/viewer?srcid=1LIZL2a6JVdALg0fJmrZu_429LTqIBFuJs2tFkFiCsbI&pid=explorer&efh=false&a=v&chrome=false&embedded=true'
			></iframe>
		</div>
	)
}

export default LightPaper
