import BuyOrders from '../../components/userservices/cex/BuyOrders'
import CreateOrder from '../../components/userservices/cex/CreateOrder'
import SellOrders from '../../components/userservices/cex/SellOrders'
import MyOrders from '../../components/userservices/cex/MyOrders'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getCurrencies } from '../../features/bank/bankSlice'
import { clearCexLogs, getOrders, getPairs, getUserOrders, resetCex } from '../../features/cex/cexSlice'
import { toast } from 'react-toastify'
import Spinner from '../../components/Spinner'
import { logout } from '../../features/auth/authSlice'
import { SiAtom } from 'react-icons/si'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import './QuantumHub.css'

function CexLayout() {
	const [pair, setPair] = useState()
	const [selectedPair, setSelectedPair] = useState()

	const { user } = useSelector(state => state.auth)
	const { messageCex, tradingPairs, isLoadingCex, isSuccessCex, isErrorCex } = useSelector(state => state.cex)
	const { currencies } = useSelector(state => state.bank)

	const dispatch = useDispatch()

	const navigate = useNavigate()

	const Login = () => {
		navigate('/login')
	}

	useEffect(() => {
		if (!currencies) {
			dispatch(getCurrencies())
		}
	}, [dispatch, currencies])

	useEffect(() => {
		if (!tradingPairs) {
			dispatch(getPairs())
			if (user) {
				dispatch(getUserOrders())
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradingPairs, user])

	useEffect(() => {
		if (pair && pair._id !== '') {
			dispatch(getOrders({ pair: pair._id }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pair])

	useEffect(() => {
		if (pair && pair._id === '') {
			dispatch(clearCexLogs())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pair])

	useEffect(() => {
		if (isSuccessCex) {
			toast.success(messageCex)
		}
		if (
			isSuccessCex &&
			// if message contains 'Placed a Buy Order of' or 'Placed a Sell Order of' then dispatch getUserOrders and getOrders
			(messageCex.includes('Placed a Buy Order of') ||
				messageCex.includes('Placed a Sell Order of') ||
				messageCex.includes('order cancelled'))
		) {
			// wait 5 seconds and then dispatch getUserOrders and getOrders, run spinner while waiting
			setTimeout(() => {
				dispatch(getUserOrders())
				if (pair && pair._id !== '') {
					dispatch(getOrders({ pair: pair._id }))
				}
			}, 4000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessCex, messageCex])

	useEffect(() => {
		if (isErrorCex) {
			toast.error(messageCex)
			dispatch(resetCex())
			if (user && messageCex === 'Authorization error') {
				dispatch(logout())
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, isErrorCex, messageCex])

	if (isLoadingCex) {
		return <Spinner />
	}

	const onChange = e => {
		setSelectedPair(e.target.value)
		setPair(prevState => ({
			...prevState,
			_id: e.target.value,
		}))
	}

	return (
		<div>
			<div className='profilebox'>
				<div className='cexback' />
				<header>Quantum Hub: Water Side</header>
				{/* if isMobile set className to '' else use className cex_gridcontainer */}
				<div className={isMobile ? '' : 'cex_gridcontainer'}>
					<div className='cexorders panel'>
						<div>
							<SellOrders _id={pair && pair._id} />
						</div>
						<div>
							<BuyOrders _id={pair && pair._id} />
						</div>
						{/* find the imgUrl in the currency that the currencyId matches the pair sellCurrency if no sellCurrency null*/}
						{pair && pair.sellCurrency && (
							<img
								src={
									currencies.find(
										c => c.currencyId === tradingPairs.find(p => p._id === pair._id).sellCurrency,
									).imgUrl || 'https://dl.dropboxusercontent.com/s/s2oyo7dzqi8vsyr/default.gif?raw=1'
								}
								alt='currency'
							/>
						)}
					</div>
					<div className='centralizer cexpair'>
						<SiAtom />
					</div>
					<div className='cexselect panel'>
						<div className='h3'> Pair Selection</div>
						{/* map out tradingPairs and onChange setTradingPair */}
						<select className='form-input4' name='pair' id='myPair' onChange={onChange} value={selectedPair}>
							{!selectedPair && <option value=''>Select a trading pair</option>}
							{tradingPairs &&
								tradingPairs.map(pair => (
									<option key={pair._id} value={pair._id}>
										{pair.name}
									</option>
								))}
						</select>
						<div className='tradingpairhorizontal'>
							{pair && pair._id && <>{tradingPairs.find(p => p._id === pair._id).name}</>}
						</div>
					</div>
					<div className='cexcreate panel'>
						<div>
							{/* if no user then display "Log in to create an order" esle display <CreateOrder>*/}
							{user ? <CreateOrder _id={pair && pair._id} /> : <div></div>}
						</div>
					</div>
					<div className='cexmyorders panel2'>
						{/* if no user then display "Log in to view your orders" esle display <MyOrders>*/}
						{user ? (
							<div>
								<div className='h3'> My Orders</div>
								<MyOrders />
							</div>
						) : (
							<header>
								<button className='synthbutton' onClick={Login}>
									Login
								</button>{' '}
								to buy or view your orders
							</header>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default CexLayout
