const Joi = require('joi');
const fields = require('./fields');

const getTransactionsSchema = Joi.object({
	nickname: fields.nicknameField.optional(),
	startDate: Joi.date().optional().label('Start Date'),
	endDate: Joi.date().optional().label('End Date'),
	currencyId: fields.currencyIdField.optional(),
	amountAbove: fields.currencyAmountField.label('Amount Above'),
	amountBelow: fields.currencyAmountField.label('Amount Below'),
	category: fields.transactionCategoryField.optional(),
	paging: fields.paginationSchema,
});

module.exports = getTransactionsSchema;
