import { useDispatch, useSelector } from 'react-redux'
import {
	deleteForgeRecipe,
	setActiveForgeRecipe,
	resetForge,
	getForgeRecipes,
} from '../../../features/forge/forgeSlice'
import CreateRecipe from './CreateRecipe'
import ModifyRecipe from './ModifyReceipe'
import Modal from '../../builders/modal'

function RecipeControl() {
	const { recipes } = useSelector(state => state.forge)
	const { currencies } = useSelector(state => state.bank)
	const sortedRecipes = recipes && [...recipes].sort((a, b) => a.createdAt - b.createdAt)

	const dispatch = useDispatch()

	function DeleteRecipe(_id) {
		const recipeData = {
			recipeId: _id,
		}

		dispatch(deleteForgeRecipe(recipeData))
		dispatch(resetForge())
		dispatch(getForgeRecipes())
	}

	function SetActiveRecipe(_id) {
		const recipeData = {
			objectId: _id,
			flag: recipes.find(p => p._id === _id).active ? false : true,
		}

		dispatch(setActiveForgeRecipe(recipeData))
		dispatch(resetForge())
		dispatch(getForgeRecipes())
	}

	return (
		<div className='centraliser'>
			<div className='centraliser'>
				<Modal
					header={`Create Recipe`}
					content={<CreateRecipe />}
					btnTextorIcon={`Create Recipe`}
					btnclass='signin signinwide'
				/>
			</div>
			<div className='cu_card_wrap'>
				{/* sort the recipes by _id */}
				{recipes && recipes.length > 0 ? (
					sortedRecipes.map(recipe => (
						<div className='cu_card' key={recipe._id}>
							<div className='h2_2'>{recipe.name}</div>
							<div className='cu_card_image'>
								<img src={recipe.imgUrl} alt={recipe.name} />
							</div>
							<div className='centraliser tb_margin'>
								<div className='cu_card_content'>{recipe.description}</div>
								<div className='cu_card_content'>
									Input : {currencies.find(currency => currency.currencyId === recipe.input.currencyId).name}
									{' x '}
									{recipe.input.amount}
								</div>
								<div className='cu_card_content'>
									Output :{' '}
									{currencies.find(currency => currency.currencyId === recipe.output.currencyId).name}
									{' x '}
									{recipe.output.amount}
								</div>
								<div className='cu_card_content'>Time : {recipe.timeRequired}</div>
								<div className='cu_card_content'>Active : {recipe.active ? 'Yes' : 'No'}</div>
							</div>
							<div className='centraliser'>
								<button className='signin b_margin' onClick={() => DeleteRecipe(recipe._id)}>
									Delete
								</button>
							</div>
							<div className='centraliser'>
								<Modal
									header={`Modify Recipe`}
									content={<ModifyRecipe recipeId={recipe._id} />}
									btnTextorIcon={`Modify Recipe`}
									btnclass='signin b_margin'
								/>
							</div>
							<div className='centraliser'>
								<button className='signin b_margin' onClick={() => SetActiveRecipe(recipe._id)}>
									SetActive
								</button>
							</div>
						</div>
					))
				) : (
					<div className='cu_card'>
						<div className='h2_2'>No Recipes</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default RecipeControl
