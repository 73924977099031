const Joi = require('joi')
const fields = require('../fields')

const forgeProcessFiltersSchema = Joi.object({
    pair: fields.objectIdField.label('Pair'),
    state: fields.integerAboveZeroField.label('State'),
    buySide: fields.boolField.label('Buy Side'),
    startDate: Joi.date().optional().label('Start Date'),
    endDate: Joi.date().optional().label('End Date'),
})

module.exports = forgeProcessFiltersSchema