import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import { getPackages, purchasePackage, resetCheckout } from '../features/checkout/checkoutSlice'

function Checkout() {
	const { packages, isErrorCheckout, isLoadingCheckout, isSuccessCheckout, messageCheckout, redirectUrl } =
		useSelector(state => state.checkout)
	const { user } = useSelector(state => state.auth)
	// filter packages that active = true
	const activePackages =
		packages && packages.filter(pack => pack.active === true || pack.active === undefined)
	const sortedPackages = activePackages && [...activePackages].sort((a, b) => a.createdAt - b.createdAt)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getPackages())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (isSuccessCheckout) {
			toast.success(messageCheckout)
			dispatch(resetCheckout())
			setTimeout(() => {
				if (redirectUrl) {
					window.location.href = redirectUrl
				}
			}, 5000)
		}
		if (isErrorCheckout) {
			toast.error(messageCheckout)
			dispatch(resetCheckout())
		}
	}, [isSuccessCheckout, isErrorCheckout, messageCheckout, redirectUrl, dispatch])

	if (isLoadingCheckout) {
		return <Spinner />
	}

	function PurchasePackage(packageId, providerId) {
		const purchaseData = {
			packageId: packageId,
			providerId: providerId * 1,
		}

		dispatch(purchasePackage(purchaseData))
	}

	return (
		<>
			<header>Purchase Credits</header>
			<div className='cu_card_wrap'>
				{/* sort the packages by _id */}
				{packages && activePackages.length > 0 ? (
					// only sort the packages if sortedPackages active === true
					sortedPackages.map(packages => (
						<div className='cu_card' key={packages._id}>
							<div className='h2_2'>{packages.name}</div>
							<div className='cu_card_image t_margin'>
								<img src={packages.imgUrl} alt={packages.name} />
							</div>
							<div className='centraliser tb_margin2'>
								<div className='cu_card_content'>{packages.description}</div>
								<div className='cu_card_content'>{packages.cost ? `$${packages.cost}` : ''}</div>
							</div>
							<div className='centraliser b_margin'>
								{user ? (
									<>
										<div>
											<button
												className='signintall tb_margin'
												onClick={() => PurchasePackage(packages._id, 2)}
											>
												Buy Now
											</button>
										</div>
										<div>
											<button
												className='signintall tb_margin'
												onClick={() => PurchasePackage(packages._id, 1)}
											>
												Pay with Crypto
											</button>
										</div>
									</>
								) : (
									<button className='signintall'>
										<Link to='/dashboard'>Login or Register to Purchase</Link>
									</button>
								)}
							</div>
						</div>
					))
				) : (
					<div className='cu_card'>
						<div className='h2_2'>No Packages</div>
					</div>
				)}
				{/* if there is a redirectURL dispalay a button to use the link */}
				{redirectUrl && (
					<div className='redirect_notice'>
						<h1 className='h1'>You are being redirected to complete your purchase. </h1>
						<div className='centraliser'>
							<button href={redirectUrl} rel='noreferrer'>
								{redirectUrl}
							</button>
						</div>
					</div>
				)}
			</div>
			<div className='loginbox'>
				<p className='notice3'>
					For volumes of $5000 or more you may contact us to use FIAT via wire transfer. Send a DM to our CEO
					StarChild#3885 through discord.
				</p>
			</div>
		</>
	)
}

export default Checkout
