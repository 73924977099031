import { useDispatch, useSelector } from 'react-redux'
import { BsFillQuestionDiamondFill } from 'react-icons/bs'
import truncator from '../builders/truncator'
import Modal from '../builders/modal'
import CitizenStatus from './CitizenStatus'
import EmailStatus from './EmailStatus'
import { useEffect } from 'react'
import { getCurrencies } from '../../features/bank/bankSlice'
import { toast } from 'react-toastify'
import { FaCopy } from 'react-icons/fa'

function UserProfileMobile() {
	const { user } = useSelector(state => state.auth)
	const dispatch = useDispatch()

	useEffect(() => {
		if (window.localStorage) {
			const nextGetCurrencies = localStorage.getItem('nextGetCurrencies')

			if (nextGetCurrencies > new Date()) {
				return
			}

			localStorage.removeItem('nextGetCurrencies')
			var expires = new Date()
			expires = expires.setHours(expires.getHours() + 24)
			localStorage.setItem('nextGetCurrencies', expires)
			dispatch(getCurrencies())
		}
	})

	function displayWallet() {
		if (!user || !user.primaryWallet) return

		return (
			<>
				<div className='p1' id='displaywallet'>
					Metamask :
				</div>
				<div className='p2' id='displaywalletaddress'>
					{truncator(user.primaryWallet)}
				</div>
				<div className='p3' />
			</>
		)
	}

	function truncatedUser() {
		if (!user || !user.nickname) return

		return <>{truncator(user.nickname)}</>
	}

	const handleCopy = () => {
		const referralUrl = `https://www.starlightnation.io/register/${user && user.nickname}`
		navigator.clipboard
			.writeText(referralUrl)
			.then(() => toast.success('Referral url copied to clipboard'))
			.catch(error => console.error('Error copying: ', error))
	}

	return (
		<div className='centraliser'>
			<h4 className='h4_2'>Profile</h4>
			<div className='profilegridmobile centraliser'>
				<div className='p1'>Nickname :</div>
				<div className='ps2'>{truncatedUser()}</div>
				<div></div>

				<EmailStatus />

				<div className='p1'>Citizenship Status : </div>
				<div className='ps2'>{'Member'}</div>
				<div title='Click for citizenship details.'>
					<Modal
						header='Citizeship Status'
						content={<CitizenStatus />}
						btnTextorIcon={<BsFillQuestionDiamondFill />}
						btnclass='question'
					/>
				</div>

				<div className='p1'>Fame : </div>
				<div className='ps2'>{user && user.fame ? user.fame : '0'}</div>
				<div className='ps3' />
				<div className='p1'>Referral Link:</div>
				<div className='p2' id='referralUrl'>
					{' '}
					https://www.starlightnation.io/register/{user && user.nickname}
				</div>
				<div title='Click to copy referral url'>
					<button className='question' onClick={handleCopy}>
						<FaCopy />
					</button>
				</div>

				{displayWallet()}
			</div>
		</div>
	)
}

export default UserProfileMobile
