import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { updateProfile } from '../../features/auth/authSlice'
import { registerPasswordField } from '../../validators/fields'
import Validators from '../../validators'
import VInput from '../VInput'

function UpdatePassword() {
	const [formData, setFormData] = useState({
		password: '',
		password2: '',
	})

	const dispatch = useDispatch()

	const { password, password2 } = formData

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const userData = {
			password,
		}

		//Full schema validation
		const schemaValidationResult = Validators['updateProfile'].validate(userData)
		if (schemaValidationResult.error) {
			toast.error(schemaValidationResult.error.message)
			return
		}

		if (password !== password2) {
			toast.error('passwords do not match')
			return
		}

		dispatch(updateProfile(userData))
	}

	return (
		<>
			<form onSubmit={onSubmit} id='changepassword'>
				<div className='form-group'>
					<VInput
						type='password'
						className='form-input'
						id='password'
						name='password'
						value={password}
						placeholder='Enter your new password'
						onChange={onChange}
						validationSchema={registerPasswordField}
					/>
				</div>
				<div className='form-group'>
					<VInput
						type='password'
						className='form-input'
						id='password2'
						name='password2'
						value={password2}
						placeholder='Verify your new password'
						onChange={onChange}
						validationSchema={registerPasswordField}
					/>
				</div>
				<div className='tabs2'>
					<button className='signin signin3' onClick={onSubmit}>
						Update password
					</button>
				</div>
			</form>
		</>
	)
}

export default UpdatePassword
