import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initTransfer } from '../../features/bank/bankSlice'

import truncator from './truncator'

function Transfer(params) {
	const [formData, setFormData] = useState({
		recipientNickName: '',
		amount: '',
		note: '',
	})

	const { recipientNickName, amount, note } = formData

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const { user } = useSelector(state => state.auth)

	const onSubmit = e => {
		e.preventDefault()

		const userData = {
			recipientNickName,
			currencyId: params.Id * 1,
			amount: amount * 1,
			note,
		}

		dispatch(initTransfer(userData))
	}

	return (
		<>
			<h3 className='centraliser'>Transfer {params.name} to another user</h3>
			<div>
				<form onSubmit={onSubmit}>
					<div className='user_id'>From: {truncator(user && user.nickname)}</div>
					<div className='wrap'>
						<div className='f1'>To: </div>
						<input
							type='text'
							className='form-input2'
							id='recipientNickName'
							name='recipientNickName'
							value={recipientNickName}
							placeholder='Recipients nickname '
							onChange={onChange}
						/>
					</div>
					<div className='wrap'>
						<div className='f1'>Amount: </div>
						<input
							type='number'
							className='form-input2'
							id='amount'
							name='amount'
							value={amount}
							placeholder='Enter amount to transfer'
							onChange={onChange}
						/>
					</div>
					<div className='wrap'>
						<div className='f1'>Note: </div>
						<input
							type='text'
							className='form-input2'
							id='note'
							name='note'
							value={note}
							placeholder='Enter note to recipient'
							onChange={onChange}
						/>
					</div>
					<div className='tabs2'>
						<button type='submit' className='signin'>
							Submit
						</button>
					</div>
				</form>
			</div>
		</>
	)
}

export default Transfer
