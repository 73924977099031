import { useSelector } from 'react-redux'
import RequestVerifyEmail from './RequestVerifyEmail'

const EmailStatus = () => {
	const { user } = useSelector(state => state.auth)

	if ((user && user.isEmailVerified === true) || (user && user.isWalletOnly === true)) {
		return (
			<>
				<div className='p1'>Email : </div>
				<div className='p2'>{user && user.email}</div>
				<div></div>
			</>
		)
	} else {
		return (
			<>
				<div className='p1'>Email : </div>
				<div className='p2red'>{user && user.email}</div>
				<div title='Verify Email'>
					<RequestVerifyEmail />
				</div>
			</>
		)
	}
}

export default EmailStatus
