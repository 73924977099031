const WhatIsDistCur = () => {
	return (
		<>
			<div className='notice3'>
            This will distribute currency to multiple users who hold the target currency. <br></br>
            The amount of currency distributed will be the amount of the target currency held by 
            the user divided by the amount of currency to be distributed. <br></br><br></br> 
            For example : if 1000 credits are to be distributed to holders of SN and they have a combined
            amount of 1000 SN together, then each SN will get a distribution of 1 credit. <br></br> <br></br>
            Distributed currency will only be distributed in whole numbers.  
            If the amount of currency to be distributed is not divisible by the amount of 
            target currency held by users then the remainder will be returned to you.
			</div>
			<div className=''>
				
			</div>
		</>
	)
}

export default WhatIsDistCur
