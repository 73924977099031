import { useState } from 'react';
import './Games.css';

function Games() {
	const [showDescription, setShowDescription] = useState(false);
	// set a function that will toggle show the description of the game and a play now button with a link to the game when icon is clicked
	function getDescription() {
		// toggle showDescription
		setShowDescription(!showDescription);
	}

	return (
		<div>
			<header>Games</header>
			<div className='gamecontainer'>
				<div className='gamesicon' onClick={getDescription}>
					<img
						src='https://dl.dropboxusercontent.com/scl/fi/wmucekgl5uldwi78iuibr/oricon.png?rlkey=9umszzgjfxpz7zw5jrxvr8ok1&dl=0'
						alt='orions reach'
					/>
					<div className={showDescription ? '' : 'hide'}>
						<div className='gamename'>Orion's Reach</div>
						<div className='gamestudio'>by Starlight Nation</div>
					</div>
				</div>
				<div className={showDescription ? 'showdescription' : 'hide'}>
					<div className='description_content'>
						<div>
							Orions Reach is a captivating and innovative space simulation game that invites players to
							embark on a cosmic adventure like no other. With a play-to-earn model at its core, this
							metaverse offers an immersive experience where you can explore uncharted galaxies, collect
							valuable resources, and command your own fleet of ships. The game introduces Alpha Synth Chips,
							an ingenious technology that allows you to unlock the inner workings of your ships, creating a
							deeper connection between you and your cosmic vessels. As you navigate through the metaverse,
							you'll have the opportunity to embark on Orion's Reach Salvage Missions, where you can recover
							precious resources that will be key to future aspects of the game. The more you explore and
							contribute, the greater your potential rewards become, shaping a dynamic and thriving community.
							Whether you're a seasoned gamer or a newcomer, Orion's Reach offers an inclusive and exciting
							journey into the metaverse, with opportunities for financial rewards, personal growth, and
							unparalleled adventures in the boundless expanse of the cosmos.
						</div>
						<a href='https://orionsreach.starlightnation.io/' target='blank'>
							<div>
								<button className='signin'>Play Now</button>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Games;
