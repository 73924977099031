import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createPackage, resetCheckout } from '../../../features/checkout/checkoutSlice'
import Spinner from '../../Spinner'

function CreatePackage() {
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		cost: '',
		amount: '',
		imgUrl: '',
	})

	const { name, description, cost, amount, imgUrl } = formData
	const { isLoadingCheckout } = useSelector(state => state.checkout)

	const dispatch = useDispatch()

	const onChange = e => {
		setFormData(prevState => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	const onSubmit = e => {
		e.preventDefault()

		const packageData = {
			name: name,
			description: description,
			cost: cost * 1,
			amount: amount * 1,
			currency: 3 * 1,
			imgUrl: imgUrl,
		}

		dispatch(createPackage(packageData))
		dispatch(resetCheckout())
	}

	if (isLoadingCheckout) {
		return <Spinner />
	}

	return (
		<div>
			<form onSubmit={onSubmit}>
				<div className='wrap2'>
					<div className='f1_2'>Name: </div>
					<input
						type='text'
						className='form-input3'
						placeholder='Name'
						name='name'
						value={name}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Description:</div>
					<input
						type='text'
						className='form-input3'
						placeholder='Description'
						name='description'
						value={description}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Cost:</div>
					<input
						type='number'
						className='form-input3'
						placeholder='Cost'
						name='cost'
						value={cost}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Amount:</div>
					<input
						type='number'
						className='form-input3'
						placeholder='Amount'
						name='amount'
						value={amount}
						onChange={onChange}
					/>
				</div>
				<div className='wrap2'>
					<div className='f1_2'>Image Url:</div>
					<input
						type='text'
						className='form-input3'
						placeholder='Image Url'
						name='imgUrl'
						value={imgUrl}
						onChange={onChange}
					/>
				</div>
				<div className='tabs2'>
					<button type='submit' className='signin'>
						Create
					</button>
				</div>
			</form>
		</div>
	)
}

export default CreatePackage
