import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import forgeService from './forgeService'
import { getErrMsg } from '../../util/generic'

const initialState = {
	isErrorForge: false,
	isSuccessForge: false,
	isLoadingForge: false,
	messageForge: '',
	logs: null,
}

// Get Forge Recipes
export const getForgeRecipes = createAsyncThunk('forge/recipes/fetch', async (_, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await forgeService.getForgeRecipes(token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Create Forge Recipe
export const createForgeRecipe = createAsyncThunk('forge/recipes/create', async (recipeData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await forgeService.createForgeRecipe(recipeData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Delete Forge Recipe
export const deleteForgeRecipe = createAsyncThunk('forge/recipes/delete', async (recipeData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await forgeService.deleteForgeRecipe(recipeData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Modify Forge Recipe
export const modifyForgeRecipe = createAsyncThunk('forge/recipes/modify', async (recipeData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await forgeService.modifyForgeRecipe(recipeData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Set Active state of Forge Recipe
export const setActiveForgeRecipe = createAsyncThunk(
	'forge/recipes/setactive',
	async (recipeData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token
			return await forgeService.setActiveForgeRecipe(recipeData, token)
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error))
		}
	},
)

// Start Forge Process
export const startForgeProcess = createAsyncThunk('forge/startProcess', async (recipeData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await forgeService.startForgeProcess(recipeData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// End Forge Process
export const endForgeProcess = createAsyncThunk('forge/endProcess', async (recipeData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await forgeService.endForgeProcess(recipeData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Get User Forge Processes
export const getForgeProcesses = createAsyncThunk('forge/getForgeProcesses', async (logData, thunkAPI) => {
	try {
		const token = thunkAPI.getState().auth.token
		return await forgeService.getForgeProcesses(logData, token)
	} catch (error) {
		return thunkAPI.rejectWithValue(getErrMsg(error))
	}
})

// Get Forge Processes All
export const getForgeProcessesAll = createAsyncThunk(
	'forge/getForgeProcessesAll',
	async (logData, thunkAPI) => {
		try {
			const token = thunkAPI.getState().auth.token
			return await forgeService.getForgeProcessesAll(logData, token)
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrMsg(error))
		}
	},
)

// Clear Forge Process Logs
export const clearForgeProcessLogs = createAsyncThunk('forge/clearForgeProcesses', async () => {
	forgeService.clearForgeProcessLogs()
})

export const forgeSlice = createSlice({
	name: 'forge',
	initialState,
	reducers: {
		resetForge: state => {
			state.isErrorForge = false
			state.isSuccessForge = false
			state.isLoadingForge = false
			state.messageForge = ''
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getForgeRecipes.pending, state => {
				state.isLoadingForge = true
			})
			.addCase(getForgeRecipes.fulfilled, (state, action) => {
				state.isLoadingForge = false
				state.isSuccessForge = false
				state.messageForge = action.payload.message
				state.recipes = action.payload.data
			})
			.addCase(getForgeRecipes.rejected, (state, action) => {
				state.isLoadingForge = false
				state.isErrorForge = true
				state.messageForge = action.payload
			})
			.addCase(createForgeRecipe.pending, state => {
				state.isLoadingForge = true
			})
			.addCase(createForgeRecipe.fulfilled, (state, action) => {
				state.isLoadingForge = false
				state.isSuccessForge = true
				state.messageForge = action.payload.message
			})
			.addCase(createForgeRecipe.rejected, (state, action) => {
				state.isLoadingForge = false
				state.isErrorForge = true
				state.messageForge = action.payload
			})
			.addCase(deleteForgeRecipe.pending, state => {
				state.isLoadingForge = true
			})
			.addCase(deleteForgeRecipe.fulfilled, (state, action) => {
				state.isLoadingForge = false
				state.isSuccessForge = true
				state.messageForge = action.payload.message
			})
			.addCase(deleteForgeRecipe.rejected, (state, action) => {
				state.isLoadingForge = false
				state.isErrorForge = true
				state.messageForge = action.payload
			})
			.addCase(modifyForgeRecipe.pending, state => {
				state.isLoadingForge = true
			})
			.addCase(modifyForgeRecipe.fulfilled, (state, action) => {
				state.isLoadingForge = false
				state.isSuccessForge = true
				state.messageForge = action.payload.message
			})
			.addCase(modifyForgeRecipe.rejected, (state, action) => {
				state.isLoadingForge = false
				state.isErrorForge = true
				state.messageForge = action.payload
			})
			.addCase(setActiveForgeRecipe.pending, state => {
				state.isLoadingForge = true
			})
			.addCase(setActiveForgeRecipe.fulfilled, (state, action) => {
				state.isLoadingForge = false
				state.isSuccessForge = true
				state.messageForge = action.payload.message
			})
			.addCase(setActiveForgeRecipe.rejected, (state, action) => {
				state.isLoadingForge = false
				state.isErrorForge = true
				state.messageForge = action.payload
			})
			.addCase(startForgeProcess.pending, state => {
				state.isLoadingForge = true
			})
			.addCase(startForgeProcess.fulfilled, (state, action) => {
				state.isLoadingForge = false
				state.isSuccessForge = true
				state.messageForge = action.payload.message
			})
			.addCase(startForgeProcess.rejected, (state, action) => {
				state.isLoadingForge = false
				state.isErrorForge = true
				state.messageForge = action.payload
			})
			.addCase(endForgeProcess.pending, state => {
				state.isLoadingForge = true
			})
			.addCase(endForgeProcess.fulfilled, (state, action) => {
				state.isLoadingForge = false
				state.isSuccessForge = true
				state.messageForge = action.payload.message
			})
			.addCase(endForgeProcess.rejected, (state, action) => {
				state.isLoadingForge = false
				state.isErrorForge = true
				state.messageForge = action.payload
			})
			.addCase(getForgeProcesses.pending, state => {
				state.isLoadingForge = true
			})
			.addCase(getForgeProcesses.fulfilled, (state, action) => {
				state.isLoadingForge = false
				state.isSuccessForge = true
				state.messageForge = action.payload.message
				state.logs = action.payload.data
			})
			.addCase(getForgeProcesses.rejected, (state, action) => {
				state.isLoadingForge = false
				state.isErrorForge = true
				state.messageForge = action.payload
			})
			.addCase(getForgeProcessesAll.pending, state => {
				state.isLoadingForge = true
			})
			.addCase(getForgeProcessesAll.fulfilled, (state, action) => {
				state.isLoadingForge = false
				state.isSuccessForge = true
				state.messageForge = action.payload.message
				state.logs = action.payload.data
			})
			.addCase(getForgeProcessesAll.rejected, (state, action) => {
				state.isLoadingForge = false
				state.isErrorForge = true
				state.messageForge = action.payload
			})
			.addCase(clearForgeProcessLogs.pending, state => {
				state.logs = null
			})
			.addCase(clearForgeProcessLogs.fulfilled, (state, action) => {
				state.logs = null
			})
			.addCase(clearForgeProcessLogs.rejected, (state, action) => {
				state.logs = null
			})
	},
})

export const { resetForge } = forgeSlice.actions

export default forgeSlice.reducer
